import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { purchaseOrderEventSliceName } from "./dataTypes";
import { purchaseOrderEventAdapter } from "./purchaseOrderEventAdapter";
import { addFetchPurchaseOrderEventsReducers } from "./reducers/fetchPurchaseOrderEvents";

const slice = createSlice({
  name: purchaseOrderEventSliceName,
  initialState: purchaseOrderEventAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchPurchaseOrderEventsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
