import { useMemo } from "react";
import useGetCustomerOrderPackageFiles from "../../../features/customerOrderPackageFile/hooks/useGetCustomerOrderPackageFiles";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { GetFilesFilter } from "../../../features/file/dataTypes";
import FileList from "../../shared/File/FileList/FileList";

const Attachments = ({ packageId }: { packageId: string }) => {
  const filter = useMemo<GetFilesFilter>(() => {
    return { paginationOptions: { all: true, amount: 0, from: 0 } };
  }, []);

  const { customerOrderPackageFiles, isLoading } = useGetCustomerOrderPackageFiles({
    filter,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    packageId: packageId,
  });

  return <FileList files={customerOrderPackageFiles} isLoading={isLoading} />;
};

export default Attachments;
