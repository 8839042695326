import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderEventAdapter } from "../customerOrderEventAdapter";
import {
  CustomerOrderEvent,
  GetCustomerOrderEventsFilter,
  ResponseCustomerOrderEvent,
  customerOrderEventSliceName,
} from "../dataTypes";

const getCustomerOrderEvents = async (
  dispatch: AppDispatch,
  id: string,
  filters: Readonly<GetCustomerOrderEventsFilter>,
) => {
  const url = api.customerOrder.customerOrderEvents(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  customerOrderEvents: PaginationResponse<ResponseCustomerOrderEvent>;
}

export const fetchCustomerOrderEvents = createAsyncThunk<
  Response,
  { filter: Readonly<GetCustomerOrderEventsFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod; id: string }
>(customerOrderEventSliceName + "/fetchCustomerOrderEvents", (dispatch, { filter, id }) =>
  getCustomerOrderEvents(dispatch, id, filter),
);

export const addFetchCustomerOrderEventsReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderEvents = payload.customerOrderEvents.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderEventAdapter.setAll(state, customerOrderEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderEventAdapter.upsertMany(state, customerOrderEvents);
        break;
    }

    state.count = payload.customerOrderEvents.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
