import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const purchaseOrderLineSliceName = "purchaseOrderLine";

export interface ResponsePurchaseOrderLine extends IdObject {
  client: string;
  orderNumber: string;
  lineNbr: number;
  lineId: string;
  orderQuantity: number;
  receivedQuantity: number;
  status: string;
  statusColor: string;
  itemId: string;
  itemDescription: string;
  eventCount: number;
}

export interface GetPurchaseOrderLinesFilter extends ClientPaginatedRequestFilter {}

export interface PurchaseOrderLine extends ResponsePurchaseOrderLine, AdditionalRequestState {}

export const PURCHASE_ORDER_LINE_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponsePurchaseOrderLine, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  CLIENT: "client",
  ORDER_NUMBER: "orderNumber",
  LINE_NBR: "lineNbr",
  LINE_ID: "lineId",
  ORDER_QUANTITY: "orderQuantity",
  RECEIVED_QUANTITY: "receivedQuantity",
  STATUS: "status",
  STATUS_COLOR: "statusColor",
  ITEM_ID: "itemId",
  ITEM_DESCRIPTION: "itemDescription",
  EVENT_COUNT: "eventCount",
};
