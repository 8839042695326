import { Response, RestOptions } from "@myloc/myloc-utils";
import { miniSerializeError } from "@reduxjs/toolkit";
import { AppDispatch } from "../../app/store";
import { Message, Messages } from "../../features/dataTypes";
import { setErrors } from "../../features/dialog/dialogSlice";
import { handleClientSideSignOut } from "../../features/session/utils/clientSideSignOut";
import { translate } from "../../preferences/localization";
import HttpStatusCodes from "../../utils/HttpStatusCodes";
import errorCodes from "./errorCodes";

export function handleError(dispatch: AppDispatch): RestOptions["errorService"] {
  return function handleError(exception, errorHandler) {
    if (errorHandler) errorHandler(exception);
    return handleErrorResponse(exception, dispatch);
  };
}

const handleErrorResponse = (exception: any, dispatch: AppDispatch) => {
  if (exception?.response?.status === HttpStatusCodes.UNAUTHORIZED) {
    if (exception.response.data === errorCodes.SESSION_INVALID.CODE) {
      handleClientSideSignOut(dispatch);
    }
  }

  const messages = getErrorMessages(exception);

  const errors = { exception: miniSerializeError(exception), errors: messages };

  errors.errors.forEach(error => {
    if (error.code === errorCodes.SESSION_INVALID.CODE) {
      error.title = translate(errorCodes.SESSION_INVALID.MESSAGE);
    }
  });

  if (errors.errors.length > 0) {
    dispatch(setErrors(errors));
  }

  return new Response(exception?.response?.status, exception?.response?.statusText, errors);
};

function handleUnauthorizedMessages(errors: Message[]): Message[] {
  return errors.map(error => {
    const unauthorizedErrorCode = errorCodes.WRONG_USERNAME_OR_PASSWORD.CODE;

    if (error.code === errorCodes.PASSWORD_EXPIRED.CODE) {
      return { ...error, title: translate(errorCodes.PASSWORD_EXPIRED.MESSAGE) };
    }

    if (error.code === unauthorizedErrorCode) {
      return { ...error, title: translate(errorCodes.WRONG_USERNAME_OR_PASSWORD.MESSAGE) };
    }

    const unauthorizedMessage = translate("UNAUTHORIZED");

    if (error.code === unauthorizedMessage && error.title === unauthorizedMessage) {
      const errorCode = navigator.onLine
        ? "SERVER_ERROR_DESCRIPTION_REQUIRE_LOGIN"
        : "INTERNET_CONNECTION_ERROR_DESCRIPTION";

      return { code: errorCode, title: translate(errorCode) };
    }

    return error;
  });
}

function handleInternalErrorMessages(errors: Message[]): Message[] {
  if (!isPasswordError(errors)) return errors;

  const errorMessage = translate("SERVER_COULDNT_PROCESS");

  return [{ title: errorMessage, code: "SERVER_COULDNT_PROCESS" }];
}

function isPasswordError(errors: Message[]): boolean {
  return errors.some(message => message.code === "password" && message.title === "password");
}

export function getErrorMessages(exception: any): Message[] {
  if (!exception?.response || exception.response.status === HttpStatusCodes.SERVICE_UNAVAILABLE) {
    const errorMessageCode = navigator.onLine ? "SERVER_ERROR_DESCRIPTION" : "INTERNET_CONNECTION_ERROR_DESCRIPTION";

    return [{ code: errorMessageCode, title: translate(errorMessageCode) }];
  }

  if (exception.response.status === HttpStatusCodes.UNAUTHORIZED) {
    if (exception.response.data?.errors != null) {
      return handleUnauthorizedMessages(exception.response.data.errors as Message[]);
    }
  }

  if (exception.response.status === HttpStatusCodes.INTERNAL_SERVER_ERROR) {
    if (exception.response.data?.errors != null) {
      return handleInternalErrorMessages(exception.response.data.errors as Message[]);
    }
  }

  if (exception.response.data) {
    const contentType = exception.response.headers["content-type"]; //MOVE TO some util constant

    if (typeof exception.response.data === "object") {
      const data = exception.response.data as Messages;

      return data.errors;
    } else if (typeof exception.response.data === "string" && !contentType?.startsWith("test/html")) {
      //Move to some util constant
      const data = exception.response.data;

      return [{ code: data, title: data }];
    }
  }

  return [{ code: "Unknown server error", title: "Unknown server error" }];
}
