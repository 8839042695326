import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import useCancellablePromise from "../../../hooks/useCancellablePromises";
import { FULFILLED_UPDATE_METHOD, FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import {
  makeSelectCustomerOrderLinesById,
  selectCount,
  selectErrorMessage,
  selectRequestState,
} from "../customerOrderLineSelectors";
import { GetCustomerOrderLinesFilter } from "../dataTypes";
import useFetchCustomerOrderLines from "./useFetchCustomerOrderLines";

interface ids {
  allIds: string[];
  uniqueIds: Set<string>;
}

const useLazyGetCustomerOrderLines = ({
  filter,
  fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.SET_ALL,
  loadIfNotInitialized = false,
  infiniteScroll = false,
}: {
  filter?: Readonly<GetCustomerOrderLinesFilter>;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
  infiniteScroll?: boolean;
}) => {
  const fetchCustomerOrderLines = useFetchCustomerOrderLines();
  const hasInitialized = useRef(false);
  const [ids, setIds] = useState<ids>({ allIds: [], uniqueIds: new Set() });

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const count = useAppSelector(selectCount);
  const selectCustomerOrderLines = useMemo(() => makeSelectCustomerOrderLinesById(ids.allIds), [ids.allIds]);
  const customerOrderLines = useAppSelector(selectCustomerOrderLines);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const { addCancellablePromise } = useCancellablePromise(filter);

  const loadCustomerOrderLines = useCallback(async () => {
    if (filter == null) return;

    const promise = fetchCustomerOrderLines({ filter, fulfilledUpdateMethod });

    if (promise == null) return;

    addCancellablePromise(promise);

    const response = await promise;

    if (!response.isSuccessful) return;

    const value = response.value;

    if (value == null) return;

    if (infiniteScroll) {
      setIds(prevIds => {
        const allIds: string[] = [...prevIds.allIds, ...value.filter(id => !uniqueIds.has(id))];
        const uniqueIds: Set<string> = new Set(...prevIds.uniqueIds, ...value);

        return { allIds, uniqueIds };
      });
    } else {
      setIds({ allIds: value, uniqueIds: new Set(value) });
    }
  }, [fetchCustomerOrderLines, filter, fulfilledUpdateMethod, infiniteScroll, addCancellablePromise]);

  useEffect(() => {
    //om något ändras som inte är from och amount i filtret nollställ ids
    hasInitialized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitialized.current) {
      hasInitialized.current = true;
      loadCustomerOrderLines();
    }
  }, [isUninitialized, loadIfNotInitialized, loadCustomerOrderLines]);

  return {
    customerOrderLines,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchCustomerOrderLines: loadCustomerOrderLines,
  };
};

export default useLazyGetCustomerOrderLines;
