import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { goodsOwnerSliceName } from "./dataTypes";
import { goodsOwnerAdapter } from "./goodsOwnerAdapter";
import { addFetchCurrentUserReducers } from "./reducers/fetchCurrentUser";
import { addFetchGoodsOwnerReducers } from "./reducers/fetchGoodsOwner";
import { addFetchGoodsOwnersReducers } from "./reducers/fetchGoodsOwners";
import { addFetchWeekLetterReducers } from "./reducers/fetchWeekLetter";
import { addFetchWeekLettersReducers } from "./reducers/fetchWeekLetters";

const slice = createSlice({
  name: goodsOwnerSliceName,
  initialState: goodsOwnerAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchGoodsOwnersReducers(builder);
    addFetchGoodsOwnerReducers(builder);
    addFetchWeekLettersReducers(builder);
    addFetchWeekLetterReducers(builder);
    addFetchCurrentUserReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
