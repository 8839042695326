import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import { dialogSliceName } from "../features/dialog/dataTypes";
import dialogSlice from "../features/dialog/dialogSlice";
import { sessionSliceName } from "../features/session/dataTypes";
import sessionSlice from "../features/session/sessionSlice";
import { clearAllLocalStorage } from "../utils/store/storage";
import { LOCAL_STORAGE_KEYS_DO_NOT_CLEAR_ON_LOGOUT } from "../utils/store/storeVariables";
import { signOut } from "./actions";
import appDataReducer from "./appDataReducer";
import { appDataName } from "./dataTypes";

const combinedReducer = combineReducers({
  [appDataName]: appDataReducer,
  [dialogSliceName]: dialogSlice,
  [sessionSliceName]: sessionSlice,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (signOut.match(action)) {
    //Reset state and clear local and session storage at logout
    state = undefined;

    clearAllLocalStorage(LOCAL_STORAGE_KEYS_DO_NOT_CLEAR_ON_LOGOUT);
    sessionStorage.clear();
  }

  return combinedReducer(state, action);
};

export default rootReducer;
