import { Method, Request, Response, sendRequest } from "@myloc/myloc-utils";
import { miniSerializeError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import translate from "../../../language/translate";
import useTranslate from "../../../language/useTranslate";
import { getErrorMessages } from "../../../services/error/errorService";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { ValueOf } from "../../../utils/dataTypes";
import { Messages, ValidationResult } from "../../dataTypes";
import { setErrors, setInfos } from "../../dialog/dialogSlice";
import defaultRestOptions from "../../utils/defaultRestOptions";
import getValidationResult from "../../utils/getValidationResults";
import { RequestResetPasswordRequest } from "../dataTypes";

export interface Message {
  message: string;
}

async function resetPassword(dispatch: AppDispatch, data: RequestResetPasswordRequest) {
  /**
   * Use a custom error handler to set a better descrption than sent from backend
   * The user sholud not see "not authorized" if user does not exist
   */
  const customErrorHandler: Parameters<typeof defaultRestOptions>["0"]["customErrorHandler"] = exception => {
    let status: ValueOf<typeof HttpStatusCodes> | undefined;

    if (exception?.response?.status === HttpStatusCodes.UNAUTHORIZED) {
      //A non existing user has requested a password reset. Show standard message anyway together with status 200
      const infoMessage = translate("RESET_LINK_IS_SENT");

      status = HttpStatusCodes.OK;
      dispatch(setInfos([{ title: infoMessage, code: "RESET_LINK_IS_SENT" }]));
    } else {
      const errorMessages = getErrorMessages(exception);

      dispatch(setErrors({ exception: miniSerializeError(exception), errors: errorMessages }));
    }

    const responseStatus = status ?? exception?.response?.status;

    return new Response(responseStatus, exception?.response?.statusText, { errors: getErrorMessages(exception) });
  };

  const url = api.password.requestReset();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, data, await defaultRestOptions({ customErrorHandler, dispatch }));
}

const useRequestResetPassword = () => {
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Messages>();

  const validateRequestResetPassword = useCallback(
    (request?: Partial<RequestResetPasswordRequest>): ValidationResult<RequestResetPasswordRequest> => {
      if (!request) {
        return getValidationResult({
          messages: { errors: [{ title: translate("DATA_MISSING"), code: "DATA_MISSING" }] },
        });
      }

      if (!request.username) {
        return getValidationResult({
          messages: {
            errors: [{ title: translate("USERNAME_MISSING"), code: "USERNAME_MISSING", field: { field: "username" } }],
          },
        });
      }

      return getValidationResult({ data: { username: request.username } });
    },
    [translate],
  );

  const requestResetPassword = useCallback(
    async (request: RequestResetPasswordRequest) => {
      setIsLoading(true);
      setIsSuccess(false);
      setIsError(false);
      setErrorMessages(undefined);

      let result = validateRequestResetPassword(request);

      if (!result.success) {
        dispatch(setErrors(result.messages));
      } else {
        const response = await resetPassword(dispatch, request);

        if (!response.isAccepted()) {
          result = getValidationResult({ messages: response.data as Messages });
        } else {
          const infoMessage = (response.data as Message).message;

          dispatch(setInfos([{ title: infoMessage, code: infoMessage }]));
        }
      }

      setIsSuccess(result.success);
      setIsError(!result.success);

      if (!result.success) setErrorMessages(result.messages);

      setIsLoading(false);
    },
    [dispatch, validateRequestResetPassword],
  );

  return { requestResetPassword, isLoading, isSuccess, isError, errorMessages };
};

export default useRequestResetPassword;
