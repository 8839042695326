import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ResponseEvent } from "../event/dataTypes";
import { PaginationFilterOptions } from "../utils/pagination";

export const shipmentTrackingEventSliceName = "shipmentTrackingEvent";

export interface ResponseShipmentTrackingEvent extends ResponseEvent, IdObject {}

export interface GetShipmentTrackingEventsFilter extends PaginationFilterOptions {}

export interface ShipmentTrackingEvent extends ResponseShipmentTrackingEvent, AdditionalRequestState {}
