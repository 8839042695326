import { ErrorBoundary } from "@myloc/myloc-utils";
import "dayjs/locale/sv";
import { Provider } from "react-redux";
import store from "./app/store";
import Site from "./components/Site/Site";
import { PreferenceProvider } from "./preferences/PreferenceProvider";

const App = () => {
  return (
    <ErrorBoundary>
      <PreferenceProvider>
        <Provider store={store}>
          <Site />
        </Provider>
      </PreferenceProvider>
    </ErrorBoundary>
  );
};

export default App;
