import { createSelector } from "@reduxjs/toolkit";
import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderAdapter } from "./customerOrderAdapter";
import { CustomerOrder, customerOrderSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderSliceName];

const adapterSelectors = customerOrderAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrdersEntities = adapterSelectors.selectEntities;
export const selectCustomerOrders = adapterSelectors.selectAll;
export const makeSelectCustomerOrder = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);

export function makeSelectCustomerOrdersById(ids: string[] = []) {
  return createSelector(selectCustomerOrdersEntities, entities => {
    return ids.reduce<CustomerOrder[]>((customerOrders, id) => {
      const customerOrder = entities[id];

      return customerOrder ? [...customerOrders, customerOrder] : customerOrders;
    }, []);
  });
}
