import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { PaginationFilterOptions } from "../utils/pagination";

export const purchaseOrderLineEventSliceName = "purchaseOrderLineEvent";

export interface ResponsePurchaseOrderLineEvent extends IdObject {
  event: string;
  date: number;
  time: number;
  place: string;
}

export interface GetPurchaseOrderLineEventsFilter extends PaginationFilterOptions {}

export interface PurchaseOrderLineEvent extends ResponsePurchaseOrderLineEvent, AdditionalRequestState {}

export const PURCHASE_ORDER_EVENT_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponsePurchaseOrderLineEvent, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  EVENT: "event",
  DATE: "date",
  TIME: "time",
  PLACE: "place",
};
