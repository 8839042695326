import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { customerOrderFileAdapter } from "./customerOrderFileAdapter";
import { customerOrderFileSliceName } from "./dataTypes";
import { addFetchCustomerOrderFilesReducers } from "./reducers/fetchCustomerOrderFiles";

const slice = createSlice({
  name: customerOrderFileSliceName,
  initialState: customerOrderFileAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderFilesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
