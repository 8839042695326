import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetCustomerOrdersFilter } from "../dataTypes";
import useLazyGetCustomerOrders from "./useLazyGetCustomerOrders";

const useGetCustomerOrders = (args: {
  filter?: Readonly<GetCustomerOrdersFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  //lägg till infintescroll
}) => {
  const { isLoading: isLazyLoading, fetchCustomerOrders, ...rest } = useLazyGetCustomerOrders(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrders();
  }, [fetchCustomerOrders]);

  useEffect(() => {
    const initiateCustomerOrders = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrders();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrders();
  }, [fetchCustomerOrders, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrders: fetchCustomerOrders };
};

export default useGetCustomerOrders;
