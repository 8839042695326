import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderPackageLastEventAdapter } from "../customerOrderPackageLastEventAdapter";
import {
  CustomerOrderPackageLastEvent,
  GetCustomerOrderPackageLastEventsFilter,
  ResponseCustomerOrderPackageLastEvent,
  customerOrderPackageLastEventSliceName,
} from "../dataTypes";

const getCustomerOrderPackageLastEvents = async (
  dispatch: AppDispatch,
  filters: Readonly<GetCustomerOrderPackageLastEventsFilter>,
) => {
  const url = api.customerOrderPackage.lastEvents();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  lastEvents: PaginationResponse<ResponseCustomerOrderPackageLastEvent>;
}

export const fetchCustomerOrderPackageLastEvents = createAsyncThunk<
  Response,
  { filter: Readonly<GetCustomerOrderPackageLastEventsFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(customerOrderPackageLastEventSliceName + "/fetchCustomerOrderPackageLastEvents", (dispatch, { filter }) =>
  getCustomerOrderPackageLastEvents(dispatch, filter),
);

export const addFetchCustomerOrderPackageLastEventsReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderPackageLastEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderPackageLastEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderPackageLastEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderPackageLastEvents = payload.lastEvents.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderPackageLastEventAdapter.setAll(state, customerOrderPackageLastEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderPackageLastEventAdapter.upsertMany(state, customerOrderPackageLastEvents);
        break;
    }

    state.count = payload.lastEvents.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderPackageLastEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
