import { List, ListItem, Snackbar as MUISnackBar, SnackbarOrigin } from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Message } from "../../../features/dataTypes";
import { SEVERITY } from "../../../features/dialog/dataTypes";
import useTranslate from "../../../language/useTranslate";
import { ValueOf } from "../../../utils/dataTypes";
import styles from "./Snackbar.module.scss";

const TITLE_TRANSLATIONS: Readonly<{ [key in ValueOf<typeof SEVERITY>]: string }> = {
  [SEVERITY.INFO]: "ALERT_SUCCESS_TITLE",
  [SEVERITY.WARNING]: "ALERT_WARNING_TITLE",
  [SEVERITY.ERROR]: "ALERT_ERROR_TITLE",
} as const;

const ALERT_COLOR: Readonly<{ [key in ValueOf<typeof SEVERITY>]: AlertColor }> = {
  [SEVERITY.INFO]: "success",
  [SEVERITY.WARNING]: "warning",
  [SEVERITY.ERROR]: "error",
} as const;

const DEFAULT_DURATION = 5000;

const Snackbar = ({
  onClose,
  severityType,
  position = { vertical: "bottom", horizontal: "center" },
  messages,
  duration = DEFAULT_DURATION,
}: {
  onClose: () => void;
  severityType: ValueOf<typeof SEVERITY>;
  position?: SnackbarOrigin;
  messages?: Message[];
  duration?: number;
}) => {
  const translate = useTranslate();

  const [visible, setVisible] = useState(false);

  const handleClose = useCallback(() => {
    setVisible(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (messages) {
      setVisible(true);
    }
  }, [messages]);

  const title = translate(TITLE_TRANSLATIONS[severityType]);

  return (
    <MUISnackBar open={visible} autoHideDuration={duration} onClose={handleClose} anchorOrigin={position}>
      <Alert
        className={classNames(styles.alert, styles[ALERT_COLOR[severityType]])}
        severity={ALERT_COLOR[severityType]}
        onClose={handleClose}
      >
        <AlertTitle>{messages?.length === 1 ? messages[0]?.title : title}</AlertTitle>
        {(messages?.length ?? 0) > 1 ? (
          <List>
            {messages?.map((message, index) => (
              <ListItem key={index}>{message.title}</ListItem>
            ))}
          </List>
        ) : null}
      </Alert>
    </MUISnackBar>
  );
};

export default Snackbar;
