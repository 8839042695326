import { useMemo } from "react";
import { GetCustomerOrderPackageCarrierEventsFilter } from "../../../features/customerOrderPackageCarrierEvent/dataTypes";
import useGetCustomerOrderPackageCarrierEvents from "../../../features/customerOrderPackageCarrierEvent/hooks/useGetCustomerOrderPackageCarrierEvents";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import EventListing from "../../shared/EventListing/EventListing";

const CarrierEvents = ({ packageId }: { packageId: string }) => {
  const filter = useMemo<GetCustomerOrderPackageCarrierEventsFilter>(() => {
    return { paginationOptions: { all: true, amount: 0, from: 0 } };
  }, []);

  const { customerOrderPackageCarrierEvents } = useGetCustomerOrderPackageCarrierEvents({
    filter,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    packageId: packageId,
  });

  const packageCarrierEventsWithDateTime = useMemo(() => {
    return customerOrderPackageCarrierEvents.map(event => {
      return {
        ...event,
        dateTime: `${event.date} ${event.time}`,
      };
    });
  }, [customerOrderPackageCarrierEvents]);

  return customerOrderPackageCarrierEvents.length > 0 ? (
    <EventListing format={DATE_FORMATS.YYYY_MM_DD_HH_mm} events={packageCarrierEventsWithDateTime} />
  ) : null;
};

export default CarrierEvents;
