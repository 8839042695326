import { useCallback } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { resetMessages } from "../../../features/dialog/dialogSlice";
import SnackbarMessage from "../SnackbarMessage/SnackbarMessage";

const UserFeedback = () => {
  const dispatch = useAppDispatch();

  const onReset = useCallback(() => dispatch(resetMessages()), [dispatch]);

  return <SnackbarMessage onReset={onReset} />;
};

export default UserFeedback;
