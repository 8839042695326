export const CONTENT_TYPE = {} as const;

export const CONTACT_TYPE = {
  PERSON: "PERSON",
  CLIENT: "CLIENT",
} as const;

export const DEFAULT_TIMEOUT = 1000;

export const EMBEDDED_PAGE = "embeddedPage";
