import { useCallback, useEffect, useRef } from "react";
import makeCancellablePromise, { CancellablePromise } from "../utils/makeCancellablePromise";

export default function useCancellablePromise(cancelTrigger?: unknown) {
  const promises = useRef<CancellablePromise<Promise<unknown>, unknown>[]>([]);

  useEffect(() => {
    return () => {
      promises.current.forEach(promise => promise.cancel());
      promises.current = [];
    };
  }, [cancelTrigger]);

  const addCancellablePromise = useCallback((promise: CancellablePromise<Promise<unknown>, unknown>) => {
    promises.current.push(promise);

    promise.finally(() => {
      promises.current = promises.current.filter(p => p !== promise);
    });
  }, []);

  const addPromise = useCallback(
    <TPromise extends Promise<unknown>>(promise: TPromise) => {
      const cancellablePromise = makeCancellablePromise(promise);

      addCancellablePromise(cancellablePromise);

      return cancellablePromise;
    },
    [addCancellablePromise],
  );

  return { addCancellablePromise, addPromise };
}
