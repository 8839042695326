import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { GetGoodsOwnersFilter, GoodsOwner, ResponseGoodsOwner, goodsOwnerSliceName } from "../dataTypes";
import { goodsOwnerAdapter } from "../goodsOwnerAdapter";

const getGoodsOwners = async (dispatch: AppDispatch, { filter = {} }: { filter?: Readonly<GetGoodsOwnersFilter> }) => {
  const url = api.goodsOwners.goodsOwners();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response extends ResponseGoodsOwner {
  goodsOwners: PaginationResponse<ResponseGoodsOwner>;
}

export const fetchGoodsOwners = createAsyncThunk<
  Response,
  { filter?: Readonly<GetGoodsOwnersFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(goodsOwnerSliceName + "/fetchGoodsOwners", getGoodsOwners);

export const addFetchGoodsOwnersReducers = (
  builder: EntityActionReducerBuilderInterface<GoodsOwner, AdditionalPaginationState>,
) => {
  builder.addCase(fetchGoodsOwners.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchGoodsOwners.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const goodsOwners = payload.goodsOwners.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        goodsOwnerAdapter.setAll(state, goodsOwners);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        goodsOwnerAdapter.upsertMany(state, goodsOwners);
        break;
    }

    state.count = payload.goodsOwners.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchGoodsOwners.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
