import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  GetPurchaseOrderEventsFilter,
  PurchaseOrderEvent,
  purchaseOrderEventSliceName,
  ResponsePurchaseOrderEvent,
} from "../dataTypes";
import { purchaseOrderEventAdapter } from "../purchaseOrderEventAdapter";

const getPurchaseOrderEvents = async (
  dispatch: AppDispatch,
  { filter, purchaseOrderId }: { filter: Readonly<GetPurchaseOrderEventsFilter>; purchaseOrderId: string },
) => {
  const url = api.purchaseOrder.getPurchaseOrderEvents(purchaseOrderId);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  purchaseOrderEvents: PaginationResponse<ResponsePurchaseOrderEvent>;
}

export const fetchPurchaseOrderEvents = createAsyncThunk<
  Response,
  {
    filter: Readonly<GetPurchaseOrderEventsFilter>;
    fulfilledUpdateMethod: FulfilledUpdateMethod;
    purchaseOrderId: string;
  }
>(purchaseOrderEventSliceName + "/fetchPurchaseOrderEvents", getPurchaseOrderEvents);

export const addFetchPurchaseOrderEventsReducers = (
  builder: EntityActionReducerBuilderInterface<PurchaseOrderEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchPurchaseOrderEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchPurchaseOrderEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const purchaseOrderEvents = payload.purchaseOrderEvents.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        purchaseOrderEventAdapter.setAll(state, purchaseOrderEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        purchaseOrderEventAdapter.upsertMany(state, purchaseOrderEvents);
        break;
    }

    state.count = payload.purchaseOrderEvents.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchPurchaseOrderEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
