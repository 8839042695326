import { createSlice } from "@reduxjs/toolkit";

import { ZetesImageUrlState, zetesImageUrlSliceName } from "./dataTypes";
import { addZetesImageUrlReducers } from "./reducers/fetchZetesImageUrl";

const initialState: ZetesImageUrlState = {};

const slice = createSlice({
  name: zetesImageUrlSliceName,
  initialState: initialState,
  reducers: {
    resetZetesImageUrl: () => initialState,
  },
  extraReducers: builder => {
    addZetesImageUrlReducers(builder);
  },
});

const { actions, reducer } = slice;

export const { resetZetesImageUrl } = actions;

export default reducer;
