import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { purchaseOrderLineEventSliceName } from "./dataTypes";
import { purchaseOrderLineEventAdapter } from "./purchaseOrderLineEventAdapter";
import { addFetchPurchaseOrderLineEventsReducers } from "./reducers/fetchPurchaseOrderLineEvents";

const slice = createSlice({
  name: purchaseOrderLineEventSliceName,
  initialState: purchaseOrderLineEventAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchPurchaseOrderLineEventsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
