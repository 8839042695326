import { URL } from "@myloc/myloc-utils";

const version = process.env.REACT_APP_API_VERSION;

const endpoint = (url: string, params?: any) => {
  const root = () => {
    let domain;
    const host = window.location.host;

    if (process.env.NODE_ENV === "development" || host === process.env.REACT_APP_URL) {
      domain = process.env.REACT_APP_API_DOMAIN;
    } else {
      const protocol = window.location.protocol;

      domain = protocol + "//" + host;
    }

    const baseURI = (domain ?? "") + process.env.REACT_APP_API_PATH;

    return baseURI;
  };

  const templates = { ...params };

  const preparedUrl = url.replace(/{([^}]+)}/g, (_, key) => templates[key]);

  const baseURI = root();
  const fullPath = `${baseURI}${version}${preparedUrl}`;
  const uri = new URL(fullPath);

  return uri;
};

export const api = {
  account: {
    authentication: () => endpoint("/authentication"),
  },
  comflow: {
    closeAllComflowActiveTasks: (sessionId: string) =>
      endpoint("/sessions/comflow/{sessionId}/close-all-comflow-activities", { sessionId }),
    getMylocTokenLoginSessionData: () => endpoint("/sessions/get-myloc-token-login-session-data"),
  },
  customerOrder: {
    customerOrders: () => endpoint("/customer-orders/get-customer-orders"),
    customerOrder: (customerOrderId: string) =>
      endpoint("/customer-orders/{customerOrderId}/get-customer-order", { customerOrderId }),
    customerOrderEvents: (customerOrderId: string) =>
      endpoint("/customer-orders/{customerOrderId}/get-events", { customerOrderId }),
    files: (customerOrderId: string) => endpoint("/customer-orders/{customerOrderId}/get-files", { customerOrderId }),
    lastEvents: () => endpoint("/customer-orders/get-last-events"),
    customerOrderLines: (customerOrderId: string) =>
      endpoint("/customer-orders/{customerOrderId}/get-customer-order-lines", { customerOrderId }),
    getFilesUploadedToWMS: () => endpoint("/customer-orders/get-files-uploaded-to-wms"),
    uploadFilesToWMS: () => endpoint("/customer-orders/upload-files-to-wms"),
  },
  customerOrderLine: {
    customerOrderLines: () => endpoint("/customer-order-lines/get-customer-order-lines"),
  },
  customerOrderPackage: {
    customerOrderPackages: () => endpoint("/customer-order-packages/get-packages"),
    customerOrderPackage: (customerOrderPackageId: string) =>
      endpoint("/customer-order-packages/{customerOrderPackageId}/get-package", { customerOrderPackageId }),
    files: (customerOrderPackageId: string) =>
      endpoint("/customer-order-packages/{customerOrderPackageId}/get-files", { customerOrderPackageId }),
    lastEvents: () => endpoint("/customer-order-packages/get-last-events"),
    customerOrderPackageCarrierEvents: (customerOrderPackageId: string) =>
      endpoint("/customer-order-packages/{customerOrderPackageId}/get-carrier-events", { customerOrderPackageId }),
  },

  file: {
    uploadToParent: (fileParentClassId: string, fileParentId: string) =>
      endpoint("/files/{fileParentClassId}/{fileParentId}", { fileParentClassId, fileParentId }),
  },
  goodsOwners: {
    goodsOwners: () => endpoint("/goods-owners/get-goods-owners"),
    goodsOwner: (goodsOwnerId: string) => endpoint("/goods-owners/{goodsOwnerId}/get-goods-owner", { goodsOwnerId }),
  },
  isak: {
    getIsakRedirectUri: (routeId: string) => endpoint("/isak/{routeId}", { routeId }),
  },
  password: {
    requestReset: () => endpoint("/password/request-reset"),
    update: () => endpoint("/password/update-password"),
    passwordRules: () => endpoint("/password/rules/get-password-rules"),
  },
  purchaseOrder: {
    getPurchaseOrder: (purchaseOrderId: string) =>
      endpoint("/purchase-orders/{purchaseOrderId}/get-purchase-order", { purchaseOrderId }),
    getPurchaseOrders: () => endpoint("/purchase-orders/get-purchase-orders"),
    getPurchaseOrderEvents: (purchaseOrderId: string) =>
      endpoint("/purchase-orders/{purchaseOrderId}/get-events", { purchaseOrderId }),
    getFilesUploadedToWMS: () => endpoint("/purchase-orders/get-files-uploaded-to-wms"),
    uploadFilesToWMS: () => endpoint("/purchase-orders/upload-files-to-wms"),
  },
  purchaseOrderLine: {
    getPurchaseOrderLine: (purchaseOrderLineId: string) =>
      endpoint("/purchase-order-lines/{purchaseOrderLineId}/get-purchase-order-line", { purchaseOrderLineId }),
    getPurchaseOrderLines: () => endpoint("/purchase-order-lines/get-purchase-order-lines"),
    getPurchaseOrderLineEvents: (purchaseOrderLineId: string) =>
      endpoint("/purchase-order-lines/{purchaseOrderLineId}/get-events", { purchaseOrderLineId }),
  },
  region: {
    getRegion: (regionId: string) => endpoint("/regions/{regionId}/get-region", { regionId }),
  },
  sessions: {
    getSessionData: () => endpoint("/sessions/get-session-data"),
  },
  shipment: {
    shipments: () => endpoint("/shipments/get-shipments"),
    shipment: (shipmentId: string) => endpoint("/shipments/{shipmentId}/get-shipment", { shipmentId }),
    trackingEvents: (shipmentId: string) => endpoint("/shipments/{shipmentId}/get-tracking-events", { shipmentId }),
    files: (shipmentId: string) => endpoint("/shipments/{shipmentId}/get-files", { shipmentId }),
  },
  singleSignOn: {
    getUserInfo: () => {
      let port = window.location.port;

      if (port !== "80" && port !== "443" && port !== "") {
        port = ":" + port;
      }

      const url = window.location.protocol + "//" + window.location.hostname + port + "/MyL/redirect_uri?info=json";

      return new URL(url);
    },
  },
  system: {
    getSettings: () => endpoint("/system/get-settings"),
  },
  user: {
    createActionLog: () => endpoint("/users/logs/create-action-log"),
    getCurrentUser: () => endpoint("/users/get-current-user"),
    updateCurrentUser: () => endpoint("/users/update-current-user"),
    nextAccessMessage: () => endpoint("/users/access-messages/get-next-access-message"),
    updateAccessMessage: (messageId: string) =>
      endpoint("/users/access-messages/{messageId}/update-access-message", { messageId }),
  },
  warehouseTransaction: {
    getWarehouseTransactions: () => endpoint("/warehouse-transactions/get-warehouse-transactions"),
    getWarehouseTransaction: (warehouseTransactionId: string) =>
      endpoint("/warehouse-transactions/{warehouseTransactionId}/get-warehouse-transaction", {
        warehouseTransactionId,
      }),
  },
  weekLetter: {
    weekLetters: () => endpoint("/week-letters/get-week-letters"),
    weekLetter: (weekLetterId: string) => endpoint("/week-letters/{weekLetterId}/get-week-letter", { weekLetterId }),
  },
  zetes: {
    getUrl: (customerOrderPackage: string) =>
      endpoint("/get-packages/{customerOrderPackage}/get-zetes-image-url", { customerOrderPackage }),
  },
};

const allowRedirectionToAfterLogin = (desiredPage: string) => {
  //Not allowed pages to restrict redirection to after login - only part of url is needed
  const PASSWORD = "/password";

  const DISALLOWED_PARTS_OF_PATH = [PASSWORD];

  //If returned value from findIndex >= 0, a disallowed part of path has been found among the DISALLOWED urls
  const posInDisallowedArray = DISALLOWED_PARTS_OF_PATH.findIndex(element => {
    return desiredPage.indexOf(element) >= 0;
  });

  //Return allowed (true) if no position in array was found (-1)
  return posInDisallowedArray === -1;
};

export const myloc = {
  webpageUrl: new URL("https://myloc.se"),
  cookieIntegrityUrl: new URL("https://myloc.se/sv/gdpr/"),
  contactEmail: new URL("info@myloc.se"),
  contactPhone: new URL("040-6304643"),
  supportEmail: new URL("support.logistics@myloc.se"),
  address: {
    street: "Kärleksgatan 1A",
    postalNo: "211 45",
    city: "Malmö",
    county: "Skåne",
  },
};

export const pages = {
  allowRedirectionToAfterLogin,
};
