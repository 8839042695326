import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE, RequestState, ValidationResult } from "../../dataTypes";
import getValidationResult from "../../utils/getValidationResults";
import { fetchSessionData } from "../reducers/fetchSessionData";
import { selectRequestState } from "../sessionSelectors";

let requestStateRef: RequestState | undefined;

const useFetchSessionData = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadSessionData = useCallback(async (): Promise<ValidationResult<{ sessionId: string }> | undefined> => {
    if (requestStateRef === REQUEST_STATE.PENDING) return;

    requestStateRef = REQUEST_STATE.PENDING;

    const response = (await dispatch(fetchSessionData())).payload;

    if (!response) return;

    if ("id" in response) {
      return getValidationResult({ data: { sessionId: response.id } });
    }

    return getValidationResult({ messages: { errors: response.errors } });
  }, [dispatch]);

  return loadSessionData;
};

export default useFetchSessionData;
