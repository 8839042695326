import { AsyncThunk, EntitySelectors } from "@reduxjs/toolkit";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Except, SetRequired } from "type-fest";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/rootReducer";
import { AppDispatch } from "../../../app/store";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { IfEmptyObject } from "../../../utils/dataTypes";
import { AdditionalRequestState, Message, REQUEST_STATE, RequestError, UseFetchRefs } from "../../dataTypes";
import mapUseFetchRefs from "../mapUseFetchRefs";
import mergeFetchedDetailRefAndStateEntities from "../mergeFetchedDetailRefAndStateEntities";
import shouldResetRefsOnEmptyState from "../shouldResetRefsOnEmptyState";
import uncapitalizeFirstLetter from "../uncapitalizeFirstLetter";
import { Fetch, Refetch, UseFetch, UseGet, UseLazyGet } from "./dataTypes";
import getFetchEntityName from "./utils/getFetchEntityName";
import getRefetchEntityName from "./utils/getRefetchEntityName";
import getUseFetchEntityName from "./utils/getUseFetchEntityName";
import getUseGetEntityName from "./utils/getUseGetEntityName";
import getUseLazyGetEntityName from "./utils/getUseLazyGetEntityName";

interface Entity extends AdditionalRequestState {}

type SelectEntities<T extends Entity> = EntitySelectors<T, RootState>["selectEntities"];

type MakeSelectEntity<T extends Entity> = (id: string | undefined) => (state: RootState) => T | undefined;

type Params<ThunkArg extends { id: string }> = Omit<ThunkArg, "id">;

type LoadEntity<ThunkArg extends { id: string }> = Params<ThunkArg> & {
  id: string | undefined;
};

type BaseHookReturn<T, EntityName extends string> = {
  [key in Uncapitalize<EntityName>]: T | undefined;
} & {
  isUninitialized: boolean;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  errorMessages: Message[] | undefined;
};

type UseFetchEntityReturn<ThunkArg extends { id: string }> = (args: LoadEntity<ThunkArg>) => Promise<void>;
type UseLazyGetEntityReturn<T, EntityName extends string> = { [key in Fetch<EntityName>]: () => Promise<void> } &
  BaseHookReturn<T, EntityName>;
type UseGetEntityReturn<T, EntityName extends string> = { [key in Refetch<EntityName>]: () => Promise<void> } &
  BaseHookReturn<T, EntityName>;

type ThunkArgExtraParams<ThunkArg extends { id: string }> = IfEmptyObject<
  Params<ThunkArg>,
  unknown,
  { params: Params<ThunkArg> }
>;

type UseFetchEntity<ThunkArg extends { id: string }> = () => UseFetchEntityReturn<ThunkArg>;
type UseLazyGetEntity<ThunkArg extends { id: string }, T extends Entity, EntityName extends string> = (
  args: {
    id: string | undefined;
    loadIfNotInitialized?: boolean;
  } & ThunkArgExtraParams<ThunkArg>,
) => UseLazyGetEntityReturn<T, EntityName>;
type UseGetEntity<ThunkArg extends { id: string }, T extends Entity, EntityName extends string> = (
  args: {
    id: string | undefined;
  } & ThunkArgExtraParams<ThunkArg>,
) => UseGetEntityReturn<T, EntityName>;

type GenerateHooksArgument<T extends Entity, Response, ThunkArg extends { id: string }, EntityName extends string> = {
  selectEntities: SelectEntities<T>;
  makeSelectEntity: MakeSelectEntity<T>;
  thunk: AsyncThunk<
    Response,
    ThunkArg,
    {
      dispatch: AppDispatch;
      rejectValue: RequestError;
    }
  >;
  entityName?: EntityName;
};

type GenerateUseEntityHooksReturn<ThunkArg extends { id: string }, T extends Entity, EntityName extends string> = {
  [key in UseFetch<EntityName>]: UseFetchEntity<ThunkArg>;
} &
  { [key in UseLazyGet<EntityName>]: UseLazyGetEntity<ThunkArg, T, EntityName> } &
  { [key in UseGet<EntityName>]: UseGetEntity<ThunkArg, T, EntityName> };

const hasParams = <T>(obj: unknown): obj is { params: T } => {
  return obj != null && typeof obj === "object" && "params" in obj;
};

function generateUseEntityHooks<T extends Entity, Response, ThunkArg extends { id: string }>(
  args: Except<GenerateHooksArgument<T, Response, ThunkArg, "entity">, "entityName">,
): GenerateUseEntityHooksReturn<ThunkArg, T, "entity">;
function generateUseEntityHooks<T extends Entity, Response, ThunkArg extends { id: string }, EntityName extends string>(
  args: SetRequired<GenerateHooksArgument<T, Response, ThunkArg, EntityName>, "entityName">,
): GenerateUseEntityHooksReturn<ThunkArg, T, EntityName>;

function generateUseEntityHooks<
  T extends Entity,
  Response,
  ThunkArg extends { id: string },
  EntityName extends string = "entity"
>({
  selectEntities,
  makeSelectEntity,
  thunk,
  // @ts-expect-error
  entityName = "entity",
}: GenerateHooksArgument<T, Response, ThunkArg, EntityName>) {
  /* ------------------------------------------------------------
   *  useFetch
   * ------------------------------------------------------------
   */
  let refs: UseFetchRefs;
  const resetRefsOnEmptyState: { [id: string]: boolean } = {};

  const useFetchEntity: UseFetchEntity<ThunkArg> = () => {
    const dispatch = useAppDispatch();

    const entities = useAppSelector(selectEntities);

    if ((refs as UseFetchRefs | undefined) === undefined) {
      refs = mapUseFetchRefs(entities);
    }

    useEffect(() => {
      refs = mergeFetchedDetailRefAndStateEntities(
        refs,
        mapUseFetchRefs(entities),
        shouldResetRefsOnEmptyState(resetRefsOnEmptyState),
      );
    }, [entities]);

    const loadEntity = useCallback(
      async (args: LoadEntity<ThunkArg>) => {
        const id = args.id;

        if (id == null || id === "") return;

        const ref = refs[id];

        if (!ref || ref.requestState !== REQUEST_STATE.PENDING) {
          resetRefsOnEmptyState[id] = false;
          refs[id] = { requestState: REQUEST_STATE.PENDING };
          await dispatch(thunk(args as ThunkArg));
          resetRefsOnEmptyState[id] = true;
        }
      },
      [dispatch],
    );

    return loadEntity;
  };

  /* ------------------------------------------------------------
   *  useLazyGet
   * ------------------------------------------------------------
   */
  const useLazyGetEntity: UseLazyGetEntity<ThunkArg, T, EntityName> = ({ id, loadIfNotInitialized, ...rest }) => {
    const fetchEntity = useFetchEntity();
    const hasInitialized = useRef(false);
    const selectEntity = useMemo(() => makeSelectEntity(id), [id]);
    const entity = useAppSelector(selectEntity);

    const requestState = entity?.requestState;
    const errorMessages = entity?.errorMessages;

    const isUninitialized = requestState === undefined;
    const isLoading = requestState === REQUEST_STATE.PENDING;
    const isError = requestState === REQUEST_STATE.REJECTED;
    const isSuccess = requestState === REQUEST_STATE.FULFILLED;

    const params = hasParams<Params<ThunkArg>>(rest) ? rest.params : undefined;

    const loadEntity = useCallback(async () => {
      await fetchEntity({ ...params, id } as LoadEntity<ThunkArg>);
    }, [fetchEntity, id, params]);

    useEffect(() => {
      hasInitialized.current = false;
    }, [id]);

    useEffect(() => {
      if (loadIfNotInitialized && isUninitialized && !hasInitialized.current) {
        hasInitialized.current = true;
        loadEntity();
      }
    }, [isUninitialized, loadEntity, loadIfNotInitialized]);

    return {
      [uncapitalizeFirstLetter(entityName)]: entity,
      isUninitialized,
      isLoading,
      isError,
      isSuccess,
      errorMessages,
      [getFetchEntityName(entityName)]: loadEntity,
    } as UseLazyGetEntityReturn<T, EntityName>;
  };

  /* ------------------------------------------------------------
   *  useGet
   * ------------------------------------------------------------
   */
  const useGetEntity: UseGetEntity<ThunkArg, T, EntityName> = ({ id, ...paramRest }) => {
    const params = hasParams<Params<ThunkArg>>(paramRest) ? paramRest.params : undefined;
    const { [getFetchEntityName(entityName)]: fetchEntity, isLoading: isLazyLoading, ...rest } = useLazyGetEntity({
      id,
      params,
    } as any);

    const forceUpdate = useForceUpdate();
    const initiationStatus = useRef<"initiating" | "initiated">();

    useEffect(() => {
      const initiateEntity = async () => {
        if (!initiationStatus.current) {
          initiationStatus.current = "initiating";
          forceUpdate();
          await ((fetchEntity as unknown) as () => Promise<void>)();
          initiationStatus.current = "initiated";
        }
      };

      initiateEntity();
    }, [fetchEntity, forceUpdate]);

    const isLoading = !initiationStatus.current || isLazyLoading;

    return ({ ...rest, isLoading, [getRefetchEntityName(entityName)]: fetchEntity } as unknown) as UseGetEntityReturn<
      T,
      EntityName
    >;
  };

  return {
    [getUseFetchEntityName(entityName)]: useFetchEntity,
    [getUseLazyGetEntityName(entityName)]: useLazyGetEntity,
    [getUseGetEntityName(entityName)]: useGetEntity,
  } as GenerateUseEntityHooksReturn<ThunkArg, T, EntityName>;
}

export default generateUseEntityHooks;
