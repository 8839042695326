import useTranslate from "../../../language/useTranslate";
import styles from "./BannerWelcome.module.scss";

const BannerWelcome = () => {
  const translate = useTranslate();

  return (
    <div className={styles.bannerWelcome}>
      <h1 className={styles.sectionTitle}>{translate("WELCOME_TITLE")}</h1>
    </div>
  );
};

export default BannerWelcome;
