import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { fetchWeekLetters } from "../../weekLetter/reducers/fetchWeekLetters";
import { GoodsOwner } from "../dataTypes";
import { goodsOwnerAdapter } from "../goodsOwnerAdapter";

export const addFetchWeekLettersReducers = (builder: EntityActionReducerBuilderInterface<GoodsOwner>) => {
  builder.addCase(fetchWeekLetters.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload.goodsOwners == null) return;

    goodsOwnerAdapter.upsertMany(state, payload.goodsOwners.map(mapFulfilledResponseObject));
  });
};
