import { AdditionalRequestState } from "../dataTypes";

export const passwordRulesSliceName = "passwordRules";

export interface ResponsePasswordRule {
  label: string;
  amount: number;
  regex: string;
}
export interface ResponsePasswordRules {
  regexValidation: string;
  rules: ResponsePasswordRule[];
}
export interface PasswordRules extends ResponsePasswordRules, AdditionalRequestState {}

export interface PasswordRulesState {
  passwordRules?: PasswordRules;
}

export type PasswordRulesFilter =
  | { username: string; expiredPassword: string; token?: never }
  | { token: string; username?: never; expiredPassword?: never };

export type GetPasswordRulesFilter = PasswordRulesFilter;
