import { MenuItem } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import React, { useEffect, useState } from "react";
import { MAX_NUMER_OF_LINES_TO_EXPORT } from "../../../../config/systemSettings";
import { FULFILLED_UPDATE_METHOD } from "../../../../features/dataTypes";
import { PaginatedRequestFilter } from "../../../../features/utils/pagination";
import { ValueOf } from "../../../../utils/dataTypes";
import { EXPORT_TYPE } from "../GridToolbarUtil";
import { ExportValuesType, FetchFunctionType } from "../datatypes";

const GridToolbarExport = ({
  apiRef,
  fetchFunction,
  filter,
  setExportValues,
  type,
}: {
  apiRef: React.MutableRefObject<GridApiPremium>;
  fetchFunction: FetchFunctionType;
  filter?: PaginatedRequestFilter;
  setExportValues: React.Dispatch<React.SetStateAction<ExportValuesType>>;
  type: ValueOf<typeof EXPORT_TYPE>;
}) => {
  const [exportFilter, setExportFilter] = useState<PaginatedRequestFilter>();

  useEffect(() => {
    if (filter === undefined) return;

    const newFilter: PaginatedRequestFilter = {
      ...filter,
      paginationOptions: { all: false, amount: MAX_NUMER_OF_LINES_TO_EXPORT, from: 0 },
    };

    setExportFilter(newFilter);
  }, [filter]);

  return (
    <MenuItem
      onClick={async () => {
        const rowsPromise = fetchFunction({
          filter: exportFilter,
          fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
        });

        setExportValues({
          ids: { allIds: [], uniqueIds: new Set() },
          exportType: type,
          promise: rowsPromise,
        });
      }}
    >
      {apiRef.current.getLocaleText(type)}
    </MenuItem>
  );
};

export default GridToolbarExport;
