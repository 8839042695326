import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Box,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { File } from "../../../../features/file/dataTypes";
import useTranslate from "../../../../language/useTranslate";
import { DATE_FORMATS } from "../../../../utils/DateUtil";

const FileList = ({ files, isLoading = false }: { files: File[]; isLoading?: boolean }) => {
  const translate = useTranslate();

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: "1px solid #00000033",
        minHeight: "10rem",
      }}
    >
      <Table aria-label="simple table">
        <TableBody>
          {isLoading ? (
            <TableCell align="center" sx={{ border: "none" }}>
              <CircularProgress />
            </TableCell>
          ) : files.length === 0 ? (
            <TableCell component="th" scope="row" align="center" sx={{ border: "none" }}>
              <Typography padding={"2.2rem"}>{translate("NO_ROWS")}</Typography>
            </TableCell>
          ) : (
            files.map(row => (
              <TableRow
                key={row.id}
                sx={{
                  "& .MuiTableCell-root": {
                    border: "none",
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Box display={"flex"}>
                    <Link
                      href={row.url}
                      display="flex"
                      alignItems="center"
                      underline="none"
                      gap={"3.2rem"}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        "&:hover": {
                          "& .underline-on-hover": {
                            textDecoration: "underline",
                          },
                        },
                      }}
                    >
                      <InsertDriveFileIcon />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography className="underline-on-hover">{row.name}</Typography>
                        <Box display={"flex"} gap={"2.4rem"}>
                          <Typography color="textSecondary" variant="caption">
                            {dayjs(row.createdTimestamp).format(DATE_FORMATS.YYYY_MM_DD_HH_mm)}
                          </Typography>
                          <Typography color="textSecondary" variant="caption">
                            {row.createdBy}
                          </Typography>
                        </Box>
                      </Box>
                    </Link>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileList;
