import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { fetchWeekLetters } from "../../weekLetter/reducers/fetchWeekLetters";
import { Region } from "../dataTypes";
import { regionAdapter } from "../regionAdapter";

export const addFetchWeekLettersReducers = (builder: EntityActionReducerBuilderInterface<Region>) => {
  builder.addCase(fetchWeekLetters.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload.regions == null) return;

    regionAdapter.upsertMany(state, payload.regions.map(mapFulfilledResponseObject));
  });
};
