import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  GetPurchaseOrderLinesFilter,
  PurchaseOrderLine,
  purchaseOrderLineSliceName,
  ResponsePurchaseOrderLine,
} from "../dataTypes";
import { purchaseOrderLineAdapter } from "../purchaseOrderLineAdapter";

const getPurchaseOrderLines = async (
  dispatch: AppDispatch,
  { filter }: { filter: Readonly<GetPurchaseOrderLinesFilter> },
) => {
  const url = api.purchaseOrderLine.getPurchaseOrderLines();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  purchaseOrderLines: PaginationResponse<ResponsePurchaseOrderLine>;
}

export const fetchPurchaseOrderLines = createAsyncThunk<
  Response,
  { filter: Readonly<GetPurchaseOrderLinesFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(purchaseOrderLineSliceName + "/fetchPurchaseOrderLines", getPurchaseOrderLines);

export const addFetchPurchaseOrderLinesReducers = (
  builder: EntityActionReducerBuilderInterface<PurchaseOrderLine, AdditionalPaginationState>,
) => {
  builder.addCase(fetchPurchaseOrderLines.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchPurchaseOrderLines.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const purchaseOrderLines = payload.purchaseOrderLines.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        purchaseOrderLineAdapter.setAll(state, purchaseOrderLines);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        purchaseOrderLineAdapter.upsertMany(state, purchaseOrderLines);
        break;
    }

    state.count = payload.purchaseOrderLines.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchPurchaseOrderLines.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
