import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE, RequestState, ValidationResult } from "../../dataTypes";

import { fetchSystemSettings } from "../reducers/fetchSystemSettings";
import { selectRequestState } from "../systemSettingsSelectors";

let requestStateRef: RequestState | undefined;

const useFetchSystemSettings = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadSystemSettings = useCallback(async (): Promise<ValidationResult<undefined> | undefined> => {
    if (requestStateRef === REQUEST_STATE.PENDING) return;

    requestStateRef = REQUEST_STATE.PENDING;

    await dispatch(fetchSystemSettings());
  }, [dispatch]);

  return loadSystemSettings;
};

export default useFetchSystemSettings;
