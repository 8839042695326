import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { customerOrderPackageCarrierEventAdapter } from "./customerOrderPackageCarrierEventAdapter";
import { customerOrderPackageCarrierEventSliceName } from "./dataTypes";
import { addFetchCustomerOrderPackageCarrierEventsReducers } from "./reducers/fetchCustomerOrderPackageCarrierEvents";

const slice = createSlice({
  name: customerOrderPackageCarrierEventSliceName,
  initialState: customerOrderPackageCarrierEventAdapter.getInitialState<
    AdditionalRequestState & AdditionalPaginationState
  >({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderPackageCarrierEventsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
