import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { ReactNode } from "react";
import { LocalizationProvider } from "./localization/LocalizationContext";
import useTheme from "./theme/hooks/useTheme";
import { ThemeProvider } from "./theme/ThemeContext";

const ThemedProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <MUIThemeProvider theme={theme}>
      <LocalizationProvider>{children}</LocalizationProvider>
    </MUIThemeProvider>
  );
};

export const PreferenceProvider = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider>
      <ThemedProvider>{children}</ThemedProvider>
    </ThemeProvider>
  );
};
