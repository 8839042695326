import { AdditionalRequestState, IdObject, ImportedDataFilter } from "../dataTypes";
import { ResponseGoodsOwner } from "../goodsOwner/dataTypes";
import { ResponseRegion } from "../region/dataTypes";
import { SortFilter, SortedPaginationFilter } from "../utils/pagination";

export const weekLetterSliceName = "weekLetter";

export interface ResponseWeekLetter extends IdObject {
  title: string;
  body: string;
  validFromDate: number;
  validToDate: number;
  goodsOwnerId: string;
  regionId: string;
  attachmentURL: string;
}

interface WeekLetterSorting extends SortFilter {
  field: "ValidFrom" | "NewsWeekId";
}
export interface WeekLettersFilter extends SortedPaginationFilter {
  sort?: WeekLetterSorting[];
}
export interface GetWeekLettersFilter extends WeekLetterImportedDataFilter {
  filter?: WeekLettersFilter;
}
export interface WeekLetterImportedDataFilter {
  region?: ImportedDataFilter;
  goodsOwner?: ImportedDataFilter;
}

export interface WeekLettersImportedDataResponse {
  regions?: ResponseRegion[];
  goodsOwners?: ResponseGoodsOwner[];
}

export interface WeekLetter extends ResponseWeekLetter, AdditionalRequestState {}
