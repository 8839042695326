import { IdObject } from "../dataTypes";

export interface LastEvent extends IdObject {
  client: string;
  lastEvent: string;
  typeOfEvent: string;
}

export const LAST_EVENT_FIELDS = {
  CLIENT: "client",
  LAST_EVENT: "lastEvent",
  TYPE_OF_EVENT: "typeOfEvent",
};
