import { useState, useEffect } from "react";

export function useMobile() {
  const currentBreakpoint = useCurrentBreakpoint();

  return currentBreakpoint <= Breakpoints.Small;
}

export function useTablet() {
  const currentBreakpoint = useCurrentBreakpoint();

  return currentBreakpoint > Breakpoints.Small && currentBreakpoint <= Breakpoints.Medium;
}

export function useDesktop() {
  const currentBreakpoint = useCurrentBreakpoint();

  return currentBreakpoint > Breakpoints.Medium;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export default function useCurrentBreakpoint() {
  const windowDimensions = useWindowDimensions();

  function getBreakpoint() {
    const width = windowDimensions.width;

    if (width <= Breakpoints.Small) return Breakpoints.Small;
    if (width <= Breakpoints.Medium) return Breakpoints.Medium;
    if (width <= Breakpoints.Large) return Breakpoints.Large;

    return Breakpoints.Full;
  }

  return getBreakpoint();
}

const Breakpoints = {
  Small: 600,
  Medium: 1000,
  Large: 1366,
  Full: 1920,
} as const;

export function isTouchScreen() {
  if ("ontouchstart" in document.documentElement) {
    return true;
  }

  return false;
}
