import { useCallback, useEffect } from "react";
import { SetRequired } from "type-fest";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import useTranslate from "../../../language/useTranslate";
import { RequireSelected } from "../../../utils/dataTypes";
import { MylocLoginTokenRequest } from "../../comflowSessionValues/dataTypes";
import { fetchComflowSessionValues } from "../../comflowSessionValues/reducers/fetchComflowSessionValues";
import { REQUEST_STATE, RequestState, ValidationResult } from "../../dataTypes";
import { setErrors } from "../../dialog/dialogSlice";
import getEmptyMessages from "../../utils/getEmptyMessages";
import getValidationResult from "../../utils/getValidationResults";
import { LogInRequest } from "../dataTypes";
import { logIn as logInAction } from "../reducers/logIn";
import { selectRequestState } from "../sessionSelectors";
import { saveSessionId } from "../utils/saveSessionId";
import { setRememberMe } from "../utils/setRememberMe";

type LogInRequestType = RequireSelected<
  Pick<LogInRequest, "username" | "password" | "persistent">,
  "username" | "password"
>;

type LogInWithMylocTokenType = RequireSelected<Pick<LogInRequest, "mylocLoginToken" | "persistent">, "mylocLoginToken">;

let requestStateRef: RequestState | undefined;

const useLogIn = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  const requestState = useAppSelector(selectRequestState);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const errorMessages = useAppSelector(state => state.session.errorMessages);

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const validateLogIn = useCallback(
    (
      additionalValidation: (request: LogInRequest) => ValidationResult<LogInRequest>,
      request?: Partial<LogInRequest>,
    ): ValidationResult<LogInRequest> => {
      if (!request) {
        return getValidationResult({
          messages: { errors: [{ title: translate("DATA_MISSING"), code: "DATA_MISSING" }] },
        });
      }

      return additionalValidation(request);
    },
    [translate],
  );

  const validateStandardLogin = useCallback(
    (request: Partial<LogInRequest>): ValidationResult<LogInRequestType> => {
      const messages = getEmptyMessages();

      if (!request.username) {
        messages.errors.push({
          title: translate("USERNAME_MISSING"),
          code: "USERNAME_MISSING",
          field: { field: "username" },
        });
      }

      if (!request.password) {
        messages.errors.push({
          title: translate("PASSWORD_MISSING"),
          code: "PASSWORD_MISSING",
          field: { field: "password" },
        });
      }

      if (messages.errors.length > 0) {
        return getValidationResult({ messages });
      }

      const validatedRequest = request as SetRequired<Partial<LogInRequest>, "password" | "username">;

      return getValidationResult({
        data: {
          password: validatedRequest.password,
          username: validatedRequest.username,
          persistent: validatedRequest.persistent,
        },
      });
    },
    [translate],
  );

  const _logIn = useCallback(
    async (
      logInRequest: LogInRequest,
      additionalValidation: (request: LogInRequest) => ValidationResult<LogInRequest>,
    ) => {
      const result = validateLogIn(additionalValidation, logInRequest);

      if (!result.success) {
        dispatch(setErrors(result.messages));
      } else {
        if (requestStateRef !== REQUEST_STATE.PENDING) {
          requestStateRef = REQUEST_STATE.PENDING;

          const response = await dispatch(logInAction(result.data));

          setRememberMe(dispatch, true);

          return response;
        }
      }
    },
    [dispatch, validateLogIn],
  );

  const logInWithMylocToken = useCallback(
    async (logInRequest: MylocLoginTokenRequest) => {
      if (requestStateRef !== REQUEST_STATE.PENDING) {
        requestStateRef = REQUEST_STATE.PENDING;

        const response = await dispatch(fetchComflowSessionValues(logInRequest));

        const comflowSessionResponse = response.payload;

        if (comflowSessionResponse == null) return;
        if (!("sessionId" in comflowSessionResponse)) return;

        if (comflowSessionResponse.sessionId) {
          setRememberMe(dispatch, true);
          saveSessionId(comflowSessionResponse.sessionId, true);
        }
      }
    },
    [dispatch],
  );

  const logIn = useCallback(
    async (logInRequest: LogInRequestType) => await _logIn(logInRequest, validateStandardLogin),
    [_logIn, validateStandardLogin],
  );

  const loginWithMylocToken = useCallback(
    async (logInRequest: LogInWithMylocTokenType) => await logInWithMylocToken(logInRequest),
    [logInWithMylocToken],
  );

  return { logIn, loginWithMylocToken, isUninitialized, isLoading, isSuccess, isError, errorMessages };
};

export default useLogIn;
