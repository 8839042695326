import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import locales, { DEFAULT_LANGUAGE } from "./locales";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: locales,
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,

    interpolation: {
      escapeValue: false,
    },
  });

export const translate = i18n.t.bind(i18n);
export const currentLanguage = i18n.language;
export default i18n;
