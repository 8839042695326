import generateUseEntityHooks from "../../utils/hooks/generateUseEntityHooks";
import { makeSelectCustomerOrder, selectCustomerOrdersEntities } from "../customerOrderSelectors";
import { fetchCustomerOrder } from "../reducers/fetchCustomerOrder";

const {
  useFetchCustomerOrder,
  useLazyGetCustomerOrder: useLazyGetEntity,
  useGetCustomerOrder: useGetEntity,
} = generateUseEntityHooks({
  selectEntities: selectCustomerOrdersEntities,
  makeSelectEntity: makeSelectCustomerOrder,
  thunk: fetchCustomerOrder,
  entityName: "customerOrder",
});

interface Params {
  id: string | undefined;
}

const useLazyGetCustomerOrder = ({
  id,
  loadIfNotInitialized = false,
}: Params & {
  loadIfNotInitialized?: boolean;
}) => useLazyGetEntity({ id, loadIfNotInitialized });

const useCustomerOrder = ({ id }: Params) => useGetEntity({ id });

export { useCustomerOrder, useFetchCustomerOrder, useLazyGetCustomerOrder };
