import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { PaginationResponse } from "../../dataTypes";
import { fileSliceName, ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { GetFilesUploadedToWMSFilter } from "../dataTypes";

async function getFilesUploadedToWMS(
  dispatch: AppDispatch,
  { filter }: { filter: Readonly<GetFilesUploadedToWMSFilter> },
) {
  const request = new Request(api.purchaseOrder.getFilesUploadedToWMS(), Method.POST);

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch }));
}

interface Response {
  files: PaginationResponse<ResponseFile>;
}

export const fetchFilesUploadedToWMS = createAsyncThunk<Response, { filter: Readonly<GetFilesUploadedToWMSFilter> }>(
  fileSliceName + "/getFilesUploadedToWMS",
  getFilesUploadedToWMS,
);
