import { AdditionalRequestState, IdObject } from "../dataTypes";

export const comflowSessionValuesSliceName = "comflowSessionValues";

export interface MylocLoginTokenRequest {
  mylocLoginToken: string;
}

interface SessionValue extends IdObject {
  value?: string;
}

export interface ComflowSessionValuesResponse {
  initSessionValues: SessionValue[];
  sessionId: string;
}

export interface ComflowSessionState extends AdditionalRequestState {
  initSessionValues?: SessionValue[];
  sessionId?: string;
}
