import generateUseEntityHooks from "../../utils/hooks/generateUseEntityHooks";
import { WeekLetterImportedDataFilter } from "../dataTypes";
import { fetchWeekLetter } from "../reducers/fetchWeekLetter";
import { makeSelectWeekLetter, selectWeekLettersEntities } from "../weekLetterSelectors";

const {
  useFetchWeekLetter,
  useLazyGetWeekLetter: useLazyGetEntity,
  useGetWeekLetter: useGetEntity,
} = generateUseEntityHooks({
  selectEntities: selectWeekLettersEntities,
  makeSelectEntity: makeSelectWeekLetter,
  thunk: fetchWeekLetter,
  entityName: "weekLetter",
});

interface Params {
  id: string | undefined;
  filter?: Readonly<WeekLetterImportedDataFilter>;
}

const useLazyGetWeekLetter = ({
  id,
  filter,
  loadIfNotInitialized = false,
}: Params & {
  loadIfNotInitialized?: boolean;
}) => useLazyGetEntity({ id, params: { filter }, loadIfNotInitialized });

const useGetWeekLetter = ({ id, filter }: Params) => useGetEntity({ id, params: { filter } });

export { useFetchWeekLetter, useLazyGetWeekLetter, useGetWeekLetter };
