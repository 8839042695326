import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectErrorMessages, selectRequestState, selectSessionData } from "../sessionSelectors";
import useFetchSessionData from "./useFetchSessionData";

const useLazyGetSessionData = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const fetchSessionData = useFetchSessionData();

  const requestState = useAppSelector(selectRequestState);
  const errorMessages = useAppSelector(selectErrorMessages);

  const sessionData = useAppSelector(selectSessionData);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadSessionData = useCallback(async () => {
    await fetchSessionData();
  }, [fetchSessionData]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized) loadSessionData();
  }, [isUninitialized, loadIfNotInitialized, loadSessionData]);

  return {
    sessionData,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessages,
    fetchSessionData: loadSessionData,
  };
};

export default useLazyGetSessionData;
