import { ComponentPropsWithoutRef } from "react";
import { Route } from "react-router";
import FallbackSuspense from "../../../utils/FallbackSuspense";

export default function LazyRoute({ children, ...rest }: ComponentPropsWithoutRef<typeof Route>) {
  return (
    <Route {...rest}>
      <FallbackSuspense>{children}</FallbackSuspense>
    </Route>
  );
}
