export const ACTION_LOG_ACTION = {
  LOGIN: "login",
  LOGOUT: "logout",
  NAVIGATE: "navigate",
} as const;

export const ACTION_LOG_TYPE_NAVIGATE = {
  MENU: "menu",
  QUICK_ACCESS: "quick-access",
} as const;

export const ACTION_LOG_TYPE = {
  ...ACTION_LOG_TYPE_NAVIGATE,
  MANUAL: "manual",
} as const;
