import type { TableContainer, TableRow } from "@mui/material";
import type { ComponentPropsWithoutRef } from "react";

export const DEFAULT_TABLE_CONTAINER_STYLE: ComponentPropsWithoutRef<typeof TableContainer>["sx"] = {
  margin: "1.5rem 4.5rem",
  border: "1px solid",
  borderColor: "rgba(0, 0, 0, 0.1)",
  borderRadius: "16px",
  width: "40%",
};

export const DEFAULT_TABLE_HEADER_ROW_STYLE: ComponentPropsWithoutRef<typeof TableRow>["sx"] = {
  borderBottom: "1px solid",
  borderColor: "rgba(0, 0, 0, 0.1)",
  "& .MuiTableCell-root": {
    border: "none",
  },
};

export const DEFAULT_TABLE_BODY_ROW_STYLE: ComponentPropsWithoutRef<typeof TableRow>["sx"] = {
  border: "none",
  height: "1rem",
  "& .MuiTableCell-root": {
    border: "none",
  },
};
