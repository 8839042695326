import { CheckCircle } from "../../../assets/icons";
import { PasswordRules as PasswordRulesType } from "../../../features/passwordRules/dataTypes";
import useTranslate from "../../../language/useTranslate";
import styles from "./PasswordRule.module.scss";

const PasswordRules = ({ rules, password }: { rules: PasswordRulesType["rules"] | undefined; password: string }) => {
  const translate = useTranslate();

  return (
    <section className={styles.container}>
      <>
        <p>{translate("PASSWORD_HAS_TO_HAVE")}</p>
        {rules?.map((rule, index) => (
          <PasswordRule key={index} rule={rule} password={password} />
        ))}
      </>
    </section>
  );
};

const PasswordRule = ({ rule, password }: { rule: PasswordRuleType; password: string }) => {
  return (
    <label className={styles.rule}>
      <CheckCircle customCssClass={isRuleFulfilled({ rule, password }) ? styles.accepted : styles.notAccepted} />
      {rule.label}
    </label>
  );
};

function isRuleFulfilled({ rule, password }: { rule: PasswordRuleType; password: string }) {
  const regex = new RegExp(rule.regex, "g");

  const matchResult = password.match(regex);

  return matchResult !== null && matchResult.length >= rule.amount;
}

export function isRulesFulfilled(rules: PasswordRulesType["rules"], password: string) {
  for (const rule of rules) {
    if (!isRuleFulfilled({ rule, password })) return false;
  }

  return true;
}

type PasswordRuleType = PasswordRulesType["rules"][number];

export default PasswordRules;
