import { AdditionalRequestState } from "../dataTypes";

export const zetesImageUrlSliceName = "zetesImageUrl";

export interface ZetesImageUrlRequest {
  customerOrderPackage: string;
}

interface ZetesImageUrl {
  url?: string;
}

export interface ZetesImageUrlState extends AdditionalRequestState {
  zetesImageUrl?: ZetesImageUrl;
}
