import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { customerOrderPackageFileAdapter } from "./customerOrderPackageFileAdapter";
import { customerOrderPackageFileSliceName } from "./dataTypes";
import { addFetchCustomerOrderPackageFilesReducers } from "./reducers/fetchCustomerOrderPackageFiles";

const slice = createSlice({
  name: customerOrderPackageFileSliceName,
  initialState: customerOrderPackageFileAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderPackageFilesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
