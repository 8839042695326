import da from "./da";
import en from "./en";
import sv from "./sv";

export default {
  da,
  en,
  sv,
};

export const LANGUAGES = {
  da_DK: "da",
  en_US: "en",
  sv_SE: "sv",
} as const;

export const LANGUAGE_LIST = [
  { code: "da_DK", name: "Dansk" },
  { code: "en_US", name: "English" },
  { code: "sv_SE", name: "Svenska" },
];

export const DEFAULT_LANGUAGE = "sv";
