import { createSlice } from "@reduxjs/toolkit";

import { IsakRedirectUriState, isakRedirectUriSliceName } from "./dataTypes";
import { addIsakRedirectUriReducers } from "./reducers/fetchIsakRedirectUri";

const initialState: IsakRedirectUriState = {};

const slice = createSlice({
  name: isakRedirectUriSliceName,
  initialState: initialState,
  reducers: {
    resetIsakRedirect: () => initialState,
  },
  extraReducers: builder => {
    addIsakRedirectUriReducers(builder);
  },
});

const { actions, reducer } = slice;

export const { resetIsakRedirect } = actions;

export default reducer;
