import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetCustomerOrderPackagesFilter } from "../dataTypes";
import useLazyGetCustomerOrderPackages from "./useLazyGetCustomerOrderPackages";

const useGetCustomerOrderPackages = (args: {
  filter?: Readonly<GetCustomerOrderPackagesFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  //lägg till infintescroll
}) => {
  const { isLoading: isLazyLoading, fetchCustomerOrderPackages, ...rest } = useLazyGetCustomerOrderPackages(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrderPackages();
  }, [fetchCustomerOrderPackages]);

  useEffect(() => {
    const initiateCustomerOrderPackages = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrderPackages();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrderPackages();
  }, [fetchCustomerOrderPackages, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrderPackages: fetchCustomerOrderPackages };
};

export default useGetCustomerOrderPackages;
