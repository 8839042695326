import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { PurchaseOrder, purchaseOrderSliceName, ResponsePurchaseOrder } from "../dataTypes";
import { purchaseOrderAdapter } from "../purchaseOrderAdapter";

const getPurchaseOrder = async (dispatch: AppDispatch, { id }: { id: string }) => {
  const url = api.purchaseOrder.getPurchaseOrder(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  purchaseOrder: ResponsePurchaseOrder;
}

export const fetchPurchaseOrder = createAsyncThunk<Response, { id: string }>(
  purchaseOrderSliceName + "/fetchPurchaseOrder",
  getPurchaseOrder,
);

export const addFetchPurchaseOrderReducers = (builder: EntityActionReducerBuilderInterface<PurchaseOrder>) => {
  builder.addCase(fetchPurchaseOrder.pending, (state, action) => {
    const { id } = action.meta.arg;
    const purchaseOrder = state.entities[id] ?? ({ id } as PurchaseOrder);

    purchaseOrderAdapter.upsertOne(state, {
      ...purchaseOrder,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchPurchaseOrder.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    purchaseOrderAdapter.updateOne(state, {
      id,
      changes: mapFulfilledResponseObject(payload.purchaseOrder),
    });
  });
  builder.addCase(fetchPurchaseOrder.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      purchaseOrderAdapter.removeOne(state, id);
    } else {
      purchaseOrderAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
