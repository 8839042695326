import { createSlice } from "@reduxjs/toolkit";

import { ComflowSessionState, comflowSessionValuesSliceName } from "./dataTypes";
import { addComflowSessionValuesReducers } from "./reducers/fetchComflowSessionValues";

const initialState: ComflowSessionState = {};

const slice = createSlice({
  name: comflowSessionValuesSliceName,
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    addComflowSessionValuesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
