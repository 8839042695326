import PropTypes from "prop-types";
import { useAppSelector } from "../../app/hooks";
import { selectSessionId } from "../../features/session/sessionSelectors";
import Page from "../shared/Page/Page";
import BannerWelcome from "./BannerWelcome/BannerWelcome";
import ContentWelcome from "./Welcome/ContentWelcome";
import LoginWelcome from "./Welcome/LoginWelcome";

const StartPage = ({ desiredPage }: { desiredPage?: string }) => {
  const loggedIn = !!useAppSelector(selectSessionId);

  return loggedIn ? (
    <>
      <BannerWelcome />
      <Page>
        <ContentWelcome />
      </Page>
    </>
  ) : (
    <LoginWelcome desiredPage={desiredPage} />
  );
};

StartPage.propTypes = {
  desiredPage: PropTypes.string,
};

export default StartPage;
