import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { userAccessMessageSliceName } from "./dataTypes";
import { addFetchNextUserAccessMessageReducers } from "./reducers/fetchNextUserAccessMessage";
import { addUpdateUserAccessMessageReducers } from "./reducers/updateUserAccessMessage";
import { userAccessMessageAdapter } from "./userAccessMessageAdapter";

const slice = createSlice({
  name: userAccessMessageSliceName,
  initialState: userAccessMessageAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchNextUserAccessMessageReducers(builder);
    addUpdateUserAccessMessageReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
