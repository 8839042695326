import { useLocation } from "react-router";
import { Breadcrumb, BreadcrumbLocationState } from "./dataTypes";

const useBreadcrumbs = <TBreadcrumbData = unknown>() => {
  const { pathname, state } = useLocation<BreadcrumbLocationState | undefined>();

  const breadcrumbs = state?.breadcrumbs;
  const lastBreadcrumb = breadcrumbs?.at(-1);
  const breadcrumb = lastBreadcrumb?.link === pathname ? (lastBreadcrumb as Breadcrumb<TBreadcrumbData>) : undefined;

  return {
    breadcrumbs,
    breadcrumb,
  };
};

export default useBreadcrumbs;
