import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { customerOrderEventAdapter } from "./customerOrderEventAdapter";
import { customerOrderEventSliceName } from "./dataTypes";
import { addFetchCustomerOrderEventsReducers } from "./reducers/fetchCustomerOrderEvents";

const slice = createSlice({
  name: customerOrderEventSliceName,
  initialState: customerOrderEventAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderEventsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
