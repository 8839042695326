import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderEventAdapter } from "./customerOrderEventAdapter";
import { CustomerOrderEvent, customerOrderEventSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderEventSliceName];

const adapterSelectors = customerOrderEventAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderEventsEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderEvents = adapterSelectors.selectAll;
export const makeSelectCustomerOrderEvent = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
export const makeSelectCustomerOrderEventsById = (ids: string[] = []) => (state: RootState) =>
  ids.reduce<CustomerOrderEvent[]>((customerOrderEvents, id) => {
    const customerOrderEvent = makeSelectCustomerOrderEvent(id)(state);

    return customerOrderEvent ? [...customerOrderEvents, customerOrderEvent] : customerOrderEvents;
  }, []);
