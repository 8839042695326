//Note that the operators will appear in the order of this lists if standard implementation is used

export const COMMON_OPERATORS = {
  IS_EMPTY: "isEmpty",
  IS_NOT_EMPTY: "isNotEmpty",
} as const;

export const STRING_OPERATORS = {
  STARTS_WITH: "startsWith",
  ENDS_WITH: "endsWith",
  EQUALS: "equals",
  CONTAINS: "contains",
} as const;

export const NUMBER_OPERATORS = {
  EQ: "=",
  NE: "!=",
  GT: ">",
  GE: ">=",
  LT: "<",
  LE: "<=",
  ...COMMON_OPERATORS,
} as const;

export const DATE_OPERATORS = {
  IS: "is",
  IS_NOT: "not",
  AFTER: "after",
  ON_OR_AFTER: "onOrAfter",
  BEFORE: "before",
  ON_OR_BEFORE: "onOrBefore",
  ...COMMON_OPERATORS,
} as const;

export const BOOLEAN_OPERATORS = {
  IS: "is",
} as const;
