import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { GetWeekLettersFilter } from "../dataTypes";
import { selectCount, selectErrorMessages, selectRequestState, selectWeekLetters } from "../weekLetterSelectors";
import useFetchWeekLetters from "./useFetchWeekLetters";

const useLazyGetWeekLetters = ({
  filter,
  fulfilledUpdateMethod,
  loadIfNotInitialized = false,
}: {
  filter?: Readonly<GetWeekLettersFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchWeekLetters = useFetchWeekLetters();
  const hasInitialized = useRef(false);

  const requestState = useAppSelector(selectRequestState);
  const errorMessages = useAppSelector(selectErrorMessages);
  const count = useAppSelector(selectCount);
  const weekLetters = useAppSelector(selectWeekLetters);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadWeekLetters = useCallback(async () => {
    await fetchWeekLetters({ filter, fulfilledUpdateMethod });
  }, [fetchWeekLetters, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitialized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitialized.current) {
      hasInitialized.current = true;
      loadWeekLetters();
    }
  }, [isUninitialized, loadIfNotInitialized, loadWeekLetters]);

  return {
    weekLetters,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessages,
    fetchWeekLetters: loadWeekLetters,
  };
};

export default useLazyGetWeekLetters;
