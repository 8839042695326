import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { ResponseWarehouseTransaction, WarehouseTransaction, warehouseTransactionSliceName } from "../dataTypes";
import { warehouseTransactionAdapter } from "../warehouseTransactionAdapter";

const getWarehouseTransaction = async (dispatch: AppDispatch, { id }: { id: string }) => {
  const url = api.warehouseTransaction.getWarehouseTransaction(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  warehouseTransaction: ResponseWarehouseTransaction;
}

export const fetchWarehouseTransaction = createAsyncThunk<Response, { id: string }>(
  warehouseTransactionSliceName + "/fetchWarehouseTransaction",
  getWarehouseTransaction,
);

export const addFetchWarehouseTransactionReducers = (
  builder: EntityActionReducerBuilderInterface<WarehouseTransaction>,
) => {
  builder.addCase(fetchWarehouseTransaction.pending, (state, action) => {
    const { id } = action.meta.arg;
    const warehouseTransaction = state.entities[id] ?? ({ id } as WarehouseTransaction);

    warehouseTransactionAdapter.upsertOne(state, {
      ...warehouseTransaction,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchWarehouseTransaction.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    warehouseTransactionAdapter.updateOne(state, {
      id,
      changes: mapFulfilledResponseObject(payload.warehouseTransaction),
    });
  });
  builder.addCase(fetchWarehouseTransaction.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      warehouseTransactionAdapter.removeOne(state, id);
    } else {
      warehouseTransactionAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
