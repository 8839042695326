import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderLineAdapter } from "./customerOrderLineAdapter";
import { CustomerOrderLine, customerOrderLineSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderLineSliceName];

const adapterSelectors = customerOrderLineAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderLinesEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderLines = adapterSelectors.selectAll;
export const makeSelectCustomerOrderLine = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
export const makeSelectCustomerOrderLinesById = (ids: string[] = []) => (state: RootState) =>
  ids.reduce<CustomerOrderLine[]>((customerOrderLines, id) => {
    const customerOrderLine = makeSelectCustomerOrderLine(id)(state);

    return customerOrderLine ? [...customerOrderLines, customerOrderLine] : customerOrderLines;
  }, []);
