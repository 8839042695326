import { AdditionalRequestState } from "../dataTypes";
import { LastEvent } from "../lastEvent/dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const customerOrderLastEventSliceName = "customerOrderLastEvent";

export interface ResponseCustomerOrderLastEvent extends LastEvent {}

export interface GetCustomerOrderLastEventsFilter extends ClientPaginatedRequestFilter {}

export interface CustomerOrderLastEvent extends ResponseCustomerOrderLastEvent, AdditionalRequestState {}
