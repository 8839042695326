import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";

import { ResponseUser, UserImportedDataResponse } from "../../user/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { CurrentUser, GetCurrentUserFilter, SessionState, sessionSliceName } from "../dataTypes";

export interface UserAPIResponse extends UserImportedDataResponse {
  user: ResponseUser;
}

async function getCurrentUser(dispatch: AppDispatch, { filter = {} }: { filter?: Readonly<GetCurrentUserFilter> }) {
  const url = api.user.getCurrentUser();

  const request = new Request(url, Method.POST);

  return await sendRequest(request, filter, await defaultRestOptions({ dispatch }));
}

export const fetchCurrentUser = createAsyncThunk<UserAPIResponse, { filter?: Readonly<GetCurrentUserFilter> }>(
  sessionSliceName + "/fetchCurrentUser",
  getCurrentUser,
);

export const addFetchCurrentUserReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchCurrentUser.pending, state => {
    if (!state.currentUser) state.currentUser = {} as CurrentUser;

    state.currentUser.requestState = REQUEST_STATE.PENDING;
    state.currentUser.errorMessages = undefined;
  });
  builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
    const { payload } = action;

    state.currentUser = mapFulfilledResponseObject({ id: payload.user.id });

    if (state.currentGoodsOwnerId == null) state.currentGoodsOwnerId = payload.user.defaultGoodsOwnerId;
  });
  builder.addCase(fetchCurrentUser.rejected, (state, action) => {
    if (!state.currentUser) state.currentUser = {} as CurrentUser;

    state.currentUser.requestState = REQUEST_STATE.REJECTED;
    state.currentUser.errorMessages = action.payload?.errors;
  });
};
