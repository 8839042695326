import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { pages } from "../../../config/settings";
import useLogIn from "../../../features/session/hooks/useLogIn";
import { ACTION_LOG_ACTION, ACTION_LOG_TYPE } from "../../../features/user/dataObjects";
import { createActionLog } from "../../../features/user/reducers/CreateActionLog";
import useTranslate from "../../../language/useTranslate";
import errorCodes from "../../../services/error/errorCodes";
import Button from "../../shared/PostNord/Button/Button";
import { BUTTON_TYPE, BUTTON_VARIANT } from "../../shared/PostNord/Button/dataTypes";
import InputField from "../../shared/PostNord/InputField/InputField";
import PasswordExpiredPage from "../ResetPassword/PasswordExpiredPage";
import RequestResetPassword from "../ResetPassword/RequestResetPassword";
import styles from "./Login.module.scss";

const Login = ({ desiredPage }: { desiredPage?: string }) => {
  const translate = useTranslate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [expiredPassword, setExpiredPassword] = useState("");
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const { logIn, isSuccess, isLoading, isError, errorMessages } = useLogIn();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setUsername(process.env.REACT_APP_DEV_USER ? process.env.REACT_APP_DEV_USER : "");
      setPassword(process.env.REACT_APP_DEV_PWD ? process.env.REACT_APP_DEV_PWD : "");
    }
  }, []);
  useEffect(() => {
    if (isSuccess) {
      if (desiredPage && pages.allowRedirectionToAfterLogin(desiredPage)) {
        //@ts-ignore:next-line
        window.location = desiredPage;
      } else {
        window.location.reload();
      }
    } else if (isError && errorMessages && errorMessages.length > 0) {
      const errorCode = errorMessages[0]?.code ?? "";

      if (errorCode.includes("expired") || errorCode.includes(errorCodes.PASSWORD_EXPIRED.CODE)) {
        setShowUpdatePassword(true);
        setPassword(prev => {
          setExpiredPassword(prev);

          return "";
        });
      }
    }
  }, [desiredPage, isSuccess, isError, errorMessages]);

  async function submitLogin(event: React.SyntheticEvent) {
    event.preventDefault();

    const response = await logIn({ username, password });

    if (response?.meta.requestStatus === "fulfilled") {
      createActionLog(dispatch, {
        action: ACTION_LOG_ACTION.LOGIN,
        actionType: ACTION_LOG_TYPE.MANUAL,
      });
    }
  }

  return resetPasswordVisible ? (
    <RequestResetPassword onClose={() => setResetPasswordVisible(false)} />
  ) : showUpdatePassword ? (
    <PasswordExpiredPage
      onClose={() => {
        setShowUpdatePassword(false);
      }}
      username={username}
      expiredPassword={expiredPassword}
    />
  ) : (
    <>
      <form className={styles.login} onSubmit={submitLogin}>
        <div className={styles.loginContent}>
          <h2 className={styles.title}>{translate("WELCOME_TITLE")}</h2>
          <InputField
            label={translate("USERNAME")}
            value={username}
            onChange={e => setUsername(e.target.value)}
            customCssClass={styles.inputField}
            InputLabelProps={{ shrink: true }}
            required
          />
          <InputField
            label={translate("PASSWORD")}
            type={"password"}
            value={password}
            onChange={e => setPassword(e.target.value)}
            customCssClass={styles.inputField}
            InputLabelProps={{ shrink: true }}
            required
          />

          <div className={styles.passwordButton}>
            <Button
              label={`${translate("FORGOT_PASSWORD")}?`}
              onClick={() => setResetPasswordVisible(true)}
              sx={{ textDecoration: "underline" }}
            />
          </div>
          <div className={styles.loginButton}>
            <Button
              isLoading={isLoading}
              variant={BUTTON_VARIANT.CONTAINED}
              label={`${translate("LOGIN")}`}
              type={BUTTON_TYPE.SUBMIT}
              sx={{ width: "100%" }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
