import { LicenseInfo } from "@mui/x-data-grid-premium";
import { applyPolyfills, defineCustomElements } from "@postnord/web-components/loader";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";

Sentry.init({
  dsn: "https://0648b99c973b44a8a362c22dedaa1fc8@o571717.ingest.sentry.io/5720257",
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,

  tracesSampleRate: 1.0,
});

LicenseInfo.setLicenseKey(
  "6d519efd2292817b676f3e7b0a19c96cTz0xMDAzMDksRT0xNzYzMDU0MjU3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y",
);

ReactDOM.render(<App />, document.getElementById("root"));

applyPolyfills().then(() => {
  defineCustomElements();
});
