import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderPackageAdapter } from "../customerOrderPackageAdapter";
import { CustomerOrderPackage, ResponseCustomerOrderPackage, customerOrderPackageSliceName } from "../dataTypes";

const getCustomerOrderPackage = async (dispatch: AppDispatch, { id }: { id: string }) => {
  const url = api.customerOrderPackage.customerOrderPackage(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  customerOrderPackage: ResponseCustomerOrderPackage;
}

export const fetchCustomerOrderPackage = createAsyncThunk<Response, { id: string }>(
  customerOrderPackageSliceName + "/fetchCustomerOrderPackage",
  getCustomerOrderPackage,
);

export const addFetchCustomerOrderPackageReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderPackage>,
) => {
  builder.addCase(fetchCustomerOrderPackage.pending, (state, action) => {
    const { id } = action.meta.arg;
    const customerOrderPackage = state.entities[id] ?? ({ id } as CustomerOrderPackage);

    customerOrderPackageAdapter.upsertOne(state, {
      ...customerOrderPackage,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchCustomerOrderPackage.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    customerOrderPackageAdapter.updateOne(state, {
      id,
      changes: mapFulfilledResponseObject(payload.customerOrderPackage),
    });
  });
  builder.addCase(fetchCustomerOrderPackage.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      customerOrderPackageAdapter.removeOne(state, id);
    } else {
      customerOrderPackageAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
