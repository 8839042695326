import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { useAppDispatch } from "../../../app/hooks";
import { ACTION_LOG_ACTION, ACTION_LOG_TYPE } from "../../../features/user/dataObjects";
import { createActionLog } from "../../../features/user/reducers/CreateActionLog";

export type LogUserActionProps = { logUserAction: true } & (
  | {
      logAction: typeof ACTION_LOG_ACTION.NAVIGATE;
      logActionType: typeof ACTION_LOG_TYPE.MENU | typeof ACTION_LOG_TYPE.QUICK_ACCESS;
      logPath: string;
    }
  | {
      logAction: typeof ACTION_LOG_ACTION.LOGIN;
      logActionType: typeof ACTION_LOG_TYPE.MANUAL;
    }
  | {
      logAction: typeof ACTION_LOG_ACTION.LOGOUT;
      logActionType: typeof ACTION_LOG_TYPE.MANUAL;
    }
);

export interface DoNotLogUserActionProps {
  logUserAction?: false;
}

type LinkProps = (LogUserActionProps | DoNotLogUserActionProps) & RouterLinkProps;

const Link = (props: LinkProps) => {
  const dispatch = useAppDispatch();

  const handleClick: RouterLinkProps["onClick"] = e => {
    if (!props.logUserAction) return;

    const { logAction, logActionType } = props;
    let logObject;

    if (logAction === ACTION_LOG_ACTION.NAVIGATE) {
      logObject = {
        action: logAction,
        actionType: logActionType,
        path: props.logPath,
      };
    } else if (logAction === ACTION_LOG_ACTION.LOGIN) {
      logObject = {
        action: logAction,
        actionType: logActionType,
      };
    } else {
      logObject = {
        action: logAction,
        actionType: logActionType,
      };
    }

    createActionLog(dispatch, logObject);

    props.onClick?.(e);
  };

  return <RouterLink {...props} onClick={handleClick} />;
};

export default Link;
