import PropTypes from "prop-types";
import { STANDARD_ICON_SIZE } from "./utils";

const Menu = ({
  color = "currentColor",
  size = STANDARD_ICON_SIZE,
  customCssClass,
}: {
  color?: string;
  size?: number | string;
  customCssClass?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

Menu.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customCssClass: PropTypes.string,
};

export default Menu;
