import { HeaderOptions, Method, Request, RestOptions, sendRequest, URL } from "@myloc/myloc-utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setErrors } from "../../../features/dialog/dialogSlice";
import { fetchZetesImageUrl } from "../../../features/zetes/reducers/fetchZetesImageUrl";
import { selectErrorMessages, selectRequestState, selectZetesUrl } from "../../../features/zetes/zetesSelectors";
import { resetZetesImageUrl } from "../../../features/zetes/zetesSlice";
import { useEmbedded } from "../../../hooks/useEmbedded";
import useTranslate from "../../../language/useTranslate";
import pages from "../../../utils/pages";
import { ZetesImageUrlRequest } from "./../../../features/zetes/dataTypes";

const useZetes = () => {
  const [response, setResponse] = useState("");
  const translate = useTranslate();
  const zetesImageUrl = useAppSelector(selectZetesUrl);
  const dispatch = useAppDispatch();
  const refIsFetchingZetesContent = useRef(false);
  const history = useHistory();
  const { hasEmbedded } = useEmbedded();
  const queryParams = useParams<{ customerOrderPackage: string }>();
  const responseReceived = !!response;
  const requestState = useAppSelector(selectRequestState);
  const errorMessages = useAppSelector(selectErrorMessages);

  const showErrorRedirectToHome = useCallback(
    (errorMessage: string) => {
      dispatch(setErrors({ errors: [{ title: errorMessage, code: errorMessage }] }));
      refIsFetchingZetesContent.current = false;
      history.push(pages.HOME);
    },
    [dispatch, history],
  );

  const replaceIncorrectDomain = (html: string) => {
    const INCORRECT_DOMAIN = "https://tplimid01.tpl.postnord.com:7090/";
    const regex = new RegExp(INCORRECT_DOMAIN, "g");

    return html.replace(regex, "/");
  };

  const replaceDynamicDirectoryReferences = (html: string) => {
    const INCORRECT_REFERENCE = "\\.\\./";
    const CORRECT_REFERENCE = "/ZetesImageBank.Web/";
    const regex = new RegExp(INCORRECT_REFERENCE, "g");

    return html.replace(regex, CORRECT_REFERENCE);
  };

  const replaceIncorrectDocType = (html: string) => {
    const INCORRECT_DOCTYPE = "<!DOCTYPE html>";
    const CORRECT_DOCTYPE = "";

    return html.replace(INCORRECT_DOCTYPE, CORRECT_DOCTYPE);
  };

  const fetchZetesContent = useCallback(async () => {
    if (zetesImageUrl === undefined) return;

    const zetesUrl = new URL(replaceIncorrectDomain(zetesImageUrl));
    const request = new Request(zetesUrl, Method.GET);
    const restOptions = new RestOptions();

    restOptions.headerOptions = new HeaderOptions();

    const response = await sendRequest(request, {}, restOptions);

    if (typeof response.data !== "string") return null;

    let html = replaceIncorrectDocType(response.data);

    html = replaceIncorrectDomain(html);

    html = replaceDynamicDirectoryReferences(html);

    if (typeof html !== "string") return null;

    return html;
  }, [zetesImageUrl]);

  const retrieveZetesContent = useCallback(async () => {
    try {
      const html = await fetchZetesContent();

      if (!html) return;

      setResponse(html);
      dispatch(resetZetesImageUrl);
    } catch (e) {
      if (!(e instanceof Error)) return;

      showErrorRedirectToHome(e.message);
    } finally {
      refIsFetchingZetesContent.current = false;
    }
  }, [dispatch, fetchZetesContent, showErrorRedirectToHome]);

  //Handle error
  useEffect(() => {
    if (errorMessages) refIsFetchingZetesContent.current = false;
  }, [errorMessages, requestState]);

  //Request Zetes Image Url
  useEffect(() => {
    if (zetesImageUrl) return;

    const { customerOrderPackage } = queryParams;

    if (!customerOrderPackage || refIsFetchingZetesContent.current) return;

    const zetesImageUrlRequest: ZetesImageUrlRequest = { customerOrderPackage: customerOrderPackage };

    refIsFetchingZetesContent.current = true;

    dispatch(fetchZetesImageUrl(zetesImageUrlRequest));
  }, [dispatch, queryParams, zetesImageUrl]);

  //Initiate load - will fetch when url is received
  useEffect(() => {
    if (!hasEmbedded) {
      showErrorRedirectToHome(translate("EMBEDDED_PATH_INCORRECT"));

      return;
    }

    //All set, initiate Zetes content fetch
    refIsFetchingZetesContent.current = true;

    setResponse("");

    retrieveZetesContent();
  }, [hasEmbedded, retrieveZetesContent, showErrorRedirectToHome, translate]);

  return { response, responseReceived, refIsFetching: refIsFetchingZetesContent, errorMessages };
};

export default useZetes;
