import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderPackageCarrierEventAdapter } from "../customerOrderPackageCarrierEventAdapter";
import {
  CustomerOrderPackageCarrierEvent,
  GetCustomerOrderPackageCarrierEventsFilter,
  ResponseCustomerOrderPackageCarrierEvent,
  customerOrderPackageCarrierEventSliceName,
} from "../dataTypes";

const getCustomerOrderPackageCarrierEvents = async (
  dispatch: AppDispatch,
  id: string,
  filters: Readonly<GetCustomerOrderPackageCarrierEventsFilter>,
) => {
  const url = api.customerOrderPackage.customerOrderPackageCarrierEvents(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  events: PaginationResponse<ResponseCustomerOrderPackageCarrierEvent>;
}

export const fetchCustomerOrderPackageCarrierEvents = createAsyncThunk<
  Response,
  {
    filter: Readonly<GetCustomerOrderPackageCarrierEventsFilter>;
    fulfilledUpdateMethod: FulfilledUpdateMethod;
    id: string;
  }
>(customerOrderPackageCarrierEventSliceName + "/fetchCustomerOrderPackageCarrierEvents", (dispatch, { filter, id }) =>
  getCustomerOrderPackageCarrierEvents(dispatch, id, filter),
);

export const addFetchCustomerOrderPackageCarrierEventsReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderPackageCarrierEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderPackageCarrierEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderPackageCarrierEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderPackageCarrierEvents = payload.events.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderPackageCarrierEventAdapter.setAll(state, customerOrderPackageCarrierEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderPackageCarrierEventAdapter.upsertMany(state, customerOrderPackageCarrierEvents);
        break;
    }

    state.count = payload.events.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderPackageCarrierEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
