import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { PaginationFilterOptions } from "../utils/pagination";

export const purchaseOrderEventSliceName = "purchaseOrderEvent";

export interface ResponsePurchaseOrderEvent extends IdObject {
  event: string;
  date: number;
  time: number;
  place: string;
}

export interface GetPurchaseOrderEventsFilter extends PaginationFilterOptions {}

export interface PurchaseOrderEvent extends ResponsePurchaseOrderEvent, AdditionalRequestState {}

export const PURCHASE_ORDER_EVENT_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponsePurchaseOrderEvent, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  EVENT: "event",
  DATE: "date",
  TIME: "time",
  PLACE: "place",
};
