import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { CUSTOMER_ORDER_FIELDS } from "../../features/customerOrder/dataTypes";
import { GetCustomerOrderLastEventsFilter } from "../../features/customerOrderLastEvent/dataTypes";
import useLazyGetCustomerOrderLastEvents from "../../features/customerOrderLastEvent/hooks/useLazyGetCustomerOrderLastEvents";
import { LAST_EVENT_FIELDS } from "../../features/lastEvent/dataTypes";
import { selectCurrentGoodsOwnerId } from "../../features/session/sessionSelectors";
import { BINDER } from "../../features/utils/SqlBinders";
import { SQL_OPERATOR } from "../../features/utils/SqlOperators";
import useTranslate from "../../language/useTranslate";
import { DATE_FORMATS } from "../../utils/DateUtil";
import { DATE_OPERATORS } from "../DataGrid/Filter/FilterOperators";
import { COLUMN_TYPES } from "../DataGrid/column/columnTypes";
import {
  generateSingleSelectOperators,
  getFormatterForDate,
  getGetterForDateTimestamp,
  getSupportedDateOperators,
} from "../DataGrid/util/DataGridUtil";

const standardColumnDef = {
  editable: false,
  width: 150,
};

const dateColumnDef = {
  editable: false,
  width: 175,
};

function getDateOperators() {
  const dateOperators = getSupportedDateOperators();

  return dateOperators.filter(
    operator => operator.value !== DATE_OPERATORS.IS_EMPTY && operator.value !== DATE_OPERATORS.IS_NOT_EMPTY,
  );
}

export function useCustomerOrderColumns(): GridColDef[] {
  const translate = useTranslate();
  const currentGoodsOwnerId = useAppSelector(selectCurrentGoodsOwnerId);

  const filter = useMemo<GetCustomerOrderLastEventsFilter | undefined>(() => {
    if (currentGoodsOwnerId == null) return undefined;

    const paginatedRequestFilter: GetCustomerOrderLastEventsFilter = {
      paginationOptions: {
        all: true,
        amount: 0,
        from: 0,
      },
      orderByFields: [],
      whereGroups: [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: LAST_EVENT_FIELDS.CLIENT,
              operator: SQL_OPERATOR.EQ,
              value: currentGoodsOwnerId,
            },
          ],
        },
      ],
    };

    return paginatedRequestFilter;
  }, [currentGoodsOwnerId]);

  const { customerOrderLastEvents } = useLazyGetCustomerOrderLastEvents({ filter, loadIfNotInitialized: true });

  const lastEventOperators = useMemo(() => {
    return generateSingleSelectOperators();
  }, []);

  const dateOperators = useMemo(() => getDateOperators(), []);
  const memoizedFormatter = useMemo(() => getFormatterForDate, []);
  const memoizedGetter = useMemo(() => getGetterForDateTimestamp, []);

  const processedOptions = useMemo(() => {
    return customerOrderLastEvents.map(lastEvent => ({
      code: lastEvent.id,
      name: lastEvent.lastEvent,
    }));
  }, [customerOrderLastEvents]);

  return useMemo(
    () => [
      {
        field: CUSTOMER_ORDER_FIELDS.CLIENTS_ORDER_NUMBER,
        headerName: translate("ORDER_NUMBER"),
        type: COLUMN_TYPES.STRING,
        ...standardColumnDef,
      },
      {
        field: CUSTOMER_ORDER_FIELDS.CUSTOMER_ORDER_NUMBER,
        headerName: translate("CUSTOMER_ORDER_NUMBER"),
        type: COLUMN_TYPES.STRING,
        ...standardColumnDef,
      },
      { field: "orderClient", headerName: translate("ORDER_CLIENT"), ...standardColumnDef },
      { field: "orderReference", headerName: translate("ORDER_REFERENCE"), ...standardColumnDef },
      {
        field: CUSTOMER_ORDER_FIELDS.ORDER_DATE,
        headerName: translate("ORDER_DATE"),
        type: COLUMN_TYPES.DATE_TIME,
        filterOperators: dateOperators,
        valueFormatter: ({ value }) => memoizedFormatter(value, DATE_FORMATS.YYYY_MM_DD_HH_mm),
        valueGetter: ({ value }) => memoizedGetter(value),
        ...dateColumnDef,
      },
      {
        field: CUSTOMER_ORDER_FIELDS.LAST_EVENT_ID,
        headerName: translate("LAST_EVENT"),
        type: COLUMN_TYPES.SINGLE_SELECT,
        valueOptions: processedOptions,
        getOptionValue: (value: any) => value?.code,
        getOptionLabel: (value: any) => value?.name,
        filterOperators: lastEventOperators,
        valueGetter: params => params.row.lastEvent || "",
        valueFormatter: ({ value }) => value,
        ...dateColumnDef,
      },
      {
        field: CUSTOMER_ORDER_FIELDS.LAST_EVENT_TIME_STAMP,
        headerName: translate("LAST_EVENT_TIME"),
        type: COLUMN_TYPES.DATE_TIME,
        filterOperators: dateOperators,
        valueFormatter: ({ value }) => memoizedFormatter(value, DATE_FORMATS.YYYY_MM_DD_HH_mm),
        valueGetter: ({ value }) => memoizedGetter(value),
        ...dateColumnDef,
      },
      { field: CUSTOMER_ORDER_FIELDS.CUSTOMER_NUMBER, headerName: translate("CUSTOMER_NUMBER"), ...standardColumnDef },
      { field: CUSTOMER_ORDER_FIELDS.RECIPIENT, headerName: translate("RECEIVER"), ...standardColumnDef },
      { field: CUSTOMER_ORDER_FIELDS.RECIPIENT_CITY, headerName: translate("CITY"), ...standardColumnDef },
      { field: CUSTOMER_ORDER_FIELDS.RECIPIENT_COUNTRY, headerName: translate("COUNTRY"), ...standardColumnDef },
      { field: CUSTOMER_ORDER_FIELDS.CARRIER, headerName: translate("CARRIER"), ...standardColumnDef },
      { field: CUSTOMER_ORDER_FIELDS.CARRIER_PRODUCT, headerName: translate("CARRIER_PRODUCT"), ...standardColumnDef },
      { field: CUSTOMER_ORDER_FIELDS.ORDER_TYPE, headerName: translate("ORDER_TYPE"), ...standardColumnDef },
      {
        field: CUSTOMER_ORDER_FIELDS.DELIVERY_DATE,
        headerName: translate("REQUESTED_DELIVERY_DATE"),
        type: COLUMN_TYPES.DATE,
        filterOperators: dateOperators,
        valueFormatter: ({ value }) => memoizedFormatter(value, DATE_FORMATS.YYYY_MM_DD_HH_mm),
        valueGetter: ({ value }) => memoizedGetter(value),
        ...dateColumnDef,
      },
      {
        field: CUSTOMER_ORDER_FIELDS.SHIPMENT_NUMBER,
        headerName: translate("SHIPMENT_NUMBER"),
        ...dateColumnDef,
      },
    ],
    [translate, dateOperators, processedOptions, lastEventOperators, memoizedFormatter, memoizedGetter],
  );
}
