import { Draft, nanoid } from "@reduxjs/toolkit";
import { produce } from "immer";
import { useCallback } from "react";
import isEqual from "react-fast-compare";
import { useLocation, useHistory as useRouterHistory } from "react-router";
import { Breadcrumb, BreadcrumbLocationState } from "./dataTypes";

export default function useHistory<THistoryLocationState extends BreadcrumbLocationState = BreadcrumbLocationState>() {
  const history = useRouterHistory<THistoryLocationState | undefined>();
  const { pathname, state: locationState } = useLocation<THistoryLocationState | undefined>();

  const push = useCallback(
    ({
      location,
      state,
      breadcrumb,
      options,
    }: {
      location: Parameters<typeof history["push"]>[0];
      state?: Parameters<typeof history["push"]>[1];
      breadcrumb?: NewBreadcrumb;
      options?: { resetBreadcrumbs?: boolean };
    }) => {
      const newStateRecipe = produce(draftState => {
        if (breadcrumb == null) {
          if (draftState == null) return draftState;

          if (options?.resetBreadcrumbs) draftState.breadcrumbs = [];

          return draftState;
        }

        const newDraftState = draftState ?? ({} as Draft<THistoryLocationState>);

        if (newDraftState.breadcrumbs == null)
          newDraftState.breadcrumbs = [...(options?.resetBreadcrumbs ? [] : locationState?.breadcrumbs ?? [])];

        newDraftState.breadcrumbs.push({ ...breadcrumb, id: nanoid() });

        if (draftState == null) return newDraftState;
      }, state);

      const newState = newStateRecipe(state);

      history.push(location, newState);
    },
    [history, locationState],
  );

  const replaceBreadcrumb = useCallback(
    (breadcrumb: NewBreadcrumb) => {
      const newStateRecipe = produce(draft => {
        const newDraft = draft ?? ({} as Draft<THistoryLocationState>);

        if (newDraft.breadcrumbs == null) newDraft.breadcrumbs = [];

        const breadcrumbs = newDraft.breadcrumbs;

        const prevBreadcrumb = breadcrumbs.at(-1);

        if (prevBreadcrumb?.link === pathname) breadcrumbs.pop();

        breadcrumbs.push({ ...prevBreadcrumb, ...breadcrumb, id: prevBreadcrumb?.id ?? nanoid() });

        if (draft == null) return newDraft;
      }, locationState);

      const newState = newStateRecipe(locationState);

      if (isEqual(locationState, newState)) return;

      history.replace(location, newState);
    },
    [history, locationState, pathname],
  );

  return { ...history, push, replaceBreadcrumb };
}

type NewBreadcrumb = Omit<Breadcrumb, "id">;
