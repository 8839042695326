import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { weekLetterSliceName } from "./dataTypes";
import { addFetchWeekLetterReducers } from "./reducers/fetchWeekLetter";
import { addFetchWeekLettersReducers } from "./reducers/fetchWeekLetters";
import { weekLetterAdapter } from "./weekLetterAdapter";

const slice = createSlice({
  name: weekLetterSliceName,
  initialState: weekLetterAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchWeekLettersReducers(builder);
    addFetchWeekLetterReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
