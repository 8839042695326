import generateUseEntityHooks from "../../utils/hooks/generateUseEntityHooks";
import { makeSelectCustomerOrderPackage, selectCustomerOrderPackagesEntities } from "../customerOrderPackageSelectors";
import { fetchCustomerOrderPackage } from "../reducers/fetchCustomerOrderPackage";

const {
  useFetchCustomerOrderPackage,
  useLazyGetCustomerOrderPackage: useLazyGetEntity,
  useGetCustomerOrderPackage: useGetEntity,
} = generateUseEntityHooks({
  selectEntities: selectCustomerOrderPackagesEntities,
  makeSelectEntity: makeSelectCustomerOrderPackage,
  thunk: fetchCustomerOrderPackage,
  entityName: "customerOrderPackage",
});

interface Params {
  id: string | undefined;
}

const useLazyGetCustomerOrderPackage = ({
  id,
  loadIfNotInitialized = false,
}: Params & {
  loadIfNotInitialized?: boolean;
}) => useLazyGetEntity({ id, loadIfNotInitialized });

const useCustomerOrderPackage = ({ id }: Params) => useGetEntity({ id });

export { useCustomerOrderPackage, useFetchCustomerOrderPackage, useLazyGetCustomerOrderPackage };
