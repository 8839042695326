import cloneDeep from "lodash.clonedeep";
import pages from "../pages";
import { MenuItem, SubMenuItem } from "./dataTypes";

const asMenuItem = <T>(menuItems: { [K in keyof T]: { items: MenuItem[] } }) => menuItems;

function mapSubMenuItem(page: {
  NAME: string;
  PATH: string;
  COMFLOW_TASK_ID?: string;
  FUNCTION_ID?: string;
}): SubMenuItem {
  return {
    label: page.NAME,
    link: page.PATH,
    id: page.PATH,
    comflowTaskId: page.COMFLOW_TASK_ID,
    functionId: page.FUNCTION_ID,
  };
}

function mapMenuItem(page: { NAME: string; PATH: string; COMFLOW_TASK_ID?: string; FUNCTION_ID?: string }): MenuItem {
  return {
    label: page.NAME,
    to: page.PATH,
    id: page.PATH,
    comflowTaskId: page.COMFLOW_TASK_ID,
    functionId: page.FUNCTION_ID,
  };
}

function getTaskIds(menu: { items: MenuItem[] }) {
  const menuItems = menu.items;
  const taskIds = menuItems.flatMap(menuItem => menuItem.comflowTaskId);

  taskIds.push(
    ...menuItems.flatMap(menuItem => {
      if ("subMenus" in menuItem)
        return menuItem.subMenus.flatMap(subMenu => subMenu.items).map(item => item.comflowTaskId);
      return [];
    }),
  );

  return taskIds.filter(Boolean);
}

const REPORTS_MENU = {
  items: [
    {
      label: "REPORTS",
      id: "Reports/Reports",
      subMenus: [
        {
          title: "QLIK_SENSE",
          id: "QLIK_SENSE",
          items: [mapSubMenuItem(pages.EMBED_REPORTS_MY_QLIK_SENSE_REPORTS)],
        },
        {
          title: "VOLUME_STATISTICS",
          id: "VOLUME_STATISTICS",
          items: [mapSubMenuItem(pages.EMBED_REPORTS_DELIVERY_ORDER_REPORTS)],
        },
        {
          title: "LEAD_TIME_REPORTS",
          id: "LEAD_TIME_REPORTS",
          items: [mapSubMenuItem(pages.EMBED_REPORTS_TRACK_AND_TRACE_STATISTICS)],
        },
        {
          title: "QUALITY_REPORTS",
          id: "QUALITY_REPORTS",
          items: [
            mapSubMenuItem(pages.EMBED_REPORTS_COMPLAINTS_KPI_REPORT),
            mapSubMenuItem(pages.EMBED_REPORTS_COMPLAINTS_KPI_REPORT_LAST_YEAR),
            mapSubMenuItem(pages.EMBED_REPORTS_COMPLAINTS_UPDATE_KPI_STATISTICS),
          ],
        },
        {
          title: "MY_REPORTS",
          id: "MY_REPORTS",
          items: [
            mapSubMenuItem(pages.EMBED_REPORTS_MY_REPORTS_LINK),
            mapSubMenuItem(pages.EMBED_REPORTS_MY_REPORTS),
            mapSubMenuItem(pages.EMBED_REPORTS_MY_REPORTS_EDIT),
            mapSubMenuItem(pages.EMBED_REPORTS_MY_REPORTS_READ),
            mapSubMenuItem(pages.EMBED_REPORTS_MY_REPORTS_CREATE),
          ],
        },
      ],
    },
  ],
};

const REPORTS_COMFLOW_IDS = getTaskIds(REPORTS_MENU);

const ADMIN_MENU = {
  items: [
    {
      label: "ADMINISTRATION",
      id: "Administration/Administration",
      subMenus: [
        {
          title: "USER",
          id: "USER",
          items: [
            mapSubMenuItem(pages.EMBED_ADMIN_USERS_CONTROL_TOWER),
            mapSubMenuItem(pages.EMBED_ADMIN_CA_USER),
            mapSubMenuItem(pages.EMBED_ADMIN_CA_CHANGE_PASSWORD),
            mapSubMenuItem(pages.EMBED_ADMIN_REDIRECT_USER),
          ],
        },
        {
          title: "INBOUND",
          id: "INBOUND",
          items: [
            mapSubMenuItem(pages.EMBED_ADMIN_INBOUND_BASIC_DATA),
            mapSubMenuItem(pages.EMBED_ADMIN_INBOUND_GENERATE_CALENDAR),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_ACTION_CODES),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_GROUPS),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_REASON_CATEGORIES),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_REASON_CODES),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_STATUS),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_EMAIL_GOODS_OWNER),
            mapSubMenuItem(pages.EMBED_ADMIN_DEVIATION_POST_NORD_EMAIL),
            mapSubMenuItem(pages.EMBED_ADMIN_ARRIVAL_REGISTRATION_MY_PRINTER),
          ],
        },
        {
          title: "OUTBOUND",
          id: "OUTBOUND",
          items: [
            mapSubMenuItem(pages.EMBED_ADMIN_ADJUSTMENT_ORDER_ADJUSTMENT_CODES),
            mapSubMenuItem(pages.EMBED_ADMIN_ADJUSTMENT_ORDER_ADJUSTMENT_ORDER_TYPE),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_MAIN_INFO_GOODS_OWNER),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_TYPES),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_STATUS),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_RECEIVER_ADDRESS),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_PACKAGE_TYPES),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_CARRIER_SERVICES),
            mapSubMenuItem(pages.EMBED_ADMIN_RETURN_PRINTERS),
          ],
        },
        {
          title: "RECLAMATIONS",
          id: "RECLAMATIONS",
          items: [
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_REASON_CATEGORY),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_RECLAIM_STATUS),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_MEASURE_CODE),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_REASON_CODES),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_DAMAGE_CODES),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_KPI_TEXTS),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_ATTACHMENT_TYPES),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_EMAIL_BUYER),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_EMAIL_GREEN_CARGO),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_RECLAIM_GROUPS),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_RECLAIM_STATUS_GOODS_OWNER),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_REASON_CODES_API),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_API),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_RECLAIM_LINE_STATUS),
            mapSubMenuItem(pages.EMBED_ADMIN_COMPLAINTS_END_TEXTS_GOODS_OWNER),
          ],
        },
        {
          title: "GENERAL",
          id: "GENERAL",
          items: [
            mapSubMenuItem(pages.EMBED_ADMIN_CARRIER_STATUS),
            mapSubMenuItem(pages.EMBED_ADMIN_INTERNAL_STATUSES),
            mapSubMenuItem(pages.EMBED_ADMIN_CLIENT_TRANSACTION_CODES),
            mapSubMenuItem(pages.EMBED_ADMIN_STATUS_CODE_COLOR),
            mapSubMenuItem(pages.EMBED_ADMIN_STATUS_TRANSLATION),
            mapSubMenuItem(pages.EMBED_ADMIN_COLUMN_SETTINGS),
            mapSubMenuItem(pages.EMBED_ADMIN_WEEK_LETTER_SUPPORT),
            mapSubMenuItem(pages.EMBED_ADMIN_REGIONS),
          ],
        },
        {
          title: "GOODS_OWNER",
          id: "GOODS_OWNER",
          items: [
            mapSubMenuItem(pages.EMBED_ADMIN_CA_GOODS_OWNER),
            mapSubMenuItem(pages.EMBED_ADMIN_GOODS_OWNER_REGION),
          ],
        },
      ],
    },
  ],
};

const ADMIN_COMFLOW_IDS = getTaskIds(ADMIN_MENU);

const SYSADMIN_MENU = {
  items: [
    {
      label: "SYSTEM_ADMINISTRATION",
      id: "SystemAdministration/SystemAdministration",
      subMenus: [
        {
          title: "OPERATIONS",
          id: "OPERATIONS",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_SYSTEM_CONTROL),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_SESSIONS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_VIRTUAL_SESSIONS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_PATCH_LEVEL),
          ],
        },
        {
          title: "LOGGING",
          id: "LOGGING",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_LOGGER_OTS_LIB),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_LOGIN_HISTORY),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_TASK_LOG),
            mapSubMenuItem(pages.EMBED_SYSADMIN_CUSTOMER_LOG),
            mapSubMenuItem(pages.EMBED_SYSADMIN_APPLICATION_ERROR_LOG),
          ],
        },
        {
          title: "STATISTICS",
          id: "STATISTICS",
          items: [mapSubMenuItem(pages.EMBED_SYSADMIN_SYSTEM_STATISTICS)],
        },
        {
          title: "JOB_SCHEDULER",
          id: "JOB_SCHEDULER",
          items: [mapSubMenuItem(pages.EMBED_SYSADMIN_JOB_SCHEDULER_OTS_LIB)],
        },
        {
          title: "MESSAGES_AND_TEMPLATES",
          id: "MESSAGES_AND_TEMPLATES",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_EMAIL),
            mapSubMenuItem(pages.EMBED_SYSADMIN_EMAIL_TEMPLATES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_SMS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_SMS_TEMPLATES),
          ],
        },
        {
          title: "FILES_AND_ATTACHMENTS",
          id: "FILES_AND_ATTACHMENTS",
          items: [mapSubMenuItem(pages.EMBED_SYSADMIN_FILES_OTS_LIB)],
        },
        {
          title: "USERS_GROUPS_AND_ROLES",
          id: "USERS_GROUPS_AND_ROLES",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_USERS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_GROUPS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_ROLES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_ADVANCED_AUTHENTICATION),
            mapSubMenuItem(pages.EMBED_SYSADMIN_PASSWORD_POLICIES),
          ],
        },
        {
          title: "AUTHORIZATION",
          id: "AUTHORIZATION",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_FUNCTION_AUTHORIZATION),
            mapSubMenuItem(pages.EMBED_SYSADMIN_FUNCTION_GROUPS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_REALM),
            mapSubMenuItem(pages.EMBED_SYSADMIN_KEY_MANAGEMENT),
          ],
        },
        {
          title: "SYSTEM_TOOLS",
          id: "SYSTEM_TOOLS",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_WEB_AND_REST_SERVICES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_EMAIL_TEST),
          ],
        },
        {
          title: "SETTINGS",
          id: "SETTINGS",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_LOCALES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_NUMBER_SERIES_OTS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_SETTINGS_OTS_LIB),
            mapSubMenuItem(pages.EMBED_SYSADMIN_CONSTANT_COLUMNS),
          ],
        },
        {
          title: "LICENSES",
          id: "LICENSES",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSE_FILES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_INSTALLED_LICENSE_OBJECTS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSE_ACCESS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSED_USERS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSED_USER_FUNCTIONS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSED_USER_WORKSTATIONS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSE_WORKSTATIONS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_LICENSE_ALLOCATION_FOR_USER_GROUP),
          ],
        },
        {
          title: "CHANGE_LOG",
          id: "CHANGE_LOG",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_FIELD_AUDIT_TRAIL_INQUIRY),
            mapSubMenuItem(pages.EMBED_SYSADMIN_FIELD_AUDIT_REASON_CODES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_FIELD_AUDIT_TRAIL_CONTROL),
          ],
        },
        {
          title: "USER_MESSAGES",
          id: "USER_MESSAGES",
          items: [
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_ACCESS_MESSAGES),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_ACCESS_MESSAGES_EXCEPTIONS),
            mapSubMenuItem(pages.EMBED_SYSADMIN_USER_ACCESS_MESSAGES_READ_LOG),
          ],
        },
      ],
    },
  ],
};

const SYSADMIN_COMFLOW_IDS = getTaskIds(SYSADMIN_MENU);

const CONFIGS = asMenuItem({
  MENU: {
    items: [
      {
        label: "INBOUND",
        id: "Delivery/Inbound",
        subMenus: [
          {
            id: "INBOUND",
            items: [
              mapSubMenuItem(pages.EMBED_INBOUND_ORDER_PURCHASE_ORDER),
              mapSubMenuItem(pages.EMBED_INBOUND_BOOKING_UPDATE),
              mapSubMenuItem(pages.EMBED_INBOUND_BOOKING_SHOW),
              mapSubMenuItem(pages.EMBED_INBOUND_INBOUND_SHIPMENTS),
              mapSubMenuItem(pages.EMBED_INBOUND_CONSIGNMENT_LIST),
              mapSubMenuItem(pages.EMBED_INBOUND_RESERVATIONS_LIST),
              mapSubMenuItem(pages.EMBED_INBOUND_UNLOADING_REPORTS_SHOW),
              mapSubMenuItem(pages.EMBED_INBOUND_PLANNING_LIST),
              mapSubMenuItem(pages.EMBED_INBOUND_PLANNING_LIST_EXTERNAL),
              mapSubMenuItem(pages.EMBED_INBOUND_DEVIATIONS),
              mapSubMenuItem(pages.EMBED_INBOUND_DEVIATIONS_INTERNAL),
              mapSubMenuItem(pages.EMBED_INBOUND_NEW_ARRIVAL_REGISTRATION),
              mapSubMenuItem(pages.EMBED_ONGOING_ARRIVAL_REGISTRATIONS),
              mapSubMenuItem(pages.EMBED_INBOUND_ORDER_SHORTAGE_ITEMS_IMPORT),
              mapSubMenuItem(pages.EMBED_MONITOR_ORDER_ITEMS),
              mapSubMenuItem(pages.PURCHASE_ORDER_IMPORT),
              mapSubMenuItem(pages.EMBED_PURCHASE_ORDER_REGISTER_NEW),
              mapSubMenuItem(pages.EMBED_PURCHASE_ORDERS_PRELIMINARY),
            ],
          },
        ],
      },
      {
        label: "WAREHOUSE",
        id: "Warehouse/Warehouse",
        subMenus: [
          {
            id: "WAREHOUSE",
            items: [
              mapSubMenuItem(pages.EMBED_WAREHOUSE_ITEMS_REQUEST),
              mapSubMenuItem(pages.EMBED_WAREHOUSE_TRANSACTIONS),
              mapSubMenuItem(pages.EMBED_WAREHOUSE_STADIUM_PRICE_LABELING),
              mapSubMenuItem(pages.EMBED_WAREHOUSE_ITEM_REGISTER_NEW),
              mapSubMenuItem(pages.EMBED_WAREHOUSE_ITEMS_PRELIMINARY),
              mapSubMenuItem(pages.EMBED_WAREHOUSE_RETURN_HANDLING_LOAD_CARRIER),
            ],
          },
        ],
      },
      mapMenuItem(pages.TRACK_AND_TRACE),
      {
        label: "OUTBOUND",
        id: "Delivery/Outbound",
        subMenus: [
          {
            id: "OUTBOUND",
            items: [
              mapSubMenuItem(pages.EMBED_OUTBOUND_DELIVERY_ORDERS),
              mapSubMenuItem(pages.EMBED_OUTBOUND_DELIVERY_ORDERS_ARCHIVE),
              mapSubMenuItem(pages.EMBED_OUTBOUND_ITEM),
              mapSubMenuItem(pages.EMBED_OUTBOUND_DELIVERY_CREATE_RETURNS),
              mapSubMenuItem(pages.EMBED_OUTBOUND_DELIVERY_LIST_RETURNS),
              mapSubMenuItem(pages.EMBED_OUTBOUND_DELIVERY_LIST_INTERNAL),
              mapSubMenuItem(pages.EMBED_OUTBOUND_IMPORT_ORDER_BH),
              mapSubMenuItem(pages.CUSTOMER_ORDER_IMPORT),
              mapSubMenuItem(pages.EMBED_OUTBOUND_CUSTOMER_ORDER_REGISTER_NEW),
              mapSubMenuItem(pages.EMBED_OUTBOUND_CUSTOMER_ORDER_DELETE),
              mapSubMenuItem(pages.EMBED_OUTBOUND_DEVIATIONS_OUTBOUND),
            ],
          },
        ],
      },
      {
        label: "CUSTOMS",
        id: "Delivery/Customs",
        subMenus: [
          {
            id: "CUSTOMS",
            items: [mapSubMenuItem(pages.EMBED_CUSTOMS_LIST), mapSubMenuItem(pages.EMBED_CUSTOMS_FILE_IMPORT)],
          },
        ],
      },
      {
        label: "COMPLAINTS",
        id: "Reports/Complaints",
        subMenus: [
          {
            id: "COMPLAINTS",
            items: [
              mapSubMenuItem(pages.EMBED_COMPLAINTS_CUSTOMER_COMPLAINTS),
              mapSubMenuItem(pages.EMBED_COMPLAINTS_INTERNAL_COMPLAINTS),
            ],
          },
        ],
      },
      { ...mapMenuItem(pages.REPORTS), comflowTaskId: REPORTS_COMFLOW_IDS, disableUserActionLog: true },
      { ...mapMenuItem(pages.ADMINISTRATION), comflowTaskId: ADMIN_COMFLOW_IDS, disableUserActionLog: true },
      { ...mapMenuItem(pages.SYSTEM_ADMINISTRATION), comflowTaskId: SYSADMIN_COMFLOW_IDS, disableUserActionLog: true },
    ],
  },
  MENU_DRAWER: {
    items: [mapMenuItem(pages.ADMINISTRATION), mapMenuItem(pages.SYSTEM_ADMINISTRATION)],
  },

  REPORTS_MENU,
  ADMIN_MENU,
  SYSADMIN_MENU,
});

export const createMenu = (conf: keyof typeof CONFIGS) => {
  const menuConfig = CONFIGS[conf];

  return cloneDeep(menuConfig.items);
};
