import { Method, Request, sendRequest } from "@myloc/myloc-utils";

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";

import { ZetesImageUrlRequest, ZetesImageUrlState, zetesImageUrlSliceName } from "../../../features/zetes/dataTypes";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";

async function getZetesImageUrl(dispatch: AppDispatch, zetesImageUrlRequest: ZetesImageUrlRequest) {
  const url = api.zetes.getUrl(zetesImageUrlRequest.customerOrderPackage);
  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch, includeSessionId: true }));

  return response;
}

export const fetchZetesImageUrl = createAsyncThunk<ZetesImageUrlState, ZetesImageUrlRequest>(
  zetesImageUrlSliceName + "/fetchZetesImageUrl",
  (dispatch, zetesImageUrlRequest) => getZetesImageUrl(dispatch, zetesImageUrlRequest),
);

export const addZetesImageUrlReducers = (builder: ActionReducerMapBuilder<ZetesImageUrlState>) => {
  builder.addCase(fetchZetesImageUrl.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchZetesImageUrl.fulfilled, (state, action) => {
    const { payload } = action;

    if (!state.zetesImageUrl) {
      state.zetesImageUrl = {};
    }

    state.zetesImageUrl.url = payload.zetesImageUrl?.url;
    state.requestState = REQUEST_STATE.FULFILLED;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchZetesImageUrl.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
