import { GridLocaleText, daDK } from "@mui/x-data-grid-premium";

export const DA_TRANSLATION: Partial<GridLocaleText> = {
  ...daDK.components.MuiDataGrid.defaultProps.localeText,

  filterPanelLogicOperator: "Logisk operator",
  filterPanelOperator: "Operator",

  filterOperatorContains: "indeholder",
  filterOperatorEquals: "lig med",
  filterOperatorStartsWith: "begynder med",
  filterOperatorEndsWith: "slutter med",
  filterOperatorIs: "lig med",
  filterOperatorNot: "ikke lig med",
  // Filter operators text
  filterOperatorAfter: "efter",
  filterOperatorOnOrAfter: "lig med eller efter",
  filterOperatorBefore: "før",
  filterOperatorOnOrBefore: "før eller lig med",
  filterOperatorIsEmpty: "er tom",
  filterOperatorIsNotEmpty: "er ikke tom",
  filterOperatorIsAnyOf: "er en af",

  // Header filter operators text
  headerFilterOperatorContains: "Indeholder",
  headerFilterOperatorEquals: "Lig med",
  headerFilterOperatorStartsWith: "Begynder med",
  headerFilterOperatorEndsWith: "Slutter med",
  headerFilterOperatorIs: "Lig med",
  headerFilterOperatorNot: "Ikke lig med",
  headerFilterOperatorAfter: "Efter",
  headerFilterOperatorOnOrAfter: "Lig med eller efter",
  headerFilterOperatorBefore: "Før",
  headerFilterOperatorOnOrBefore: "Før eller lig med",
  headerFilterOperatorIsEmpty: "Er tom",
  headerFilterOperatorIsNotEmpty: "Er ikke tom",
  headerFilterOperatorIsAnyOf: "Er en af",
  "headerFilterOperator=": "Lig med",
  "headerFilterOperator!=": "Ikke lig med",
  "headerFilterOperator>": "Større end",
  "headerFilterOperator>=": "Større end eller lig med",
  "headerFilterOperator<": "Mindre end",
  "headerFilterOperator<=": "Mindre end eller lig med",
};
