import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { GetPasswordRulesFilter } from "../dataTypes";
import { selectPasswordRules } from "../passwordRulesSelectors";
import useFetchPasswordRules from "./useFetchPasswordRules";

const useLazyGetPasswordRules = ({
  filter,
  loadIfNotInitialized = false,
}: {
  filter?: Readonly<GetPasswordRulesFilter>;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchPasswordRules = useFetchPasswordRules();

  const passwordRules = useAppSelector(selectPasswordRules);

  const requestState = passwordRules?.requestState;
  const errorMessages = passwordRules?.errorMessages;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadPasswordRules = useCallback(async () => {
    await fetchPasswordRules({ filter });
  }, [fetchPasswordRules, filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized) loadPasswordRules();
  }, [isUninitialized, loadIfNotInitialized, loadPasswordRules]);

  return {
    passwordRules,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessages,
    fetchPasswordRules: loadPasswordRules,
  };
};

export default useLazyGetPasswordRules;
