import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import detailHeaderImg from "../../../assets/images/detail-header.jpg";

type BannerProps = PropsWithChildren<unknown>;

const DetailBanner = ({ children }: BannerProps) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${detailHeaderImg})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right",
        height: "15rem",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "top",
          maxWidth: "95%",
          width: "160rem",
          margin: "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default DetailBanner;
