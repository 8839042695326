import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  GetPasswordRulesFilter,
  PasswordRulesState,
  ResponsePasswordRules,
  passwordRulesSliceName,
} from "../dataTypes";

export async function getPasswordRules(dispatch: AppDispatch, filter?: Readonly<GetPasswordRulesFilter>) {
  const request = new Request(api.password.passwordRules(), Method.POST);

  return await sendRequest(request, filter ?? {}, await defaultRestOptions({ dispatch }));
}

export const fetchPasswordRules = createAsyncThunk<
  ResponsePasswordRules,
  { filter?: Readonly<GetPasswordRulesFilter> }
>(passwordRulesSliceName + "/fetchPasswordRules", (dispatch, args) => getPasswordRules(dispatch, args.filter));

export const addFetchPasswordRulesReducers = (builder: ActionReducerMapBuilder<PasswordRulesState>) => {
  builder.addCase(fetchPasswordRules.pending, state => {
    if (state.passwordRules == null) {
      state.passwordRules = { regexValidation: "", rules: [] };
    }

    state.passwordRules.requestState = REQUEST_STATE.PENDING;
    state.passwordRules.errorMessages = undefined;
  });
  builder.addCase(fetchPasswordRules.fulfilled, (state, action) => {
    const { payload } = action;

    state.passwordRules = mapFulfilledResponseObject(payload);
  });
  builder.addCase(fetchPasswordRules.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
