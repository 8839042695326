import { configureStore } from "@reduxjs/toolkit";
import { Config, createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import { logIn } from "../features/session/reducers/logIn";
import { signOut } from "./actions";
import rootReducer from "./rootReducer";

const config: Config = {
  whitelist: [logIn.fulfilled.type, signOut.type],
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(createStateSyncMiddleware(config)),
});

initMessageListener(store);

export type AppDispatch = typeof store.dispatch;

export default store;
