import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { ORDER_BY_OPERATOR } from "../../../features/utils/SqlOperators";
import useGetWeekLetters from "../../../features/weekLetter/hooks/useGetWeekLetters";
import useTranslate from "../../../language/useTranslate";
import styles from "./ContentWelcome.module.scss";
import NewsWelcomeRow from "./NewsWelcomeRow/NewsWelcomeRow";
import QuickAccessWelcome from "./QuickAccessWelcome/QuickAccessWelcome";

const ContentWelcome = () => {
  const { weekLetters } = useGetWeekLetters({
    filter: {
      filter: {
        all: true,
        sort: [
          { field: "ValidFrom", order: ORDER_BY_OPERATOR.DESC },
          { field: "NewsWeekId", order: ORDER_BY_OPERATOR.DESC },
        ],
      },
      goodsOwner: { include: true },
    },
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.SET_ALL,
  });

  const translate = useTranslate();

  return (
    <section className={styles.quickAccessContainer}>
      <QuickAccessWelcome />

      <div className={styles.notificationsContainer}>
        <h2>{translate("NOTIFICATIONS")}</h2>
        <div className={styles.allNewsContainer}>
          {weekLetters.map(weekLetter => (
            <NewsWelcomeRow key={weekLetter.id} id={weekLetter.id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ContentWelcome;
