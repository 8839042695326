import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { REQUEST_STATE } from "../../dataTypes";
import { updateUserAccessMessage } from "../../userAccessMessage/reducers/updateUserAccessMessage";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { SessionState } from "../dataTypes";

export const addUpdateUserAccessMessageReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(updateUserAccessMessage.pending, (state, action) => {
    const id = action.meta.arg.id;

    if (id === state.nextUserAccessMessage?.id) {
      state.nextUserAccessMessage.requestState = REQUEST_STATE.PENDING;
      state.nextUserAccessMessage.errorMessages = undefined;
    }
  });
  builder.addCase(updateUserAccessMessage.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    if (id === state.nextUserAccessMessage?.id) {
      state.nextUserAccessMessage = mapFulfilledResponseObject({ id: payload?.id });
    }
  });
  builder.addCase(updateUserAccessMessage.rejected, (state, action) => {
    const id = action.meta.arg.id;

    if (id === state.nextUserAccessMessage?.id) {
      state.nextUserAccessMessage.requestState = REQUEST_STATE.REJECTED;
      state.nextUserAccessMessage.errorMessages = action.payload?.errors;
    }
  });
};
