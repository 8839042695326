import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { regionSliceName } from "./dataTypes";
import { regionAdapter } from "./regionAdapter";

const selectSliceState = (state: RootState) => state[appDataName][regionSliceName];

const adapterSelectors = regionAdapter.getSelectors(selectSliceState);

export const selectErrorMessages = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectRegionsEntities = adapterSelectors.selectEntities;
export const selectRegions = adapterSelectors.selectAll;
export const makeSelectRegion = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
