import { TimelineItem, TimelineOppositeContent } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Paper, PaperProps, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ReactElement } from "react";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import { MutableOnlyArray, ValueOf } from "../../../utils/dataTypes";
import { Event } from "./dataTypes";

export type SXProps = NonNullable<PaperProps["sx"]>;

interface EventListingProps extends PaperProps {
  events: Event[];
  format: ValueOf<typeof DATE_FORMATS>;
}

export default function EventListing({ events, format, sx: sxProp, ...paperProps }: EventListingProps): ReactElement {
  const sx: MutableOnlyArray<SXProps> = [{ backgroundColor: "#EFFBFF", overflow: "auto" }];

  if (Array.isArray(sxProp)) sx.push(...sxProp);
  else if (sxProp) sx.push(sxProp);

  return (
    <Paper elevation={1} {...paperProps} sx={sx}>
      <Timeline sx={{ padding: "0" }}>
        {events.map((event, index) => {
          const formattedDate = dayjs(event.dateTime).format(format);

          return (
            <TimelineItem key={event.id}>
              <TimelineOppositeContent color="text.secondary">{formattedDate}</TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {index < events.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ wordBreak: "break-all" }}>
                <Typography fontWeight={"bold"}> {event.place} </Typography>

                {event.event}
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Paper>
  );
}
