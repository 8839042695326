import { uploadFilesToWMS } from "../../customerOrder/reducers/uploadFilesToWMS";
import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { File } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addUploadCustomerOrderFilesToWMSReducers = (builder: EntityActionReducerBuilderInterface<File>) => {
  builder.addCase(uploadFilesToWMS.fulfilled, (state, action) => {
    const { payload } = action;

    const files = payload.files.map(mapFulfilledResponseObject);

    fileAdapter.upsertMany(state, files);
  });
};
