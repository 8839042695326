import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { GetPurchaseOrdersFilter, PurchaseOrder, purchaseOrderSliceName, ResponsePurchaseOrder } from "../dataTypes";
import { purchaseOrderAdapter } from "../purchaseOrderAdapter";

const getPurchaseOrders = async (dispatch: AppDispatch, { filter }: { filter: Readonly<GetPurchaseOrdersFilter> }) => {
  const url = api.purchaseOrder.getPurchaseOrders();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  purchaseOrders: PaginationResponse<ResponsePurchaseOrder>;
}

export const fetchPurchaseOrders = createAsyncThunk<
  Response,
  { filter: Readonly<GetPurchaseOrdersFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(purchaseOrderSliceName + "/fetchPurchaseOrders", getPurchaseOrders);

export const addFetchPurchaseOrdersReducers = (
  builder: EntityActionReducerBuilderInterface<PurchaseOrder, AdditionalPaginationState>,
) => {
  builder.addCase(fetchPurchaseOrders.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchPurchaseOrders.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const purchaseOrders = payload.purchaseOrders.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        purchaseOrderAdapter.setAll(state, purchaseOrders);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        purchaseOrderAdapter.upsertMany(state, purchaseOrders);
        break;
    }

    state.count = payload.purchaseOrders.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchPurchaseOrders.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
