import classNames from "classnames";
import PropTypes from "prop-types";
import image from "../../../assets/images/TPL_LOGIN_IMAGE.png";
import logo from "../../../assets/logos/PostNord_TPL_blue.png";
import Image from "../../shared/Image/Image";
import Login from "../Login/Login";
import styles from "./LoginWelcome.module.scss";

const LoginWelcome = ({ desiredPage, customCssClass }: { desiredPage?: string; customCssClass?: string }) => {
  return (
    <div className={classNames(styles.container, customCssClass)}>
      <div>
        <Login desiredPage={desiredPage} />
        <Image src={logo} alt="postnord logga" customCssClass={styles.logo} />
      </div>
      <div className={styles.imageContainer}>
        <Image src={image} alt="" customCssClass={styles.controlTowerImageTree} />
      </div>
    </div>
  );
};

LoginWelcome.propTypes = {
  desiredPage: PropTypes.string,
  customCssClass: PropTypes.string,
};

export default LoginWelcome;
