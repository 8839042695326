import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Link, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, useState } from "react";
import { generatePath, useParams } from "react-router";
import { useLazyGetCustomerOrderPackage } from "../../../features/customerOrderPackage/hooks/useCustomerOrderPackage";
import useLazyGetCurrentGoodsOwner from "../../../features/session/hooks/useLazyGetCurrentGoodsOwner";
import { translate } from "../../../preferences/localization";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import pages from "../../../utils/pages";
import DetailBanner from "../../Detail/Banner/DetailBanner";
import Label from "../../shared/Label/Label";
import Page from "../../shared/Page/Page";
import TabPanel from "../../shared/TabPanel/TabPanel";
import Attachments from "./Attachments";
import CarrierEvents from "./CarrierEvents";
import CustomerOrderLinesDataGrid from "./CustomerOrderLinesDataGrid";

const BANNER_CONTENT_FLEX = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  minHeight: "4.5rem",
  marginTop: "4.5rem",
};

const PAGE_CONTENT_FLEX: ComponentPropsWithoutRef<typeof Box>["sx"] = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  justifyContent: "start",
  margin: "0.8rem",
  gap: "1.8rem",
};

const TAB = {
  ITEMS: 0,
  ATTACHMENTS: 1,
} as const;

const TRACKING_LINK: ComponentPropsWithoutRef<typeof Link>["sx"] = {
  color: "$primary",
  textDecoration: "none",
  marginTop: "$spacing-s",
  fontWeight: "500",
  display: "flex",
  alignItems: "center",
  "&:hover": {
    textDecoration: "underline",
  },
};

const ZETES_IMAGE = "ZetesImage";

const CustomerOrderPackageDetailPage = () => {
  const queryParams = useParams<{ customerOrderPackage: string }>();

  const [tabValue, setTabValue] = useState<number>(TAB.ITEMS);

  const { customerOrderPackage } = useLazyGetCustomerOrderPackage({
    loadIfNotInitialized: true,
    id: queryParams.customerOrderPackage,
  });

  const { currentGoodsOwner } = useLazyGetCurrentGoodsOwner({ loadIfNotInitialized: true });

  return (
    <>
      <DetailBanner>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            wordWrap: "nowrap",
            margin: "3.4rem 0.8rem",
            width: "35%",
          }}
        >
          <Typography variant="h1" marginTop={"1.6rem"}>
            {translate("PACKAGE")} #{customerOrderPackage?.sscc}
          </Typography>
          {customerOrderPackage?.linkToPackage && (
            <Link sx={TRACKING_LINK} href={customerOrderPackage.linkToPackage} target="_blank">
              {translate("TRACK_PACKAGE_WITH_CARRIER")}{" "}
              <LaunchIcon sx={{ fontSize: "1.7rem", marginLeft: "0.4rem", marginTop: "0.2rem" }} />
            </Link>
          )}
          {currentGoodsOwner?.externalService === ZETES_IMAGE && (
            <Link
              sx={TRACKING_LINK}
              href={
                generatePath(pages.ZETES_IMAGES.PATH, {
                  customerOrderPackage: customerOrderPackage?.shipmentNumber,
                }) + "?hideMenu=true"
              }
              target="_blank"
            >
              {translate("VIEW_PACKAGE")}
              <LaunchIcon sx={{ fontSize: "1.7rem", marginLeft: "0.4rem", marginTop: "0.2rem" }} />
            </Link>
          )}
        </Box>
        <Box sx={BANNER_CONTENT_FLEX}>
          <Label label={translate("SHIPMENT_NUMBER")} value={customerOrderPackage?.shipmentNumber} />
        </Box>
        <Box sx={BANNER_CONTENT_FLEX}>
          <Label
            label={translate("ETA_FROM_CARRIER")}
            value={
              customerOrderPackage?.eta ? dayjs(customerOrderPackage.eta).format(DATE_FORMATS.YYYY_MM_DD_HH_mm) : ""
            }
          />
        </Box>
        <Box sx={BANNER_CONTENT_FLEX}>
          <Label label={translate("LAST_EVENT")} value={customerOrderPackage?.lastEvent} />
        </Box>
      </DetailBanner>
      <Page>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10%",
            justifyContent: "space-between",
            marginTop: "2.4rem",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", flex: "2" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Box sx={{ ...PAGE_CONTENT_FLEX }}>
                <Typography variant="h4">{translate("RECIPIENT_DETAIL")}</Typography>

                <Label label={translate("RECIPIENT_NAME")} value={customerOrderPackage?.recipientName} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" component="span" color="textSecondary">
                    {translate("RECIPIENT_ADRESS")}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerOrderPackage?.recipientAddress} <br />
                    {customerOrderPackage?.recipientZipCode} - {customerOrderPackage?.recipientCity} <br />
                    {customerOrderPackage?.recipientCountry}
                  </Typography>
                </Box>
                <Label
                  label={translate("RECIPIENT_TELEPHONE_NUMBER")}
                  value={customerOrderPackage?.recipientTelephone}
                />
                <Label label={translate("RECIPIENT_EMAIL")} value={customerOrderPackage?.recipientEmail} />
              </Box>
              <Box sx={{ ...PAGE_CONTENT_FLEX }}>
                <Typography variant="h4">{translate("CARRIER_DETAILS")}</Typography>
                <Label label={translate("CARRIER")} value={customerOrderPackage?.carrier} />
                <Label label={translate("CARRIER_PRODUCT")} value={customerOrderPackage?.carrierProduct} />
              </Box>
            </Box>
            <Box sx={{ flex: "2", marginTop: "2.5rem", width: "96.5rem", height: "50rem" }}>
              <Tabs value={tabValue} onChange={(_, selectedTabValue: number) => setTabValue(selectedTabValue)}>
                <Tab label={translate("ITEMS")} />
                <Tab label={translate("ATTACHMENTS")} />
              </Tabs>

              <TabPanel value={tabValue} index={TAB.ITEMS}>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
                  <CustomerOrderLinesDataGrid packageId={queryParams.customerOrderPackage} />
                </Box>
              </TabPanel>
              <TabPanel value={tabValue} index={TAB.ATTACHMENTS}>
                <Box sx={{ display: "flex", flexDirection: "column", flex: 2 }}>
                  <Attachments packageId={queryParams.customerOrderPackage} />
                </Box>
              </TabPanel>
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} flex={"1"}>
            <Box sx={{ ...PAGE_CONTENT_FLEX }}>
              <Typography variant="h4">{translate("CARRIER_EVENTS")}</Typography>
              <CarrierEvents packageId={queryParams.customerOrderPackage} />
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default CustomerOrderPackageDetailPage;
