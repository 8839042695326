import { Method, Request, sendRequest } from "@myloc/myloc-utils";

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  ComflowSessionState,
  ComflowSessionValuesResponse,
  MylocLoginTokenRequest,
  comflowSessionValuesSliceName,
} from "../../comflowSessionValues/dataTypes";

import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";

async function getComflowSessionValues(dispatch: AppDispatch, token: MylocLoginTokenRequest) {
  const url = api.comflow.getMylocTokenLoginSessionData();
  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, token, await defaultRestOptions({ dispatch, includeSessionId: true }));

  return response;
}

export const fetchComflowSessionValues = createAsyncThunk<ComflowSessionValuesResponse, MylocLoginTokenRequest>(
  comflowSessionValuesSliceName + "/fetchComflowSessionValues",
  (dispatch, token) => getComflowSessionValues(dispatch, token),
);

export const addComflowSessionValuesReducers = (builder: ActionReducerMapBuilder<ComflowSessionState>) => {
  builder.addCase(fetchComflowSessionValues.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchComflowSessionValues.fulfilled, (state, action) => {
    const { payload } = action;

    state.initSessionValues = payload.initSessionValues;
    state.requestState = REQUEST_STATE.FULFILLED;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchComflowSessionValues.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
