import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetFilesFilter } from "../../file/dataTypes";
import useLazyGetCustomerOrderFiles from "./useLazyGetCustomerOrderFiles";

const useGetCustomerOrderFiles = (args: {
  filter: Readonly<GetFilesFilter>;
  customerOrderId: string;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  //lägg till infintescroll
}) => {
  const { isLoading: isLazyLoading, fetchCustomerOrderFiles, ...rest } = useLazyGetCustomerOrderFiles(args);

  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrderFiles();
  }, [fetchCustomerOrderFiles]);

  useEffect(() => {
    const initiateCustomerOrderFiles = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrderFiles();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrderFiles();
  }, [fetchCustomerOrderFiles, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrderFiles: fetchCustomerOrderFiles };
};

export default useGetCustomerOrderFiles;
