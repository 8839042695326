import { useEffect, useState } from "react";
import useRequestResetPassword from "../../../features/password/hooks/useRequestResetPassword";
import useTranslate from "../../../language/useTranslate";
import Button from "../../shared/PostNord/Button/Button";
import { BUTTON_TYPE, BUTTON_VARIANT } from "../../shared/PostNord/Button/dataTypes";
import InputField from "../../shared/PostNord/InputField/InputField";
import styles from "./RequestResetPassword.module.scss";

function RequestResetPassword({ onClose }: { onClose: () => void }) {
  const translate = useTranslate();
  const [email, setEmail] = useState("");
  const { requestResetPassword, isSuccess } = useRequestResetPassword();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const resetPassword = async () => {
    await requestResetPassword({ username: email });
  };

  return (
    <form className={styles.form}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.title}>{translate("FORGOT_PASSWORD")}?</h2>
          <p>{translate("FORGOT_PASSWORD_TEXT")}</p>
          <InputField
            type="text"
            label={translate("USERNAME")}
            value={email}
            onChange={e => setEmail(e.target.value)}
            customCssClass={styles.inputField}
            required
          />
          <div className={styles.buttons}>
            <Button label={translate("CANCEL")} onClick={onClose} />
            <Button
              label={translate("RESET")}
              type={BUTTON_TYPE.BUTTON}
              onClick={resetPassword}
              variant={BUTTON_VARIANT.CONTAINED}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default RequestResetPassword;
