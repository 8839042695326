import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { regionSliceName } from "./dataTypes";
import { addFetchRegionReducers } from "./reducers/fetchRegion";
import { addFetchWeekLetterReducers } from "./reducers/fetchWeekLetter";
import { addFetchWeekLettersReducers } from "./reducers/fetchWeekLetters";
import { regionAdapter } from "./regionAdapter";

const slice = createSlice({
  name: regionSliceName,
  initialState: regionAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchWeekLettersReducers(builder);
    addFetchWeekLetterReducers(builder);
    addFetchRegionReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
