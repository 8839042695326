import { Box, BoxProps } from "@mui/material";
import classNames from "classnames";
import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import styles from "./Page.module.scss";

type SXProps = BoxProps["sx"];

const Page = ({
  children,
  customCssClass,
  spaceBetween,
  isLoading,
  loadingTitle,
  spacingTop,
  sx: sxProp = [],
}: {
  children?: ReactNode;
  customCssClass?: string;
  spaceBetween?: boolean;
  isLoading?: boolean;
  loadingTitle?: string;
  spacingTop?: boolean;
  sx?: SXProps;
}) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sx: SXProps = [spacingTop ? { marginTop: "4.5rem" } : {}, ...(Array.isArray(sxProp) ? sxProp : [sxProp])];
  const isEmbedded = Boolean(location.pathname.match(/^\/embedded\//));

  return (
    <Box sx={sx} minHeight={"100rem"}>
      <section
        className={classNames(
          styles.page,
          spaceBetween ? styles.spaceBetween : undefined,
          isEmbedded ? styles.embedded : undefined,
          customCssClass,
        )}
      >
        {isLoading ? <LoadingSpinner title={loadingTitle} /> : children}
      </section>
    </Box>
  );
};

export default Page;
