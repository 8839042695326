import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { CreateActionLogRequest } from "../dataTypes";

export async function createActionLog(dispatch: AppDispatch, createActionLogRequest: CreateActionLogRequest) {
  const request = new Request(api.user.createActionLog(), Method.POST);

  return await sendRequest(request, createActionLogRequest, await defaultRestOptions({ dispatch }));
}
