import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./Image.module.scss";

const Image = ({
  style,
  src,
  alt,
  customCssClass,
}: {
  style?: React.CSSProperties;
  src: string;
  alt: string;
  customCssClass?: string;
}) => (
  <figure className={classNames(styles.imgWrapper, customCssClass)} style={style}>
    <img src={src} alt={alt} className={styles.image} />
  </figure>
);

Image.propTypes = {
  style: PropTypes.object,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  customCssClass: PropTypes.string,
};

export default Image;
