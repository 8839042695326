import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderLastEventAdapter } from "./customerOrderLastEventAdapter";
import { CustomerOrderLastEvent, customerOrderLastEventSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderLastEventSliceName];

const adapterSelectors = customerOrderLastEventAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderLastEventsEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderLastEvents = adapterSelectors.selectAll;
export const makeSelectCustomerOrderLastEvent = (id = "") => (state: RootState) =>
  adapterSelectors.selectById(state, id);

export const makeSelectCustomerOrderLastEventsById = (ids: string[] = []) => (state: RootState) => {
  if (ids.length === 0) {
    const customerOrderLastEvents = selectCustomerOrderLastEvents(state);

    return customerOrderLastEvents;
  }

  return ids.reduce<CustomerOrderLastEvent[]>((customerOrderLastEvents, id) => {
    const customerOrderLastEvent = makeSelectCustomerOrderLastEvent(id)(state);

    return customerOrderLastEvent ? [...customerOrderLastEvents, customerOrderLastEvent] : customerOrderLastEvents;
  }, []);
};
