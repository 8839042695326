import { Box } from "@mui/material";
import { withPage } from "@myloc/myloc-utils";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { setInfos } from "../../../features/dialog/dialogSlice";
import { UpdatePasswordRequest } from "../../../features/password/dataTypes";
import useUpdatePassword from "../../../features/password/hooks/useUpdatePassword";
import useGetPasswordRules from "../../../features/passwordRules/hooks/useGetPasswordRules";
import useTranslate from "../../../language/useTranslate";
import PasswordRules, { isRulesFulfilled } from "../../shared/Password/PasswordRules";
import Button from "../../shared/PostNord/Button/Button";
import { BUTTON_TYPE, BUTTON_VARIANT } from "../../shared/PostNord/Button/dataTypes";
import InputField from "../../shared/PostNord/InputField/InputField";
import styles from "./ResetPasswordPage.module.scss";

function PasswordExpiredPage({
  onClose,
  username,
  expiredPassword,
}: {
  onClose: () => void;
  username: string;
  expiredPassword: string;
}) {
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const { updatePasswordWithUsername, isSuccess, isError } = useUpdatePassword();
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setInfos([{ code: "password.changed", title: translate("PASSWORD_CHANGED") }]));
      onClose();
    }

    setIsLoading(false);
  }, [isError, isSuccess, onClose, dispatch, translate]);

  const { passwordRules } = useGetPasswordRules({
    filter: {
      username: username,
      expiredPassword: expiredPassword,
    },
  });

  const handleNewPassword = async () => {
    setIsLoading(true);

    const data: UpdatePasswordRequest = {
      username: username,
      previous: currentPassword,
      password: password,
      passwordRepeat: repeatPassword,
    };

    await updatePasswordWithUsername({ ...data, previous: currentPassword, username: username });
  };

  const disabled = !isRulesFulfilled(passwordRules?.rules ?? [], password) || password !== repeatPassword;

  return (
    <>
      <form className={styles.form}>
        <div className={styles.formContent}>
          <div>
            <h5 className={styles.title}>{translate("EXPIRED_PASSWORD_TITLE")}</h5>
          </div>

          <Box display={"flex"}>
            <Box display={"flex"} flexDirection={"column"} flex={2}>
              <InputField
                label={translate("CURRENT_PASSWORD")}
                onChange={e => setCurrentPassword(e.target.value)}
                customCssClass={styles.inputField}
                value={currentPassword}
                required
                InputLabelProps={{ shrink: true }}
                type={"password"}
              />
              <InputField
                label={translate("NEW_PASSWORD")}
                onChange={e => setPassword(e.target.value)}
                value={password}
                required
                InputLabelProps={{ shrink: true }}
                customCssClass={styles.inputField}
                type={"password"}
              />
              <InputField
                label={translate("REPEAT_PASSWORD")}
                onChange={e => setRepeatPassword(e.target.value)}
                value={repeatPassword}
                required
                InputLabelProps={{ shrink: true }}
                customCssClass={styles.inputField}
                type={"password"}
              />

              <div className={styles.buttons}>
                <Button className={styles.passwordButton} label={translate("CANCEL")} onClick={onClose} />

                <Button
                  className={styles.passwordButton}
                  label={translate("SAVE_NEW_PASSWORD")}
                  type={BUTTON_TYPE.BUTTON}
                  variant={BUTTON_VARIANT.CONTAINED}
                  isLoading={isLoading}
                  disabled={disabled}
                  onClick={handleNewPassword}
                />
              </div>
            </Box>
            <Box display={"flex"} flexDirection={"column"}>
              <PasswordRules rules={passwordRules?.rules} password={password} />
            </Box>
          </Box>
        </div>
      </form>
    </>
  );
}

export default withPage(PasswordExpiredPage);
