import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const customerOrderLineSliceName = "customerOrderLine";

export interface ResponseCustomerOrderLine extends IdObject {
  client: string;
  sscc: string;
  clientsOrderNumber: string;
  orderNumber: string;
  lineNbr: string;
  itemID: string;
  itemDescription: string;
  orderQuantity: number;
  reservedQuantity: number;
  deliveredQuantity: number;
  lastEvent: string;
  shipmentNumber: string;
  statusCode: string;
  subStatusCode: string;
  batchId: string;
  expirationDate: number;
  batchQuantity: number;
}

export interface GetCustomerOrderLinesFilter extends ClientPaginatedRequestFilter {}

export interface CustomerOrderLine extends ResponseCustomerOrderLine, AdditionalRequestState {}

export const CUSTOMER_ORDER_LINE_FIELDS = {
  CLIENT: "client",
  SSCC: "sscc",
  CLIENTS_ORDER_NUMBER: "clientsOrderNumber",
  ORDER_NUMBER: "orderNumber",
  LINE_NUMBER: "lineNbr",
  ITEM_ID: "itemID",
  ITEM_DESCRIPTION: "itemDescription",
  ORDER_QUANTITY: "orderQuantity",
  RESERVED_QUANTITY: "reservedQuantity",
  DELIVERED_QUANTITY: "deliveredQuantity",
  LAST_EVENT: "lastEvent",
  SHIPMENT_NUMBER: "shipmentNumber",
  STATUS_CODE: "statusCode",
  SUB_STATUS_CODE: "subStatusCode",
  BATCH_ID: "batchId",
  EXPIRATION_DATE: "expirationDate",
  BATCH_QUANTITY: "batchQuantity",
};
