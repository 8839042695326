import { cloneDeep } from "lodash";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import makeCancellablePromise from "../../../utils/makeCancellablePromise";
import { FulfilledUpdateMethod, REQUEST_STATE, RequestState } from "../../dataTypes";
import { selectRequestState } from "../customerOrderPackageSelectors";
import { GetCustomerOrderPackagesFilter } from "../dataTypes";
import { fetchCustomerOrderPackages } from "../reducers/fetchCustomerOrderPackages";

let filterRef: GetCustomerOrderPackagesFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchCustomerOrderPackages = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) {
    requestStateRef = requestState;
  }

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadCustomerOrderPackages = useCallback(
    ({
      filter,
      fulfilledUpdateMethod,
    }: {
      filter: Readonly<GetCustomerOrderPackagesFilter>;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
    }) => {
      if (isEqual(filter, filterRef) && requestStateRef === REQUEST_STATE.PENDING) return;

      if (!filterContainsClient(filter)) return;

      requestStateRef = REQUEST_STATE.PENDING;
      filterRef = cloneDeep(filter);

      const thunkPromise = dispatch(fetchCustomerOrderPackages({ filter, fulfilledUpdateMethod }));

      return makeCancellablePromise(
        thunkPromise.then(({ payload }) => {
          if (payload && "customerOrderPackages" in payload) {
            return payload.customerOrderPackages.page.map(({ id }) => id);
          }
        }),
        thunkPromise.abort,
      );
    },
    [dispatch],
  );

  function filterContainsClient(filter: GetCustomerOrderPackagesFilter): boolean {
    const containsClient = filter.whereGroups.some(whereGroup => {
      return whereGroup.fields.some(field => field.field === "client" && field.value != null);
    });

    return containsClient;
  }

  return loadCustomerOrderPackages;
};

export default useFetchCustomerOrderPackages;
