import { cloneDeep } from "lodash";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import makeCancellablePromise from "../../../utils/makeCancellablePromise";
import { FulfilledUpdateMethod, REQUEST_STATE, RequestState } from "../../dataTypes";
import { selectRequestState } from "../customerOrderPackageCarrierEventSelectors";
import { GetCustomerOrderPackageCarrierEventsFilter } from "../dataTypes";
import { fetchCustomerOrderPackageCarrierEvents } from "../reducers/fetchCustomerOrderPackageCarrierEvents";

let filterRef: GetCustomerOrderPackageCarrierEventsFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchCustomerOrderPackageCarrierEvents = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) {
    requestStateRef = requestState;
  }

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadCustomerOrderPackageCarrierEvents = useCallback(
    ({
      filter,
      fulfilledUpdateMethod,
      packageId,
    }: {
      filter: Readonly<GetCustomerOrderPackageCarrierEventsFilter>;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
      packageId: string;
    }) => {
      if (isEqual(filter, filterRef) && requestStateRef === REQUEST_STATE.PENDING) return;

      requestStateRef = REQUEST_STATE.PENDING;
      filterRef = cloneDeep(filter);

      const thunkPromise = dispatch(
        fetchCustomerOrderPackageCarrierEvents({ filter, fulfilledUpdateMethod, id: packageId }),
      );

      return makeCancellablePromise(
        thunkPromise.then(({ payload }) => {
          if (payload && "events" in payload) {
            return payload.events.page.map(({ id }) => id);
          }
        }),
        thunkPromise.abort,
      );
    },
    [dispatch],
  );

  return loadCustomerOrderPackageCarrierEvents;
};

export default useFetchCustomerOrderPackageCarrierEvents;
