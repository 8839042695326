export const FILTER_OPERATOR = {
  EQ: "EQ",
  LE: "LE",
  GE: "GE",
  LT: "LT",
  GT: "GT",
  NE: "NE",
  LIKE: "LIKE",
  NOT_LIKE: "NOT LIKE",
  IN: "IN",
  IS_NULL: "IS NULL",
  IS_NOT_NULL: "IS NOT NULL",
} as const;

export const ORDER_BY_OPERATOR = {
  ASC: "ASC",
  DESC: "DESC",
} as const;

export const SQL_OPERATOR = { ...FILTER_OPERATOR, ...ORDER_BY_OPERATOR } as const;
