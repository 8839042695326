import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { SessionState, sessionSliceName } from "../dataTypes";

async function logout(dispatch: AppDispatch) {
  const request = new Request(api.account.authentication(), Method.DELETE);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const logOut = createAsyncThunk(sessionSliceName + "/logOut", dispatch => logout(dispatch));

export const addLogOutReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(logOut.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(logOut.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
