import generateUseEntityHooks from "../../utils/hooks/generateUseEntityHooks";
import { fetchRegion } from "../reducers/fetchRegion";
import { makeSelectRegion, selectRegionsEntities } from "../regionSelectors";

export const { useFetchRegion, useLazyGetRegion, useGetRegion } = generateUseEntityHooks({
  selectEntities: selectRegionsEntities,
  makeSelectEntity: makeSelectRegion,
  thunk: fetchRegion,
  entityName: "Region",
});
