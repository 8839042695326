import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { File, GetFilesFilter, ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderFileAdapter } from "../customerOrderFileAdapter";
import { customerOrderFileSliceName } from "../dataTypes";

const getCustomerOrderFiles = async (
  dispatch: AppDispatch,
  customerOrderId: string,
  filters: Readonly<GetFilesFilter>,
) => {
  const url = api.customerOrder.files(customerOrderId);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  files: PaginationResponse<ResponseFile>;
}

export const fetchCustomerOrderFiles = createAsyncThunk<
  Response,
  { filter: Readonly<GetFilesFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod; customerOrderId: string }
>(customerOrderFileSliceName + "/fetchCustomerOrderFiles", (dispatch, { filter, customerOrderId }) =>
  getCustomerOrderFiles(dispatch, customerOrderId, filter),
);

export const addFetchCustomerOrderFilesReducers = (
  builder: EntityActionReducerBuilderInterface<File, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderFiles.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderFiles.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderFiles = payload.files.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderFileAdapter.setAll(state, customerOrderFiles);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderFileAdapter.upsertMany(state, customerOrderFiles);
        break;
    }

    state.count = payload.files.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderFiles.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
