import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { updateCurrentUserData } from "../session/reducers/updateCurrentUserData";
import { setCurrentGoodsOwnerId } from "../session/sessionSlice";
import { customerOrderLastEventAdapter } from "./customerOrderLastEventAdapter";
import { customerOrderLastEventSliceName } from "./dataTypes";
import { addFetchCustomerOrderLastEventsReducers } from "./reducers/fetchCustomerOrderLastEvents";

const slice = createSlice({
  name: customerOrderLastEventSliceName,
  initialState: customerOrderLastEventAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderLastEventsReducers(builder);
  },
});

const { reducer } = slice;

const customerOrderLastEventReducer: typeof reducer = (state, action) => {
  if (setCurrentGoodsOwnerId.match(action)) {
    return reducer(undefined, action);
  }

  if (updateCurrentUserData.fulfilled.match(action)) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default customerOrderLastEventReducer;
