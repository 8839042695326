import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderLastEventAdapter } from "../customerOrderLastEventAdapter";
import {
  CustomerOrderLastEvent,
  GetCustomerOrderLastEventsFilter,
  ResponseCustomerOrderLastEvent,
  customerOrderLastEventSliceName,
} from "../dataTypes";

const getCustomerOrderLastEvents = async (
  dispatch: AppDispatch,
  filters: Readonly<GetCustomerOrderLastEventsFilter>,
) => {
  const url = api.customerOrder.lastEvents();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  lastEvents: PaginationResponse<ResponseCustomerOrderLastEvent>;
}

export const fetchCustomerOrderLastEvents = createAsyncThunk<
  Response,
  { filter: Readonly<GetCustomerOrderLastEventsFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(customerOrderLastEventSliceName + "/fetchCustomerOrderLastEvents", (dispatch, { filter }) =>
  getCustomerOrderLastEvents(dispatch, filter),
);

export const addFetchCustomerOrderLastEventsReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderLastEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderLastEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderLastEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderLastEvents = payload.lastEvents.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderLastEventAdapter.setAll(state, customerOrderLastEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderLastEventAdapter.upsertMany(state, customerOrderLastEvents);
        break;
    }

    state.count = payload.lastEvents.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderLastEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
