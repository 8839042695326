import { AdditionalRequestState, IdObject } from "../dataTypes";
import { PaginationFilterOptions } from "../utils/pagination";

export const customerOrderEventSliceName = "customerOrderEvent";

export interface ResponseCustomerOrderEvent extends IdObject {
  event: string;
  date: number;
  time: number;
  place: string;
}

export interface GetCustomerOrderEventsFilter extends PaginationFilterOptions {}

export interface CustomerOrderEvent extends ResponseCustomerOrderEvent, AdditionalRequestState {}
