import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { Region, ResponseRegion, regionSliceName } from "../dataTypes";
import { regionAdapter } from "../regionAdapter";

const getRegion = async (dispatch: AppDispatch, { id }: { id: string }) => {
  const url = api.region.getRegion(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  region: ResponseRegion;
}

export const fetchRegion = createAsyncThunk<Response, { id: string }>(regionSliceName + "/fetchRegion", getRegion);

export const addFetchRegionReducers = (builder: EntityActionReducerBuilderInterface<Region>) => {
  builder.addCase(fetchRegion.pending, (state, action) => {
    const { id } = action.meta.arg;
    const region = state.entities[id] ?? ({ id } as Region);

    regionAdapter.upsertOne(state, {
      ...region,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchRegion.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    regionAdapter.updateOne(state, {
      id,
      changes: mapFulfilledResponseObject(payload.region),
    });
  });
  builder.addCase(fetchRegion.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      regionAdapter.removeOne(state, id);
    } else {
      regionAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
