import { createSelector } from "@reduxjs/toolkit";
import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { Shipment, shipmentSliceName } from "./dataTypes";
import { shipmentAdapter } from "./shipmentAdapter";

const selectSliceState = (state: RootState) => state[appDataName][shipmentSliceName];

const adapterSelectors = shipmentAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectShipmentsEntities = adapterSelectors.selectEntities;
export const selectShipments = adapterSelectors.selectAll;
export const makeSelectShipment = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);

export function makeSelectShipmentsById(ids: string[] = []) {
  return createSelector(selectShipmentsEntities, entities => {
    return ids.reduce<Shipment[]>((shipments, id) => {
      const shipment = entities[id];

      return shipment ? [...shipments, shipment] : shipments;
    }, []);
  });
}
