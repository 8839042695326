import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetFilesFilter } from "../../file/dataTypes";
import useLazyGetCustomerOrderPackageFiles from "./useLazyGetCustomerOrderPackageFiles";

const useGetCustomerOrderPackageFiles = (args: {
  filter: Readonly<GetFilesFilter>;
  packageId: string;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  //lägg till infintescroll
}) => {
  const { isLoading: isLazyLoading, fetchCustomerOrderPackageFiles, ...rest } = useLazyGetCustomerOrderPackageFiles(
    args,
  );

  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrderPackageFiles();
  }, [fetchCustomerOrderPackageFiles]);

  useEffect(() => {
    const initiateCustomerOrderPackageFiles = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrderPackageFiles();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrderPackageFiles();
  }, [fetchCustomerOrderPackageFiles, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrderPackageFiles: fetchCustomerOrderPackageFiles };
};

export default useGetCustomerOrderPackageFiles;
