import { Method, Request, sendRequest } from "@myloc/myloc-utils";

import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";

import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { IsakRedirectUriRequest, IsakRedirectUriState, isakRedirectUriSliceName } from "../dataTypes";

async function getIsakRedirectUri(dispatch: AppDispatch, route: IsakRedirectUriRequest) {
  const url = api.isak.getIsakRedirectUri(route.routeId);
  const request = new Request(url, Method.POST);

  const response = await sendRequest(
    request,
    route.routeId,
    await defaultRestOptions({ dispatch, includeSessionId: true }),
  );

  return response;
}

export const fetchIsakRedirectUri = createAsyncThunk<IsakRedirectUriState, IsakRedirectUriRequest>(
  isakRedirectUriSliceName + "/fetchIsakRedirectUri",
  (dispatch, routeId) => getIsakRedirectUri(dispatch, routeId),
);

export const addIsakRedirectUriReducers = (builder: ActionReducerMapBuilder<IsakRedirectUriState>) => {
  builder.addCase(fetchIsakRedirectUri.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchIsakRedirectUri.fulfilled, (state, action) => {
    const { payload } = action;

    if (!state.isakRedirectUri) {
      state.isakRedirectUri = {};
    }

    state.isakRedirectUri.id = payload.isakRedirectUri?.id;
    state.isakRedirectUri.uri = payload.isakRedirectUri?.uri;
    state.requestState = REQUEST_STATE.FULFILLED;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchIsakRedirectUri.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
