import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { purchaseOrderLineSliceName } from "./dataTypes";
import { purchaseOrderLineAdapter } from "./purchaseOrderLineAdapter";
import { addFetchPurchaseOrderLineReducers } from "./reducers/fetchPurchaseOrderLine";
import { addFetchPurchaseOrderLinesReducers } from "./reducers/fetchPurchaseOrderLines";

const slice = createSlice({
  name: purchaseOrderLineSliceName,
  initialState: purchaseOrderLineAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchPurchaseOrderLinesReducers(builder);
    addFetchPurchaseOrderLineReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
