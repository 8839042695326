import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { goodsOwnerSliceName } from "./dataTypes";
import { goodsOwnerAdapter } from "./goodsOwnerAdapter";

const selectSliceState = (state: RootState) => state[appDataName][goodsOwnerSliceName];

const adapterSelectors = goodsOwnerAdapter.getSelectors(selectSliceState);

export const selectErrorMessages = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectGoodsOwnersEntities = adapterSelectors.selectEntities;
export const selectGoodsOwners = adapterSelectors.selectAll;
export const makeSelectGoodsOwner = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
