import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderPackageAdapter } from "./customerOrderPackageAdapter";
import { CustomerOrderPackage, customerOrderPackageSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderPackageSliceName];

const adapterSelectors = customerOrderPackageAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderPackagesEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderPackages = adapterSelectors.selectAll;
export const makeSelectCustomerOrderPackage = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
export const makeSelectCustomerOrderPackagesById = (ids: string[] = []) => (state: RootState) =>
  ids.reduce<CustomerOrderPackage[]>((customerOrderPackages, id) => {
    const customerOrderPackage = makeSelectCustomerOrderPackage(id)(state);

    return customerOrderPackage ? [...customerOrderPackages, customerOrderPackage] : customerOrderPackages;
  }, []);
