import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import pages from "../../../utils/pages";
import { REQUEST_STATE, RequestState } from "../../dataTypes";
import { setErrors } from "../../dialog/dialogSlice";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { logOut as logOutAction } from "../reducers/logOut";
import { selectIsSingleSignOn, selectRequestState } from "../sessionSelectors";
import { setIsSingleSignOut } from "../sessionSlice";
import { handleClientSideSignOut } from "../utils/clientSideSignOut";

let requestStateRef: RequestState | undefined;

const useLogOut = () => {
  const dispatch = useAppDispatch();
  const [isLoggedOut, setIsLoggedOut] = useState<boolean>();
  const isSingleSignOn = useAppSelector(selectIsSingleSignOn);
  const result = useValidateUserIsLoggedIn();
  const history = useHistory();

  const requestState = useAppSelector(selectRequestState);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === isLoggedOut;
  const isError = requestState === REQUEST_STATE.REJECTED;

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    return function cleanup() {
      setIsLoggedOut(false);
    };
  }, []);

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const logOut = useCallback(async () => {
    if (!result.success) {
      dispatch(setErrors(result.messages));
    } else {
      if (requestStateRef !== REQUEST_STATE.PENDING) {
        requestStateRef = REQUEST_STATE.PENDING;

        const payload = (await dispatch(logOutAction())).payload;

        if (!payload) {
          if (isSingleSignOn) {
            dispatch(setIsSingleSignOut(true));
          }

          handleClientSideSignOut(dispatch);
          setIsLoggedOut(true);
          history.push(pages.HOME);
        } else setIsLoggedOut(false);
      }
    }
  }, [dispatch, history, isSingleSignOn, result]);

  return { logOut, isUninitialized, isLoading, isSuccess, isError };
};

export default useLogOut;
