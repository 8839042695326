import { useEffect, useRef } from "react";
import { ReadonlyDeep } from "type-fest";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetCustomerOrderEventsFilter } from "../dataTypes";
import useLazyGetCustomerOrderEvents from "./useLazyGetCustomerOrderEvents";

const useGetCustomerOrderEvents = (args: {
  filter: ReadonlyDeep<GetCustomerOrderEventsFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  customerOrderId: string;
  //lägg till infintescroll
}) => {
  const { isLoading: isLazyLoading, fetchCustomerOrderEvents, ...rest } = useLazyGetCustomerOrderEvents(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrderEvents();
  }, [fetchCustomerOrderEvents]);

  useEffect(() => {
    const initiateCustomerOrderEvents = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrderEvents();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrderEvents();
  }, [fetchCustomerOrderEvents, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrderEvents: fetchCustomerOrderEvents };
};

export default useGetCustomerOrderEvents;
