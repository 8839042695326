import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderLineAdapter } from "../customerOrderLineAdapter";
import {
  CustomerOrderLine,
  GetCustomerOrderLinesFilter,
  ResponseCustomerOrderLine,
  customerOrderLineSliceName,
} from "../dataTypes";

const getCustomerOrderLines = async (dispatch: AppDispatch, filters: Readonly<GetCustomerOrderLinesFilter>) => {
  const url = api.customerOrderLine.customerOrderLines();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  customerOrderLines: PaginationResponse<ResponseCustomerOrderLine>;
}

export const fetchCustomerOrderLines = createAsyncThunk<
  Response,
  {
    filter: Readonly<GetCustomerOrderLinesFilter>;
    fulfilledUpdateMethod: FulfilledUpdateMethod;
  }
>(customerOrderLineSliceName + "/fetchCustomerOrderLines", (dispatch, { filter }) =>
  getCustomerOrderLines(dispatch, filter),
);

export const addFetchCustomerOrderLinesReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderLine, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderLines.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderLines.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderLines = payload.customerOrderLines.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderLineAdapter.setAll(state, customerOrderLines);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderLineAdapter.upsertMany(state, customerOrderLines);
        break;
    }

    state.count = payload.customerOrderLines.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderLines.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
