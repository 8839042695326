import { Alert, AlertTitle } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { IsakRedirectUriRequest } from "../../features/isak/dataTypes";
import { selectIsakRedirectUri, selectIsakRedirectUriErrorMessages } from "../../features/isak/isakSelectors";
import { resetIsakRedirect } from "../../features/isak/isakSlice";
import { fetchIsakRedirectUri } from "../../features/isak/reducers/fetchIsakRedirectUri";
import { selectComflowSessionId } from "../../features/session/sessionSelectors";
import useTranslate from "../../language/useTranslate";
import pages from "../../utils/pages";
import Button from "../shared/PostNord/Button/Button";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import useComflow from "./hooks/useComflow";

const IsakRedirect = ({ redirectTo, mygAction }: { redirectTo: string; mygAction?: string }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const comflowSessionId = useAppSelector(selectComflowSessionId);
  const translate = useTranslate();

  const redirectUri = useAppSelector(selectIsakRedirectUri);
  const redirectUriError = !!useAppSelector(selectIsakRedirectUriErrorMessages);

  const [localRedirectUri, setLocalRedirectUri] = useState("");

  //We need to make a request comflow to be sure we hava a session, then we need to wait for the sessionId
  const { responseReceived } = useComflow({ mygAction });
  const refHasRequested = useRef(false);

  useEffect(() => {
    //Not enough just to have a sessionId, it might be old. We also need response from Comflow
    if (responseReceived && comflowSessionId && !refHasRequested.current) {
      refHasRequested.current = true;

      const uriRequest: IsakRedirectUriRequest = { routeId: redirectTo };

      dispatch(fetchIsakRedirectUri(uriRequest));
    }
  }, [comflowSessionId, dispatch, redirectTo, responseReceived]);

  useEffect(() => {
    if (redirectUri) {
      const decodedUrl = decodeURIComponent(redirectUri);

      window.open(decodedUrl, "_blank", "noreferrer");

      setLocalRedirectUri(decodedUrl);
      dispatch(resetIsakRedirect());
    }
  }, [dispatch, redirectUri]);

  const RedirectMessage = () => {
    return (
      <>
        <Alert severity="warning">
          <AlertTitle>{translate("YOUR_LINK_OPENED_IN_NEW_TAB")}</AlertTitle>
          <p>{translate("IF_PAGE_NOT_OPENED_POP_UP_ERROR_EXPLAINED")}</p>
          <p>{translate("CLICK_LINK_OPEN_AGAIN")}</p>
          <p>
            <strong>
              <a href={localRedirectUri} target="_blank" rel="noreferrer">
                {translate("OPEN_AGAIN")}
              </a>
            </strong>
          </p>
        </Alert>
        <Button label={translate("CLOSE")} onClick={() => history.push(pages.HOME)} />
      </>
    );
  };

  const ShowError = () => {
    return (
      <>
        <Alert severity="error">
          <AlertTitle>{translate("SERVER_ERROR")}</AlertTitle>
          <p>{translate("SERVER_ERROR_UNEXPECTED_DESCRIPTION")}</p>
        </Alert>
        <Button label={translate("CLOSE")} onClick={() => history.push("/")} />
      </>
    );
  };

  const ShowContent = () => {
    return redirectUriError ? <ShowError /> : <RedirectMessage />;
  };

  return localRedirectUri || redirectUriError ? <ShowContent /> : <LoadingSpinner />;
};

export default IsakRedirect;
