import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import useTranslate from "../../../language/useTranslate";
import { Messages, ValidationResult } from "../../dataTypes";
import { setErrors } from "../../dialog/dialogSlice";
import defaultRestOptions from "../../utils/defaultRestOptions";
import getEmptyMessages from "../../utils/getEmptyMessages";
import getValidationResult from "../../utils/getValidationResults";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { UpdatePasswordRequest } from "../dataTypes";

async function sendUpdatePassword(dispatch: AppDispatch, data: UpdatePasswordRequest) {
  const url = api.password.update();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, data, await defaultRestOptions({ dispatch }));
}

const useUpdatePassword = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Messages>();
  const result = useValidateUserIsLoggedIn();

  const _validateUpdatePassword = useCallback(
    (
      additionalValidation: (request: UpdatePasswordRequest) => ValidationResult<UpdatePasswordRequest>,
      request?: Partial<UpdatePasswordRequest>,
    ): ValidationResult<UpdatePasswordRequest> => {
      const messages = getEmptyMessages();

      if (!request) {
        messages.errors.push({ title: translate("DATA_MISSING"), code: "DATA_MISSING" });

        return getValidationResult({ messages });
      }

      if (!request.password) {
        messages.errors.push({
          title: translate("PASSWORD_MISSING"),
          code: "PASSWORD_MISSING",
          field: { field: "password" },
        });
      }

      if (!request.passwordRepeat) {
        messages.errors.push({
          title: translate("PASSWORD_REPEAT_MISSING"),
          code: "PASSWORD_REPEAT_MISSING",
          field: { field: "passwordRepeat" },
        });
      }

      if (messages.errors.length > 0) {
        return getValidationResult({ messages });
      }

      if (request.password !== request.passwordRepeat) {
        messages.errors.push({ title: translate("PASSWORDS_DOESNT_MATCH"), code: "PASSWORDS_DOESNT_MATCH" });

        return getValidationResult({ messages });
      }

      return additionalValidation(request as UpdatePasswordRequest);
    },
    [translate],
  );

  const validateUpdatePasswordWithToken = useCallback(
    (
      request: UpdatePasswordRequest,
    ): ValidationResult<Required<Omit<UpdatePasswordRequest, "previous" | "username">>> => {
      if (!request.token) {
        return getValidationResult({
          messages: {
            errors: [{ title: translate("TOKEN_MISSING"), code: "TOKEN_MISSING", field: { field: "token" } }],
          },
        });
      }

      return getValidationResult({
        data: {
          password: request.password,
          passwordRepeat: request.passwordRepeat,
          token: request.token,
        },
      });
    },
    [translate],
  );

  const validateUpdatePasswordWithUsername = useCallback(
    (request: UpdatePasswordRequest): ValidationResult<Required<Omit<UpdatePasswordRequest, "token">>> => {
      if (!request.username) {
        return getValidationResult({
          messages: {
            errors: [
              {
                title: translate("USERNAME_MISSING"),
                code: "USERNAME_MISSING",
                field: { field: "username" },
              },
            ],
          },
        });
      }

      if (!request.previous) {
        return getValidationResult({
          messages: {
            errors: [
              {
                title: translate("PREVIOUS_PASSWORD_MISSING"),
                code: "PREVIOUS_PASSWORD_MISSING",
                field: { field: "previous" },
              },
            ],
          },
        });
      }

      return getValidationResult({
        data: {
          password: request.password,
          passwordRepeat: request.passwordRepeat,
          username: request.username,
          previous: request.previous,
        },
      });
    },
    [translate],
  );

  const validateUpdatePassword = useCallback(
    (request: UpdatePasswordRequest): ValidationResult<Required<Omit<UpdatePasswordRequest, "token" | "username">>> => {
      if (!result.success) return result;

      if (!request.previous) {
        return getValidationResult({
          messages: {
            errors: [
              {
                title: translate("PREVIOUS_PASSWORD_MISSING"),
                code: "PREVIOUS_PASSWORD_MISSING",
                field: { field: "previous" },
              },
            ],
          },
        });
      }

      return getValidationResult({
        data: {
          password: request.password,
          passwordRepeat: request.passwordRepeat,
          previous: request.previous,
        },
      });
    },
    [translate, result],
  );

  const _updatePassword = useCallback(
    async (
      request: UpdatePasswordRequest,
      additionalValidation: (request: UpdatePasswordRequest) => ValidationResult<UpdatePasswordRequest>,
    ) => {
      setIsLoading(true);
      setIsSuccess(false);
      setIsError(false);
      setErrorMessages(undefined);

      let result = _validateUpdatePassword(additionalValidation, request);

      if (!result.success) {
        dispatch(setErrors(result.messages));
      } else {
        const response = await sendUpdatePassword(dispatch, result.data);

        if (!response.isOk()) {
          result = getValidationResult({ messages: { errors: (response.data as Messages).errors } });
        }
      }

      setIsSuccess(result.success);
      setIsError(!result.success);

      if (!result.success) setErrorMessages(result.messages);

      setIsLoading(false);
    },
    [dispatch, _validateUpdatePassword],
  );

  const updatePassword = useCallback(
    async (request: Required<Omit<UpdatePasswordRequest, "token" | "username">>) =>
      await _updatePassword(request, validateUpdatePassword),
    [_updatePassword, validateUpdatePassword],
  );

  const updatePasswordWithToken = useCallback(
    async (request: Required<Omit<UpdatePasswordRequest, "previous" | "username">>) =>
      await _updatePassword(request, validateUpdatePasswordWithToken),
    [_updatePassword, validateUpdatePasswordWithToken],
  );

  const updatePasswordWithUsername = useCallback(
    async (request: Required<Omit<UpdatePasswordRequest, "token">>) =>
      await _updatePassword(request, validateUpdatePasswordWithUsername),
    [_updatePassword, validateUpdatePasswordWithUsername],
  );

  return {
    updatePassword,
    updatePasswordWithToken,
    updatePasswordWithUsername,
    isLoading,
    isSuccess,
    isError,
    errorMessages,
  };
};

export default useUpdatePassword;
