import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { customerOrderLineAdapter } from "./customerOrderLineAdapter";
import { customerOrderLineSliceName } from "./dataTypes";
import { addFetchCustomerOrderLinesReducers } from "./reducers/fetchCustomerOrderLines";

const slice = createSlice({
  name: customerOrderLineSliceName,
  initialState: customerOrderLineAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderLinesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
