import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { updateCurrentUserData } from "../session/reducers/updateCurrentUserData";
import { setCurrentGoodsOwnerId } from "../session/sessionSlice";
import { customerOrderPackageLastEventAdapter } from "./customerOrderPackageLastEventAdapter";
import { customerOrderPackageLastEventSliceName } from "./dataTypes";
import { addFetchCustomerOrderPackageLastEventsReducers } from "./reducers/fetchCustomerOrderPackageLastEvents";

const slice = createSlice({
  name: customerOrderPackageLastEventSliceName,
  initialState: customerOrderPackageLastEventAdapter.getInitialState<
    AdditionalRequestState & AdditionalPaginationState
  >({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrderPackageLastEventsReducers(builder);
  },
});

const { reducer } = slice;

const customerOrderPackageLastEventReducer: typeof reducer = (state, action) => {
  if (setCurrentGoodsOwnerId.match(action)) {
    return reducer(undefined, action);
  }

  if (updateCurrentUserData.fulfilled.match(action)) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};

export default customerOrderPackageLastEventReducer;
