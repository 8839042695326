import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import classNames from "classnames";
import { generatePath, Link } from "react-router-dom";
import logo from "../../../../assets/logos/PostNord_TPL_blue.png";
import { useLazyGetGoodsOwner } from "../../../../features/goodsOwner/hooks/useGoodsOwner";
import { useLazyGetRegion } from "../../../../features/region/hooks/useRegion";
import { useLazyGetWeekLetter } from "../../../../features/weekLetter/hooks/useWeekLetter";
import useTranslate from "../../../../language/useTranslate";
import pages from "../../../../utils/pages";
import styles from "./NewsWelcomeRow.module.scss";

const CHARACTER_COUNT_LIMIT = 80;

export default function NewsWelcomeRow({ id }: { id: string }) {
  const translate = useTranslate();

  const { weekLetter } = useLazyGetWeekLetter({
    id,
    loadIfNotInitialized: true,
    filter: { goodsOwner: { include: true }, region: { include: true } },
  });

  const characterCount = weekLetter?.body.length ?? 0;

  const { goodsOwner } = useLazyGetGoodsOwner({ id: weekLetter?.goodsOwnerId, loadIfNotInitialized: true });
  const { region } = useLazyGetRegion({ id: weekLetter?.regionId, loadIfNotInitialized: true });

  return (
    <div className={styles.newsContainer}>
      <div className={styles.newsImage}>
        {weekLetter?.attachmentURL ? (
          <img className={styles.uploadedImage} src={weekLetter.attachmentURL} alt="week letter image" />
        ) : (
          <img className={styles.pnLogo} src={logo} alt="standard image if there is no week letter image" />
        )}
      </div>
      <div className={styles.newsContent}>
        <div className={styles.newsTopInfo}>
          {goodsOwner != null ? (
            <span className={styles.infoBubble}>
              {goodsOwner.id} - {goodsOwner.name}
            </span>
          ) : region != null ? (
            <span className={styles.infoBubble}>
              {translate("REGION")} - {region.name}
            </span>
          ) : null}
          <span className={styles.date}>{weekLetter?.validFromDate}</span>
        </div>
        <h3>{weekLetter?.title}</h3>
        <p className={classNames(characterCount > CHARACTER_COUNT_LIMIT && styles.compactBody)}>{weekLetter?.body}</p>

        {characterCount > CHARACTER_COUNT_LIMIT ? (
          <div className={styles.readMore}>
            <Link to={generatePath(pages.NEWS_DETAIL.PATH, { id })}> {translate("READ_MORE")}</Link>
            <KeyboardArrowRightIcon fontSize="large" />
          </div>
        ) : null}
      </div>
    </div>
  );
}
