import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { shipmentFileSliceName } from "./dataTypes";
import { addFetchShipmentFilesReducers } from "./reducers/fetchShipmentFiles";
import { shipmentFileAdapter } from "./shipmentFileAdapter";

const slice = createSlice({
  name: shipmentFileSliceName,
  initialState: shipmentFileAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchShipmentFilesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
