import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { customerOrderAdapter } from "./customerOrderAdapter";
import { customerOrderSliceName } from "./dataTypes";
import { addFetchCustomerOrderReducers } from "./reducers/fetchCustomerOrder";
import { addFetchCustomerOrdersReducers } from "./reducers/fetchCustomerOrders";

const slice = createSlice({
  name: customerOrderSliceName,
  initialState: customerOrderAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchCustomerOrdersReducers(builder);
    addFetchCustomerOrderReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
