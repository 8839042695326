import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { fetchNextUserAccessMessage } from "../../session/reducers/fetchNextUserAccessMessage";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { UserAccessMessage } from "../dataTypes";
import { userAccessMessageAdapter } from "../userAccessMessageAdapter";

export const addFetchNextUserAccessMessageReducers = (
  builder: EntityActionReducerBuilderInterface<UserAccessMessage>,
) => {
  builder.addCase(fetchNextUserAccessMessage.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload) {
      userAccessMessageAdapter.upsertOne(state, mapFulfilledResponseObject(payload));
    }
  });
};
