import { useEffect, useState } from "react";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import styles from "./ComflowContent.module.scss";
import useComflow from "./hooks/useComflow";

export const MYG_ACTIONS = {
  SELECT_GOODS_OWNER: "selectGoodsOwner",
};

const ComflowContent = ({ startTask, mygAction }: { startTask: string; mygAction?: string }) => {
  const { response } = useComflow({ startTask, mygAction });

  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    if (response) setIsFetching(false);
  }, [response]);

  const comflowIframe = (
    <iframe id="ComflowIframe" className={styles.comflowIframe} srcDoc={response} scrolling="yes"></iframe>
  );

  return isFetching ? <LoadingSpinner /> : comflowIframe;
};

export default ComflowContent;
