import { createSlice } from "@reduxjs/toolkit";
import { passwordRulesSliceName, PasswordRulesState } from "./dataTypes";
import { addFetchPasswordRulesReducers } from "./reducers/fetchPasswordRules";

const initialState: PasswordRulesState = {};

const slice = createSlice({
  name: passwordRulesSliceName,
  initialState,
  reducers: {},
  extraReducers: builder => {
    addFetchPasswordRulesReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
