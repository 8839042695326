import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectErrorMessages, selectSessionId } from "../../session/sessionSelectors";
import { selectRequestState, selectSystemSettings } from "../systemSettingsSelectors";
import useFetchSystemSettings from "./useFetchSystemSettings";

const useLazyGetSystemSettings = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const fetchSystemSettings = useFetchSystemSettings();

  const requestState = useAppSelector(selectRequestState);
  const errorMessages = useAppSelector(selectErrorMessages);

  const systemSettings = useAppSelector(selectSystemSettings);

  const userLoggedIn = !!useAppSelector(selectSessionId);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadSystemSettings = useCallback(async () => {
    await fetchSystemSettings();
  }, [fetchSystemSettings]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && userLoggedIn) loadSystemSettings();
  }, [isUninitialized, loadIfNotInitialized, loadSystemSettings, userLoggedIn]);

  return {
    systemSettings,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessages,
    fetchSystemSettings: loadSystemSettings,
  };
};

export default useLazyGetSystemSettings;
