import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { shipmentTrackingEventSliceName } from "./dataTypes";
import { addFetchShipmentTrackingEventsReducers } from "./reducers/fetchShipmentTrackingEvents";
import { shipmentTrackingEventAdapter } from "./shipmentTrackingEventAdapter";

const slice = createSlice({
  name: shipmentTrackingEventSliceName,
  initialState: shipmentTrackingEventAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchShipmentTrackingEventsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
