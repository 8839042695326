import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import useTranslate from "../../language/useTranslate";
import { ValidationResult } from "../dataTypes";
import { selectSessionId } from "../session/sessionSelectors";
import getValidationResult from "./getValidationResults";

const validateUserIsLoggedIn = (
  translate: ReturnType<typeof useTranslate>,
  sessionId?: string,
): ValidationResult<undefined> => {
  if (!sessionId) {
    return getValidationResult({
      messages: { errors: [{ title: translate("USER_IS_NOT_LOGGED_IN"), code: "USER_IS_NOT_LOGGED_IN" }] },
    });
  }

  return getValidationResult({ data: undefined });
};

const useValidateUserIsLoggedIn = () => {
  const translate = useTranslate();
  const sessionId = useAppSelector(selectSessionId);

  return useMemo(() => validateUserIsLoggedIn(translate, sessionId), [sessionId, translate]);
};

export { useValidateUserIsLoggedIn };
