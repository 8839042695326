import { useLocation } from "react-router";
import { useSearchParams } from "./useSearchParams";

/** Use to find out if myg is embedded INTO Comflow */
const useEmbedded = () => {
  const isEmbedded = useSearchParams().get("hideMenu") === "true";
  const hasEmbedded = useLocation().pathname.startsWith("/embed");

  return { isEmbedded, hasEmbedded };
};

export { useEmbedded };
