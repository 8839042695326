import {
  GridColDef,
  GridColType,
  GridFilterInputValueProps,
  GridFilterModel,
  GridFilterOperator,
  GridPaginationModel,
  GridSortItem,
  GridSortModel,
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { debounce } from "lodash";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { ImmerReducer } from "use-immer";
import { BINDER } from "../../../features/utils/SqlBinders";
import { FILTER_OPERATOR, ORDER_BY_OPERATOR } from "../../../features/utils/SqlOperators";
import { ClientPaginatedRequestFilter, FiltrationField, OrderBy, WhereGroup } from "../../../features/utils/pagination";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import { ValueOf } from "../../../utils/dataTypes";
import DefaultSingleSelectInput from "../Filter/DefaultSingleSelectInput";
import EmptyDateValue from "../Filter/EmptyDateValue";
import {
  BOOLEAN_OPERATORS,
  COMMON_OPERATORS,
  DATE_OPERATORS,
  NUMBER_OPERATORS,
  STRING_OPERATORS,
} from "../Filter/FilterOperators";
import { DEFAULT_PAGINATION_MODEL } from "../config/DataGridConfig";

export function getSupportedFilterOperatorsForType(type: GridColType | undefined) {
  switch (type) {
    case "string":
      return getSupportedStringOperators();
    case "number":
      return getSupportedNumericOperators();
    case "date":
      return getSupportedDateOperators();
    case "dateTime":
      return getSupportedDateTimeOperators();
    case "boolean":
    case "singleSelect":
      return getSupportedBooleanOperators();
    default:
      return getSupportedStringOperators();
  }
}

function getSupportedBooleanOperators() {
  return getGridBooleanOperators()
    .filter(operator => Object.values(BOOLEAN_OPERATORS).includes(operator.value))
    .sort((a, b) => {
      const indexA = Object.values(BOOLEAN_OPERATORS).indexOf(a.value);
      const indexB = Object.values(BOOLEAN_OPERATORS).indexOf(b.value);

      return indexA - indexB;
    });
}

export function getSupportedDateOperators() {
  return getSupportedDateOrDateTimeOperators(false);
}

export function getSupportedDateTimeOperators() {
  return getSupportedDateOrDateTimeOperators(true);
}

function getSupportedDateOrDateTimeOperators(isDateTime: boolean) {
  return getGridDateOperators(isDateTime)
    .filter(operator => Object.values(DATE_OPERATORS).includes(operator.value))
    .sort((a, b) => {
      const indexA = Object.values(DATE_OPERATORS).indexOf(a.value);
      const indexB = Object.values(DATE_OPERATORS).indexOf(b.value);

      return indexA - indexB;
    });
}

function getSupportedNumericOperators() {
  return getGridNumericOperators()
    .filter(operator => Object.values(NUMBER_OPERATORS).includes(operator.value))
    .sort((a, b) => {
      const indexA = Object.values(NUMBER_OPERATORS).indexOf(a.value);
      const indexB = Object.values(NUMBER_OPERATORS).indexOf(b.value);

      return indexA - indexB;
    });
}

function getSupportedStringOperators() {
  return getGridStringOperators()
    .filter(operator => Object.values(STRING_OPERATORS).includes(operator.value))
    .sort((a, b) => {
      const indexA = Object.values(STRING_OPERATORS).indexOf(a.value);
      const indexB = Object.values(STRING_OPERATORS).indexOf(b.value);

      return indexA - indexB;
    });
}

export function generateDateTimeFilterOperators(): GridFilterOperator[] {
  return generateDateOrDateTimeFilterOperators(true);
}

export function generateDateFilterOperators(): GridFilterOperator[] {
  return generateDateOrDateTimeFilterOperators(false);
}

function generateDateOrDateTimeFilterOperators(isDateTime: boolean): GridFilterOperator[] {
  return getGridDateOperators(isDateTime).map((operator: GridFilterOperator) => {
    const InputComponent = (props: GridFilterInputValueProps) => {
      const DefaultInputComponent = operator.InputComponent ? operator.InputComponent : () => null;

      return React.createElement(EmptyDateValue, {
        ...props,
        DefaultInputComponent,
      });
    };

    return {
      ...operator,
      InputComponent,
    };
  });
}

export function generateSingleSelectOperators(): GridFilterOperator[] {
  const singleSelectOperators = getGridSingleSelectOperators().filter(operator => operator.value === "is");

  return singleSelectOperators.map((operator: GridFilterOperator) => {
    return {
      ...operator,
      InputComponent: (props: GridFilterInputValueProps) => {
        return DefaultSingleSelectInput(props);
      },
    };
  });
}

export function getFormatterForDate(columnValue: any, format: ValueOf<typeof DATE_FORMATS>) {
  if (columnValue === null || columnValue === "") return "";

  const dayjsDate = dayjs(columnValue);

  return dayjsDate.format(format);
}

export function getGetterForDateTimestamp(value: any) {
  if (value == null) return null;
  return new Date(value);
}

export function getFormattedColumns(columns: GridColDef[]): GridColDef[] {
  return columns.map(column => {
    if (column.filterOperators == null) column.filterOperators = getSupportedFilterOperatorsForType(column.type);

    return column;
  });
}

export function getConvertValueBasedOnOperator(operator: string, value: any): string | number | boolean | null {
  switch (operator) {
    case STRING_OPERATORS.STARTS_WITH:
      return `${value}%`;
    case STRING_OPERATORS.ENDS_WITH:
      return `%${value}`;
    case STRING_OPERATORS.CONTAINS:
      return `%${value}%`;
    case COMMON_OPERATORS.IS_EMPTY:
    case COMMON_OPERATORS.IS_NOT_EMPTY:
      return null;
    default:
      return value;
  }
}

export function getFilterOperator(operator: string): ValueOf<typeof FILTER_OPERATOR> | undefined {
  switch (operator) {
    case STRING_OPERATORS.STARTS_WITH:
    case STRING_OPERATORS.ENDS_WITH:
    case STRING_OPERATORS.CONTAINS:
      return FILTER_OPERATOR.LIKE;
    case STRING_OPERATORS.EQUALS:
    case NUMBER_OPERATORS.EQ:
    case COMMON_OPERATORS.IS_EMPTY:
    case DATE_OPERATORS.IS:
      return FILTER_OPERATOR.EQ;
    case COMMON_OPERATORS.IS_NOT_EMPTY:
    case NUMBER_OPERATORS.NE:
    case DATE_OPERATORS.IS_NOT:
      return FILTER_OPERATOR.NE;
    case NUMBER_OPERATORS.LT:
    case DATE_OPERATORS.BEFORE:
      return FILTER_OPERATOR.LT;
    case NUMBER_OPERATORS.LE:
    case DATE_OPERATORS.ON_OR_BEFORE:
      return FILTER_OPERATOR.LE;
    case NUMBER_OPERATORS.GT:
    case DATE_OPERATORS.AFTER:
      return FILTER_OPERATOR.GT;
    case NUMBER_OPERATORS.GE:
    case DATE_OPERATORS.ON_OR_AFTER:
      return FILTER_OPERATOR.GE;
    default:
      return undefined;
  }
}

export function getPaginatedRequestFilter(
  paginationModel: GridPaginationModel,
  orderByModel: OrderBy[],
  whereFilterModel: WhereGroup[],
): ClientPaginatedRequestFilter {
  return {
    paginationOptions: {
      all: false,
      amount: paginationModel.pageSize,
      from: paginationModel.page * paginationModel.pageSize,
    },
    orderByFields: orderByModel,
    whereGroups: whereFilterModel,
  };
}

export function getOrderByModel(gridSortModel: GridSortModel) {
  const orderBys: OrderBy[] = [];

  gridSortModel.forEach((sortModel: GridSortItem) => {
    if (sortModel.sort == null) return;

    const orderByOperator = sortModel.sort.toUpperCase() as ValueOf<typeof ORDER_BY_OPERATOR>;

    const orderBy: OrderBy = {
      field: sortModel.field,
      operator: orderByOperator,
    };

    orderBys.push(orderBy);
  });

  return orderBys;
}

export function getWhereFilterByModelAndHandleDateEQ(gridFilterModel: GridFilterModel, fields: string[]) {
  const whereFilterModel = getWhereFilterByModel(gridFilterModel);

  whereFilterModel.forEach(whereGroup => {
    whereGroup.fields.forEach((whereField, index) => {
      if (!fields.includes(whereField.field)) return;
      if (whereField.operator !== FILTER_OPERATOR.EQ || whereField.value == null) return;

      whereGroup.fields.splice(index, 1);
      whereGroup.fields.push({ ...whereField, operator: FILTER_OPERATOR.LIKE, value: `${whereField.value}%` });
    });
  });

  return whereFilterModel;
}

export function getWhereFilterByModel(gridFilterModel: GridFilterModel) {
  const filtrationFields: FiltrationField[] = [];

  gridFilterModel.items.forEach(filterItem => {
    const operator = getFilterOperator(filterItem.operator);

    if (operator == null) return;
    if (
      filterItem.value == null &&
      filterItem.operator != COMMON_OPERATORS.IS_EMPTY &&
      filterItem.operator != COMMON_OPERATORS.IS_NOT_EMPTY
    )
      return;

    const filtrationField: FiltrationField = {
      value: getConvertValueBasedOnOperator(filterItem.operator, filterItem.value),
      operator: operator,
      field: filterItem.field,
    };

    filtrationFields.push(filtrationField);
  });

  const whereGroups: WhereGroup = {
    binder: BINDER.AND,
    fields: filtrationFields,
  };

  return [whereGroups];
}

//This is needed because the onchangne of the filter triggers even when the user only changes the operator
//but have not filtered on a real value yet
export function removeEmptyOperatorChange(filterModel: GridFilterModel): GridFilterModel {
  const filterItems = filterModel.items.filter(
    filterItem =>
      filterItem.value != null &&
      filterItem.operator != COMMON_OPERATORS.IS_EMPTY &&
      filterItem.operator != COMMON_OPERATORS.IS_NOT_EMPTY,
  );

  return {
    ...filterModel,
    items: filterItems,
  };
}

//Basically the date and dateTime columns have a bug where they filter on empty value when if the user filter on invalid date
//To avoid a BE call we simple remove them here
//Hopefully this is temporary and can be removed once mui has made their own datepicker the default component in the datagrid
export function handleFilterModelOnChange(filterModel: GridFilterModel, columns: GridColDef[]) {
  const filterItems = filterModel.items.filter(
    filterItem =>
      !(
        columns.find(
          column => column.field === filterItem.field && (column.type === "date" || column.type == "dateTime"),
        ) &&
        filterItem.value === "" &&
        filterItem.operator !== COMMON_OPERATORS.IS_EMPTY &&
        filterItem.operator !== COMMON_OPERATORS.IS_NOT_EMPTY
      ),
  );

  return {
    ...filterModel,
    items: filterItems,
  };
}

export function isInvalidDateChange(filterModel: GridFilterModel, columns: GridColDef[]): boolean {
  const filterItems = filterModel.items.filter(
    filterItem =>
      columns.find(
        column => column.field === filterItem.field && (column.type === "date" || column.type == "dateTime"),
      ) &&
      filterItem.value === "" &&
      filterItem.operator !== COMMON_OPERATORS.IS_EMPTY &&
      filterItem.operator !== COMMON_OPERATORS.IS_NOT_EMPTY,
  );

  return filterItems.length > 0;
}

type FilterModel = {
  whereFilterModel: GridFilterModel;
  visualFilterModel: GridFilterModel;
  orderByModel: GridSortModel;
  paginationModel: GridPaginationModel;
};

const filterModelEmptyState: FilterModel = {
  whereFilterModel: { items: [] },
  visualFilterModel: { items: [] },
  orderByModel: [],
  paginationModel: DEFAULT_PAGINATION_MODEL,
};

export const FILTER_MODEL_ACTION = {
  RESET: "RESET",
  CHANGE_WHERE_FILTER_MODEL: "CHANGE_WHERE_FILTER_MODEL",
  CHANGE_ORDER_BY_MODEL: "CHANGE_ORDER_BY_MODEL",
  CHANGE_PAGINATION_MODEL: "CHANGE_PAGINATION_MODEL",
} as const;

type FilterModelAction =
  | { type: typeof FILTER_MODEL_ACTION.RESET }
  | {
      type: typeof FILTER_MODEL_ACTION.CHANGE_WHERE_FILTER_MODEL;
      whereFilterModel: GridFilterModel;
      columns: GridColDef[];
    }
  | { type: typeof FILTER_MODEL_ACTION.CHANGE_ORDER_BY_MODEL; orderByModel: GridSortModel }
  | { type: typeof FILTER_MODEL_ACTION.CHANGE_PAGINATION_MODEL; paginationModel: GridPaginationModel };

export const filterModelReducer: ImmerReducer<typeof filterModelEmptyState, FilterModelAction> = (draft, action) => {
  switch (action.type) {
    case FILTER_MODEL_ACTION.RESET:
      return filterModelEmptyState;
    case FILTER_MODEL_ACTION.CHANGE_WHERE_FILTER_MODEL:
      if (
        isInvalidDateChange(action.whereFilterModel, action.columns) ||
        JSON.stringify(removeEmptyOperatorChange(draft.whereFilterModel).items) ===
          JSON.stringify(removeEmptyOperatorChange(action.whereFilterModel).items)
      )
        draft.visualFilterModel = action.whereFilterModel;
      else {
        draft.visualFilterModel = action.whereFilterModel;
        draft.whereFilterModel = handleFilterModelOnChange(action.whereFilterModel, action.columns);
        draft.paginationModel = {
          ...draft.paginationModel,
          page: 0,
        };
      }

      return;
    case FILTER_MODEL_ACTION.CHANGE_ORDER_BY_MODEL:
      draft.orderByModel = action.orderByModel;
      draft.paginationModel = {
        ...draft.paginationModel,
        page: 0,
      };

      return;
    case FILTER_MODEL_ACTION.CHANGE_PAGINATION_MODEL:
      draft.paginationModel = action.paginationModel;

      return;
  }
};

const scrollListenerDelay = 10; // time in ms

export const useDebouncedScrollListener = (setState: Dispatch<SetStateAction<any>>): void => {
  useEffect(() => {
    const handleScroll = debounce(() => {
      setState({});
    }, scrollListenerDelay);

    // Add the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      // Cancel any pending execution of handleScroll
      handleScroll.cancel();
    };
  }, [setState]);
};
