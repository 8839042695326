import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { File } from "../file/dataTypes";
import { customerOrderPackageFileAdapter } from "./customerOrderPackageFileAdapter";
import { customerOrderPackageFileSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderPackageFileSliceName];

const adapterSelectors = customerOrderPackageFileAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderPackageFilesEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderPackageFiles = adapterSelectors.selectAll;
export const makeSelectCustomerOrderPackageFile = (id = "") => (state: RootState) =>
  adapterSelectors.selectById(state, id);

export const makeSelectCustomerOrderPackageFilesById = (ids: string[] = []) => (state: RootState) => {
  return ids.reduce<File[]>((customerOrderPackageFiles, id) => {
    const customerOrderPackageFile = makeSelectCustomerOrderPackageFile(id)(state);

    return customerOrderPackageFile
      ? [...customerOrderPackageFiles, customerOrderPackageFile]
      : customerOrderPackageFiles;
  }, []);
};
