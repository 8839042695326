export default function makeCancellablePromise<TPromise extends Promise<unknown>, TError = unknown>(
  promise: TPromise,
  onCancel?: () => void,
): CancellablePromise<TPromise, TError> {
  let isCancelled = false;

  const cancel = () => {
    isCancelled = true;

    onCancel?.();
  };

  const wrappedPromise = new Promise<PromiseReturnType<Awaited<TPromise>, TError>>((resolve, reject) => {
    promise
      .then(value => resolve({ isCancelled, isSuccessful: true, value: value as Awaited<TPromise> }))
      .catch(error => reject({ isCancelled, isSuccessful: false, error }));
  });

  return Object.assign(wrappedPromise, { cancel, originalPromise: promise });
}

type PromiseReturnType<TValue, TError> = { isCancelled: boolean } & (
  | { isSuccessful: true; value: TValue }
  | { isSuccessful: false; error: TError }
);

export type CancellablePromise<TPromise extends Promise<unknown>, TError> = Promise<
  PromiseReturnType<Awaited<TPromise>, TError>
> & { cancel: () => void; originalPromise: TPromise };
