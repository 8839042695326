import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { useAppSelector } from "../../app/hooks";
import { GetCustomerOrderPackageLastEventsFilter } from "../../features/customerOrderPackageLastEvent/dataTypes";
import useLazyGetCustomerOrderPackageLastEvents from "../../features/customerOrderPackageLastEvent/hooks/useLazyGetCustomerOrderPackageLastEvents";
import { LAST_EVENT_FIELDS } from "../../features/lastEvent/dataTypes";
import { selectCurrentGoodsOwnerId } from "../../features/session/sessionSelectors";
import { SHIPMENT_FIELDS } from "../../features/shipment/dataTypes";
import { BINDER } from "../../features/utils/SqlBinders";
import { SQL_OPERATOR } from "../../features/utils/SqlOperators";
import useTranslate from "../../language/useTranslate";
import { DATE_FORMATS } from "../../utils/DateUtil";
import { DATE_OPERATORS } from "../DataGrid/Filter/FilterOperators";
import { COLUMN_TYPES } from "../DataGrid/column/columnTypes";
import {
  generateSingleSelectOperators,
  getFormatterForDate,
  getGetterForDateTimestamp,
  getSupportedDateOperators,
} from "../DataGrid/util/DataGridUtil";

const standardColumnDef = {
  editable: false,
  width: 150,
};

const dateColumnDef = {
  editable: false,
  width: 175,
};

const lastEventOperators = generateSingleSelectOperators();

function getDateOperators() {
  const dateOperators = getSupportedDateOperators();

  return dateOperators.filter(
    operator => operator.value !== DATE_OPERATORS.IS_EMPTY && operator.value !== DATE_OPERATORS.IS_NOT_EMPTY,
  );
}

const dateOperators = getDateOperators();

export function useShipmentColumns(): GridColDef[] {
  const translate = useTranslate();
  const currentGoodsOwnerId = useAppSelector(selectCurrentGoodsOwnerId);

  const filter = useMemo<GetCustomerOrderPackageLastEventsFilter | undefined>(() => {
    if (currentGoodsOwnerId == null) return undefined;

    const paginatedRequestFilter: GetCustomerOrderPackageLastEventsFilter = {
      paginationOptions: {
        all: true,
        amount: 0,
        from: 0,
      },
      orderByFields: [],
      whereGroups: [
        {
          binder: BINDER.AND,
          fields: [
            {
              field: LAST_EVENT_FIELDS.CLIENT,
              operator: SQL_OPERATOR.EQ,
              value: currentGoodsOwnerId,
            },
          ],
        },
      ],
    };

    return paginatedRequestFilter;
  }, [currentGoodsOwnerId]);

  const { customerOrderPackageLastEvents } = useLazyGetCustomerOrderPackageLastEvents({
    filter,
    loadIfNotInitialized: true,
  });

  const processedOptions = useMemo(() => {
    return customerOrderPackageLastEvents.map(lastEvent => ({
      code: lastEvent.id,
      name: lastEvent.lastEvent,
    }));
  }, [customerOrderPackageLastEvents]);

  return useMemo(
    () => [
      {
        field: SHIPMENT_FIELDS.SHIPMENT_NUMBER,
        headerName: translate("SHIPMENT_NUMBER"),
        type: COLUMN_TYPES.STRING,
        ...standardColumnDef,
      },
      {
        field: SHIPMENT_FIELDS.CARRIER,
        headerName: translate("CARRIER"),
        type: COLUMN_TYPES.STRING,
        ...standardColumnDef,
      },
      {
        field: SHIPMENT_FIELDS.CARRIER_PRODUCT,
        headerName: translate("CARRIER_PRODUCT"),
        type: COLUMN_TYPES.STRING,
        ...standardColumnDef,
      },
      {
        field: SHIPMENT_FIELDS.LAST_EVENT_ID,
        headerName: translate("LAST_EVENT"),
        type: COLUMN_TYPES.SINGLE_SELECT,
        valueOptions: processedOptions,
        getOptionValue: (value: any) => value?.code,
        getOptionLabel: (value: any) => value?.name,
        filterOperators: lastEventOperators,
        valueGetter: params => params.row.lastEvent || "",
        valueFormatter: ({ value }) => value,
        width: 175,
      },
      {
        field: SHIPMENT_FIELDS.LAST_EVENT_TIME_STAMP,
        headerName: translate("LAST_EVENT_TIME"),
        type: COLUMN_TYPES.DATE_TIME,
        filterOperators: dateOperators,
        valueFormatter: ({ value }) => getFormatterForDate(value, DATE_FORMATS.YYYY_MM_DD_HH_mm),
        valueGetter: ({ value }) => getGetterForDateTimestamp(value),
        ...dateColumnDef,
      },
      {
        field: SHIPMENT_FIELDS.LOADING_DATE,
        headerName: translate("LOADING_DATE"),
        type: COLUMN_TYPES.DATE_TIME,
        filterOperators: dateOperators,
        valueFormatter: ({ value }) => getFormatterForDate(value, DATE_FORMATS.YYYY_MM_DD),
        valueGetter: ({ value }) => getGetterForDateTimestamp(value),
        ...dateColumnDef,
      },
      { field: SHIPMENT_FIELDS.RECIPIENT, headerName: translate("RECIPIENT"), ...standardColumnDef },
      {
        field: SHIPMENT_FIELDS.RECIPIENT_ZIP_CODE,
        headerName: translate("ZIP_CODE"),
        type: COLUMN_TYPES.STRING,
        ...standardColumnDef,
      },
      { field: SHIPMENT_FIELDS.RECIPIENT_CITY, headerName: translate("CITY"), ...standardColumnDef },
      {
        field: SHIPMENT_FIELDS.WEIGHT,
        type: COLUMN_TYPES.NUMBER,
        headerName: translate("WEIGHT"),
        ...standardColumnDef,
      },
      {
        field: SHIPMENT_FIELDS.VOLUME,
        type: COLUMN_TYPES.NUMBER,
        headerName: translate("VOLUME"),
        ...standardColumnDef,
      },
      {
        field: SHIPMENT_FIELDS.NO_OF_PACKAGES,
        type: COLUMN_TYPES.NUMBER,
        headerName: translate("AMOUNT_OF_PACKAGES"),
        ...standardColumnDef,
      },

      { field: SHIPMENT_FIELDS.ORDER_REFERENCE, headerName: translate("ORDER_REFERENCE"), ...standardColumnDef },
    ],
    [translate, processedOptions],
  );
}
