import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { Button } from "@mui/material";
import { GridApiPremium } from "@mui/x-data-grid-premium/models/gridApiPremium";
import { useCallback } from "react";
import useTranslate from "../../../../language/useTranslate";
import storage from "../../../../utils/store/storage";

const GridToolbarSaveView = ({
  gridOnPage,
  apiRef,
}: {
  gridOnPage: string;
  apiRef: React.MutableRefObject<GridApiPremium>;
}) => {
  const translate = useTranslate();

  const saveInLocalStorage = useCallback(() => {
    const exportState = apiRef.current.exportState();

    if (exportState.preferencePanel) {
      exportState.preferencePanel = { ...exportState.preferencePanel, open: false };
    }

    storage.saveItem(gridOnPage, true, exportState);
  }, [apiRef, gridOnPage]);

  return (
    <Button variant="text" color="primary" size="small" startIcon={<SaveOutlinedIcon />} onClick={saveInLocalStorage}>
      {translate("SAVE_VIEW")}
    </Button>
  );
};

export default GridToolbarSaveView;
