import { AdditionalRequestState, IdObject } from "../dataTypes";
import { PaginationFilterOptions } from "../utils/pagination";

export const fileSliceName = "file";

export interface ResponseFile extends IdObject {
  name: string;
  url: string;
  sequence: number;
  createdTimestamp: number;
  createdBy: string;
}

export interface GetFilesFilter extends PaginationFilterOptions {}

export interface File extends ResponseFile, AdditionalRequestState {}

export const FILE_FIELDS = {
  NAME: "name",
  URL: "url",
  SEQUENCE: "sequence",
  CREATED_TIMESTAMP: "createdTimestamp",
  CREATED_BY: "createdBy",
};
