import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const warehouseTransactionSliceName = "warehouseTransaction";

export interface ResponseWarehouseTransaction extends IdObject {
  client: string;
  itemNumber: string;
  transaction: string;
  timestamp: number;
  clientItemNumber: string;
  itemDescription: string;
  quantity: number;
  transactionCode: string;
  showAdjustmentTransactions: boolean;
  referenceOrder: string;
  batch: string;
  expirationDate: number;
}

export interface GetWarehouseTransactionsFilter extends ClientPaginatedRequestFilter {}

export interface WarehouseTransaction extends ResponseWarehouseTransaction, AdditionalRequestState {}

export const WAREHOUSE_TRANSACTION_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponseWarehouseTransaction, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  CLIENT: "client",
  ITEM_NUMBER: "itemNumber",
  TRANSACTION: "transaction",
  TIMESTAMP: "timestamp",
  CLIENT_ITEM_NUMBER: "clientItemNumber",
  ITEM_DESCRIPTION: "itemDescription",
  QUANTITY: "quantity",
  TRANSACTION_CODE: "transactionCode",
  SHOW_ADJUSTMENT_TRANSACTIONS: "showAdjustmentTransactions",
  REFERENCE_ORDER: "referenceOrder",
  BATCH: "batch",
  EXPIRATION_DATE: "expirationDate",
};
