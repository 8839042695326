import { CamelCase, SnakeCase } from "type-fest";
import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const shipmentSliceName = "shipment";

export interface ResponseShipment extends IdObject {
  client: string;
  shipmentNumber: string;
  loadingDate: number;
  orderReference: string;
  recipient: string;
  recipientAddress: string;
  recipientZipCode: string;
  recipientCity: string;
  recipientCountryCode: string;
  recipientCountry: string;
  recipientName: string;
  recipientEmail: string;
  recipientTelephone: string;
  recipientMobile: string;
  carrier: string;
  carrierProduct: string;
  lastEvent: string;
  lastEventId: string;
  lastEventTimeStamp: number;
  weight?: number;
  volume?: number;
  noOfPackages: number;
}

export interface GetShipmentsFilter extends ClientPaginatedRequestFilter {}

export interface Shipment extends ResponseShipment, AdditionalRequestState {}

export const SHIPMENT_FIELDS: {
  [key in Uppercase<SnakeCase<Exclude<keyof ResponseShipment, "id">>>]: CamelCase<Lowercase<key>>;
} = {
  CLIENT: "client",
  SHIPMENT_NUMBER: "shipmentNumber",
  LOADING_DATE: "loadingDate",
  ORDER_REFERENCE: "orderReference",
  RECIPIENT: "recipient",
  RECIPIENT_ADDRESS: "recipientAddress",
  RECIPIENT_ZIP_CODE: "recipientZipCode",
  RECIPIENT_CITY: "recipientCity",
  RECIPIENT_COUNTRY_CODE: "recipientCountryCode",
  RECIPIENT_COUNTRY: "recipientCountry",
  RECIPIENT_NAME: "recipientName",
  RECIPIENT_EMAIL: "recipientEmail",
  RECIPIENT_TELEPHONE: "recipientTelephone",
  RECIPIENT_MOBILE: "recipientMobile",
  CARRIER: "carrier",
  CARRIER_PRODUCT: "carrierProduct",
  LAST_EVENT: "lastEvent",
  LAST_EVENT_ID: "lastEventId",
  LAST_EVENT_TIME_STAMP: "lastEventTimeStamp",
  WEIGHT: "weight",
  VOLUME: "volume",
  NO_OF_PACKAGES: "noOfPackages",
};

export const SHIPMENT_FILTRATION_FIELDS = {
  ...SHIPMENT_FIELDS,
  SEARCH: "search",
  ITEM_SEARCH: "itemID",
  ORDER_DATE: "orderDate",
};
