import { RootState } from "../../app/rootReducer";
import { sessionSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[sessionSliceName];

export const selectErrorMessages = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectSessionData = (state: RootState) => selectSliceState(state).sessionData;

export const selectSessionId = (state: RootState) => selectSliceState(state).sessionData?.id;
export const selectAuthorizations = (state: RootState) => selectSliceState(state).sessionData?.authorizations;

export const selectIsSingleSignOn = (state: RootState) => selectSliceState(state).isSingleSignOn;

export const selectSingleSignOut = (state: RootState) => selectSliceState(state).singleSignOut;

export const selectIsInitialized = (state: RootState) => selectSliceState(state).isInitialized;

export const selectRememberMe = (state: RootState) => selectSliceState(state).rememberMe;

export const selectCurrentUser = (state: RootState) => selectSliceState(state).currentUser;
export const selectCurrentUserRequestState = (state: RootState) => selectCurrentUser(state)?.requestState;
export const selectCurrentUserErrorMessages = (state: RootState) => selectCurrentUser(state)?.errorMessages;
export const selectCurrentUserId = (state: RootState) => selectCurrentUser(state)?.id;

export const selectNextUserAccessMessage = (state: RootState) => selectSliceState(state).nextUserAccessMessage;
export const selectNextUserAccessMessageRequestState = (state: RootState) =>
  selectNextUserAccessMessage(state)?.requestState;
export const selectNextUserAccessMessageErrorMessages = (state: RootState) =>
  selectNextUserAccessMessage(state)?.errorMessages;
export const selectNextUserAccessMessageId = (state: RootState) => selectNextUserAccessMessage(state)?.id;
export const selectCurrentGoodsOwnerId = (state: RootState) => selectSliceState(state).currentGoodsOwnerId;
export const selectComflowSessionId = (state: RootState) => selectSliceState(state).comflowSessionId;
