import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  GetWeekLettersFilter,
  ResponseWeekLetter,
  WeekLetter,
  WeekLettersImportedDataResponse,
  weekLetterSliceName,
} from "../dataTypes";
import { weekLetterAdapter } from "../weekLetterAdapter";

const getWeekLetters = async (dispatch: AppDispatch, filters: Readonly<GetWeekLettersFilter> = {}) => {
  const url = api.weekLetter.weekLetters();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response extends WeekLettersImportedDataResponse {
  weekLetters: PaginationResponse<ResponseWeekLetter>;
}

export const fetchWeekLetters = createAsyncThunk<
  Response,
  { filter?: Readonly<GetWeekLettersFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(weekLetterSliceName + "/fetchWeekLetters", (dispatch, { filter }) => getWeekLetters(dispatch, filter));

export const addFetchWeekLettersReducers = (
  builder: EntityActionReducerBuilderInterface<WeekLetter, AdditionalPaginationState>,
) => {
  builder.addCase(fetchWeekLetters.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchWeekLetters.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const weekLetters = payload.weekLetters.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        weekLetterAdapter.setAll(state, weekLetters);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        weekLetterAdapter.upsertMany(state, weekLetters);
        break;
    }

    state.count = payload.weekLetters.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchWeekLetters.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
