import { useMemo } from "react";
import useGetCustomerOrderFiles from "../../../features/customerOrderFile/hooks/useGetCustomerOrderFiles";
import { FULFILLED_UPDATE_METHOD } from "../../../features/dataTypes";
import { GetFilesFilter } from "../../../features/file/dataTypes";
import FileList from "../../shared/File/FileList/FileList";

const Attachments = ({ customerOrderId }: { customerOrderId: string }) => {
  const filter = useMemo<GetFilesFilter>(() => {
    return { paginationOptions: { all: true, amount: 0, from: 0 } };
  }, []);

  const { customerOrderFiles, isLoading } = useGetCustomerOrderFiles({
    filter,
    fulfilledUpdateMethod: FULFILLED_UPDATE_METHOD.UPSERT_MANY,
    customerOrderId: customerOrderId,
  });

  return <FileList files={customerOrderFiles} isLoading={isLoading} />;
};

export default Attachments;
