import { Theme } from "@mui/material";
import { createContext, ReactNode, useEffect, useState } from "react";
import { KeyOf } from "../../utils/dataTypes";
import storage from "../../utils/store/storage";
import THEME, { DEFAULT_THEME } from "./themes";

const THEME_SAVE_ID = "THEME_ID";

type ThemeId = KeyOf<typeof THEME>;

interface ThemeInterface {
  themeId: ThemeId;
  setTheme: (themeId: ThemeId) => void;
  theme: Theme;
}

const defaultTheme: ThemeInterface = {
  themeId: "default",
  setTheme: (_themeId: ThemeId) => {},
  theme: DEFAULT_THEME,
};

export const ThemeContext = createContext<ThemeInterface>(defaultTheme);

export function ThemeProvider({ children }: { children: ReactNode }) {
  const [themeId, setThemeId] = useState<ThemeId>(defaultTheme.themeId);

  const setTheme = (themeId: ThemeId) => {
    setThemeId(themeId);
    saveTheme(themeId);
  };

  useEffect(() => {
    const initializeTheme = async () => {
      const currentThemeId = (await storage.loadItem(THEME_SAVE_ID)) as ThemeId | undefined;

      setTheme(currentThemeId ?? defaultTheme.themeId);
    };

    initializeTheme();
  }, []);

  const theme = THEME[themeId];

  return <ThemeContext.Provider value={{ themeId, setTheme, theme }}>{children}</ThemeContext.Provider>;
}

function saveTheme(themeId?: string, rememberMe?: boolean) {
  storage.saveItem(THEME_SAVE_ID, rememberMe ?? false, themeId);
}
