import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { fetchCurrentUser } from "../../session/reducers/fetchCurrentUser";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { GoodsOwner } from "../dataTypes";
import { goodsOwnerAdapter } from "../goodsOwnerAdapter";

export const addFetchCurrentUserReducers = (builder: EntityActionReducerBuilderInterface<GoodsOwner>) => {
  builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload.goodsOwners == null) return;

    goodsOwnerAdapter.upsertMany(state, payload.goodsOwners.map(mapFulfilledResponseObject));
  });
};
