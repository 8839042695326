import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { GetPasswordRulesFilter } from "../dataTypes";
import useLazyGetPasswordRules from "./useLazyGetPasswordRules";

const useGetPasswordRules = (args: { filter?: Readonly<GetPasswordRulesFilter> }) => {
  const { isLoading: isLazyLoading, fetchPasswordRules, ...rest } = useLazyGetPasswordRules(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiatePasswordRules = async () => {
      if (!initiationStatus.current) {
        initiationStatus.current = "initiating";
        forceUpdate();
        await fetchPasswordRules();
        initiationStatus.current = "initiated";
      }
    };

    initiatePasswordRules();
  }, [fetchPasswordRules, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchPasswordRules: fetchPasswordRules };
};

export default useGetPasswordRules;
