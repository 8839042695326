import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderPackageAdapter } from "../customerOrderPackageAdapter";
import {
  CustomerOrderPackage,
  GetCustomerOrderPackagesFilter,
  ResponseCustomerOrderPackage,
  customerOrderPackageSliceName,
} from "../dataTypes";

const getCustomerOrderPackages = async (dispatch: AppDispatch, filters: Readonly<GetCustomerOrderPackagesFilter>) => {
  const url = api.customerOrderPackage.customerOrderPackages();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  customerOrderPackages: PaginationResponse<ResponseCustomerOrderPackage>;
}

export const fetchCustomerOrderPackages = createAsyncThunk<
  Response,
  { filter: Readonly<GetCustomerOrderPackagesFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod }
>(customerOrderPackageSliceName + "/fetchCustomerOrderPackages", (dispatch, { filter }) =>
  getCustomerOrderPackages(dispatch, filter),
);

export const addFetchCustomerOrderPackagesReducers = (
  builder: EntityActionReducerBuilderInterface<CustomerOrderPackage, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderPackages.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderPackages.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrders = payload.customerOrderPackages.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderPackageAdapter.setAll(state, customerOrders);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderPackageAdapter.upsertMany(state, customerOrders);
        break;
    }

    state.count = payload.customerOrderPackages.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderPackages.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
