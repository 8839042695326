import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import { fetchCurrentUser } from "../../session/reducers/fetchCurrentUser";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { User } from "../dataTypes";
import { userAdapter } from "../userAdapter";

export const addFetchCurrentUserReducers = (builder: EntityActionReducerBuilderInterface<User>) => {
  builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
    const { payload } = action;

    userAdapter.upsertOne(state, mapFulfilledResponseObject(payload.user));
  });
};
