import { SerializedError } from "@reduxjs/toolkit";
import { ValueOf } from "../../utils/dataTypes";
import { Message as ResponseMessage } from "../dataTypes";

export const dialogSliceName = "dialog";

export const SEVERITY = {
  INFO: 10,
  WARNING: 20,
  ERROR: 30,
} as const;

export interface Message extends ResponseMessage {
  type: ValueOf<typeof SEVERITY>;
}

export interface DialogState {
  messages?: Message[];
  error?: SerializedError;
}
