import { AdditionalRequestState, IdObject, IdType } from "../dataTypes";
import { UserImportedDataFilter } from "../user/dataTypes";

export const sessionSliceName = "session";

export interface SingleSignOnResponse {
  iss: string;
  upn: string;
  given_name: string;
  family_name: string;
  aud: string;
  sub: string;
  tid: string;
  unique_name: string;
}

export interface ResponseSessionData extends IdObject {
  token?: string;
  authorizations: IdType[];
  hasToAcceptAccessMessage: boolean;
  isAdmin: boolean;
}

export interface SessionData extends ResponseSessionData, AdditionalRequestState, IdObject {}

export interface CurrentUser extends AdditionalRequestState, IdObject {}

export interface NextUserAccessMessage extends AdditionalRequestState, Omit<IdObject, "id"> {
  id?: string;
}

export interface SessionState extends AdditionalRequestState {
  sessionData?: SessionData;
  isSingleSignOn?: boolean;
  singleSignOut?: boolean;
  isInitialized: boolean;
  rememberMe?: boolean;
  loginToken?: string;
  currentUser?: CurrentUser;
  nextUserAccessMessage?: NextUserAccessMessage;
  currentGoodsOwnerId?: string;
  comflowSessionId?: string;
}

export interface LogInRequest {
  username?: string;
  password?: string;
  token?: string;
  mylocLoginToken?: string;
  persistent?: boolean;
}

export interface UpdateUserCurrentDataRequest {
  firstName: string;
  surname: string;
  cellPhoneNumbers?: string;
  emailAddress: string;
  localeId?: string;
}

export interface RequestResetPasswordRequest {
  username: string;
}

export interface UpdateUserAccessMessageRequest {
  accept: boolean;
}

export interface GetCurrentUserFilter extends UserImportedDataFilter {}
