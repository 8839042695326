export default function mergeFetchedDetailRefAndStateEntities<T>(
  ref: { [id: string]: T | undefined },
  state: { [id: string]: T | undefined },
  resetRefsOnEmptyState = true,
) {
  return Object.entries(state).length > 0
    ? {
        ...ref,
        ...Object.entries(state).reduce<{ [id: string]: T | undefined }>((obj, [id, object]) => {
          const object1 = ref[id];

          return object
            ? {
                ...obj,
                [id]: {
                  ...object1,
                  ...object,
                },
              }
            : obj;
        }, {}),
      }
    : resetRefsOnEmptyState
    ? {}
    : ref;
}
