import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { shipmentSliceName } from "./dataTypes";
import { addFetchShipmentReducers } from "./reducers/fetchShipment";
import { addFetchShipmentsReducers } from "./reducers/fetchShipments";
import { shipmentAdapter } from "./shipmentAdapter";

const slice = createSlice({
  name: shipmentSliceName,
  initialState: shipmentAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchShipmentsReducers(builder);
    addFetchShipmentReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
