import { ComponentPropsWithoutRef } from "react";
import { Except } from "type-fest";
import Link from "../../Navigation/Link";

type TileProps = {
  label: string;
  illustration: string;
  content: string;
  link: string;
} & Except<ComponentPropsWithoutRef<typeof Link>, "to">;

export default function PnTileMyloc({ label, illustration, content, link, ...props }: TileProps) {
  return (
    <Link {...props} to={link}>
      <pn-tile label={label} illustration={illustration}>
        <p>{content}</p>
      </pn-tile>
    </Link>
  );
}
