import { createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { Message } from "../dataTypes";
import { dialogSliceName, DialogState, SEVERITY } from "./dataTypes";

const initialState: DialogState = {};

const slice = createSlice({
  name: dialogSliceName,
  initialState,
  reducers: {
    resetMessages(state) {
      state.messages = undefined;
      state.error = undefined;
    },
    setErrors(state, action: PayloadAction<{ errors: Message[]; exception?: SerializedError }>) {
      state.messages = action.payload.errors.map(message => ({
        ...message,
        type: SEVERITY.ERROR,
      }));
      state.error = action.payload.exception;
    },
    setInfos(state, action: PayloadAction<Message[]>) {
      state.messages = action.payload.map(message => ({
        ...message,
        type: SEVERITY.INFO,
      }));
    },
    setWarnings(state, action: PayloadAction<Message[]>) {
      state.messages = action.payload.map(message => ({
        ...message,
        type: SEVERITY.WARNING,
      }));
    },
  },
});

const { actions, reducer } = slice;

export const { resetMessages, setErrors, setInfos, setWarnings } = actions;

export default reducer;
