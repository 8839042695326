import { useEffect } from "react";
import { useAppSelector } from "../app/hooks";
import { selectSessionId } from "../features/session/sessionSelectors";
import useLazyGetSystemSettings from "../features/system/hooks/useLazyGetSystemSettings";
import useIdleTimer from "../hooks/useIdleTimer";

/**
 * This component can be used to show a modal showing time left before the user is getting logged out
 * Now it just uses the IdleTimer hook to get logged out following the timeout settings in System Settings
 * @returns null
 */
export default function IdleTimer() {
  const { systemSettings } = useLazyGetSystemSettings({ loadIfNotInitialized: true });
  const { idleTimer } = useIdleTimer(systemSettings?.timeOutMS);
  const userLoggedIn = !!useAppSelector(selectSessionId);

  useEffect(() => {
    if (!systemSettings?.useTimeOut) {
      return;
    }

    if (userLoggedIn) {
      idleTimer.start();
    }
  }, [idleTimer, systemSettings?.useTimeOut, userLoggedIn]);

  return null;
}
