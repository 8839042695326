import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  GetShipmentTrackingEventsFilter,
  ResponseShipmentTrackingEvent,
  ShipmentTrackingEvent,
  shipmentTrackingEventSliceName,
} from "../dataTypes";
import { shipmentTrackingEventAdapter } from "../shipmentTrackingEventAdapter";

const getShipmentTrackingEvents = async (
  dispatch: AppDispatch,
  { id, filter }: { id: string; filter: Readonly<GetShipmentTrackingEventsFilter> },
) => {
  const url = api.shipment.trackingEvents(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  events: PaginationResponse<ResponseShipmentTrackingEvent>;
}

export const fetchShipmentTrackingEvents = createAsyncThunk<
  Response,
  {
    filter: Readonly<GetShipmentTrackingEventsFilter>;
    fulfilledUpdateMethod: FulfilledUpdateMethod;
    id: string;
  }
>(shipmentTrackingEventSliceName + "/fetchShipmentTrackingEvents", getShipmentTrackingEvents);

export const addFetchShipmentTrackingEventsReducers = (
  builder: EntityActionReducerBuilderInterface<ShipmentTrackingEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchShipmentTrackingEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchShipmentTrackingEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const shipmentTrackingEvents = payload.events.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        shipmentTrackingEventAdapter.setAll(state, shipmentTrackingEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        shipmentTrackingEventAdapter.upsertMany(state, shipmentTrackingEvents);
        break;
    }

    state.count = payload.events.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchShipmentTrackingEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
