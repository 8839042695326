import useTranslate from "../../language/useTranslate";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import styles from "./ZetesContent.module.scss";
import useZetes from "./hooks/useZetes";

const ZetesContent = () => {
  const { response, errorMessages } = useZetes();
  const translate = useTranslate();

  const Content = () => {
    if (errorMessages) {
      return (
        <div className={styles.errorMessages}>
          <div>{translate("CANNOT_SHOW_IMAGE_OF_PACKAGE")}</div>
          <div>{translate("CLOSE_THIS_TAB")}</div>
        </div>
      );
    } else {
      return <iframe id="ZetesIframe" className={styles.zetesIframe} srcDoc={response} scrolling="yes"></iframe>;
    }
  };

  return response || errorMessages ? <Content /> : <LoadingSpinner />;
};

export default ZetesContent;
