import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { ResponseSessionData, SessionState, sessionSliceName } from "../dataTypes";

async function getSessionData(dispatch: AppDispatch) {
  const request = new Request(api.sessions.getSessionData(), Method.POST);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchSessionData = createAsyncThunk<ResponseSessionData, undefined>(
  sessionSliceName + "/fetchSessionData",
  dispatch => getSessionData(dispatch),
);

export const addFetchSessionDataReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchSessionData.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchSessionData.fulfilled, (state, action) => {
    const { payload } = action;

    state.sessionData = mapFulfilledResponseObject(payload);
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchSessionData.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
