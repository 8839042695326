import { createSlice } from "@reduxjs/toolkit";
import { AdditionalPaginationState, AdditionalRequestState } from "../dataTypes";
import { warehouseTransactionSliceName } from "./dataTypes";
import { addFetchWarehouseTransactionReducers } from "./reducers/fetchWarehouseTransaction";
import { addFetchWarehouseTransactionsReducers } from "./reducers/fetchWarehouseTransactions";
import { warehouseTransactionAdapter } from "./warehouseTransactionAdapter";

const slice = createSlice({
  name: warehouseTransactionSliceName,
  initialState: warehouseTransactionAdapter.getInitialState<AdditionalRequestState & AdditionalPaginationState>({
    count: 0,
  }),
  reducers: {},
  extraReducers: builder => {
    addFetchWarehouseTransactionReducers(builder);
    addFetchWarehouseTransactionsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
