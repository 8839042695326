import generateUseEntityHooks from "../../utils/hooks/generateUseEntityHooks";
import { makeSelectGoodsOwner, selectGoodsOwnersEntities } from "../goodsOwnerSelectors";
import { fetchGoodsOwner } from "../reducers/fetchGoodsOwner";

export const { useFetchGoodsOwner, useLazyGetGoodsOwner, useGetGoodsOwner } = generateUseEntityHooks({
  selectEntities: selectGoodsOwnersEntities,
  makeSelectEntity: makeSelectGoodsOwner,
  thunk: fetchGoodsOwner,
  entityName: "GoodsOwner",
});
