import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { systemSettingsSliceName, SystemSettingsState } from "./dataTypes";
import { addFetchSystemSettingsReducers } from "./reducers/fetchSystemSettings";

const initialState: SystemSettingsState = {
  isInitialized: false,
};

const slice = createSlice({
  name: systemSettingsSliceName,
  initialState,
  reducers: {
    setIsInitialized(state, action: PayloadAction<boolean>) {
      state.isInitialized = action.payload;
    },
  },
  extraReducers: builder => {
    addFetchSystemSettingsReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
