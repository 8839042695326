import { ValueOf } from "../../utils/dataTypes";
import { AdditionalRequestState, IdObject, ImportedDataFilter } from "../dataTypes";
import { ResponseGoodsOwner } from "../goodsOwner/dataTypes";
import { ACTION_LOG_ACTION, ACTION_LOG_TYPE, ACTION_LOG_TYPE_NAVIGATE } from "./dataObjects";

export const userSliceName = "user";

const LOCALES = {
  SV: "sv_SE",
  EN: "en_US",
} as const;

export interface ResponseUser extends IdObject {
  firstName: string;
  surname: string;
  cellPhoneNumbers: string;
  emailAddress: string;
  defaultGoodsOwnerId: string;
  localeId: ValueOf<typeof LOCALES>;
}

export interface UserImportedDataResponse {
  goodsOwners?: ResponseGoodsOwner[];
}

export interface User extends ResponseUser, AdditionalRequestState {}

export interface UserImportedDataFilter {
  defaultGoodsOwner?: ImportedDataFilter;
}

export type CreateActionLogRequest =
  | {
      action: typeof ACTION_LOG_ACTION.NAVIGATE;
      actionType: ValueOf<typeof ACTION_LOG_TYPE_NAVIGATE>;
      path: string;
    }
  | {
      action: typeof ACTION_LOG_ACTION.LOGIN;
      actionType: typeof ACTION_LOG_TYPE.MANUAL;
    }
  | {
      action: typeof ACTION_LOG_ACTION.LOGOUT;
      actionType: typeof ACTION_LOG_TYPE.MANUAL;
    };
