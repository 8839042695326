import { AdditionalRequestState, IdObject } from "../dataTypes";

export const isakRedirectUriSliceName = "isakRedirectUri";

export interface IsakRedirectUriRequest {
  routeId: string;
}

interface IsakRedirectUri extends Omit<IdObject, "id"> {
  id?: string;
  uri?: string;
}

export interface IsakRedirectUriState extends AdditionalRequestState {
  isakRedirectUri?: IsakRedirectUri;
}
