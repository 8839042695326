import { AdditionalRequestState } from "../dataTypes";
import { LastEvent } from "../lastEvent/dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const customerOrderPackageLastEventSliceName = "customerOrderPackageLastEvent";

export interface ResponseCustomerOrderPackageLastEvent extends LastEvent {}

export interface GetCustomerOrderPackageLastEventsFilter extends ClientPaginatedRequestFilter {}

export interface CustomerOrderPackageLastEvent extends ResponseCustomerOrderPackageLastEvent, AdditionalRequestState {}
