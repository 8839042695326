import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { AdditionalPaginationState, EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  ResponseWeekLetter,
  WeekLetter,
  WeekLetterImportedDataFilter,
  WeekLettersImportedDataResponse,
  weekLetterSliceName,
} from "../dataTypes";
import { weekLetterAdapter } from "../weekLetterAdapter";

const getWeekLetter = async (
  dispatch: AppDispatch,
  { id, filter = {} }: { id: string; filter?: Readonly<WeekLetterImportedDataFilter> },
) => {
  const url = api.weekLetter.weekLetter(id);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response extends WeekLettersImportedDataResponse {
  weekLetter: ResponseWeekLetter;
}

export const fetchWeekLetter = createAsyncThunk<
  Response,
  { id: string; filter?: Readonly<WeekLetterImportedDataFilter> }
>(weekLetterSliceName + "/fetchWeekLetter", (dispatch, thunkArgs) => getWeekLetter(dispatch, thunkArgs));

export const addFetchWeekLetterReducers = (
  builder: EntityActionReducerBuilderInterface<WeekLetter, AdditionalPaginationState>,
) => {
  builder.addCase(fetchWeekLetter.pending, (state, action) => {
    const { id } = action.meta.arg;
    const weekLetter = state.entities[id] ?? ({ id } as WeekLetter);

    weekLetterAdapter.upsertOne(state, {
      ...weekLetter,
      requestState: REQUEST_STATE.PENDING,
      errorMessages: undefined,
    });
  });
  builder.addCase(fetchWeekLetter.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    weekLetterAdapter.updateOne(state, {
      id,
      changes: mapFulfilledResponseObject(payload.weekLetter),
    });
  });
  builder.addCase(fetchWeekLetter.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload == null) return;

    const { id } = meta.arg;

    if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) {
      weekLetterAdapter.removeOne(state, id);
    } else {
      weekLetterAdapter.updateOne(state, {
        id,
        changes: mapRejectedResponse(payload),
      });
    }
  });
};
