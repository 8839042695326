import { GridLocaleText, svSE } from "@mui/x-data-grid-premium";

export const SV_TRANSLATION: Partial<GridLocaleText> = {
  ...svSE.components.MuiDataGrid.defaultProps.localeText,

  // Filter panel text
  filterPanelLogicOperator: "Logisk operator",
  filterPanelOperator: "Operator",

  filterOperatorContains: "innehåller",
  filterOperatorEquals: "lika med",
  filterOperatorStartsWith: "börjar med",
  filterOperatorEndsWith: "slutar med",
  filterOperatorIs: "lika med",
  filterOperatorNot: "ej lika med",
  // Filter operators text
  filterOperatorAfter: "efter",
  filterOperatorOnOrAfter: "lika med eller efter",
  filterOperatorBefore: "före",
  filterOperatorOnOrBefore: "före eller lika med",
  filterOperatorIsEmpty: "är tom",
  filterOperatorIsNotEmpty: "är ej tom",
  filterOperatorIsAnyOf: "är någon av",

  // Header filter operators text
  headerFilterOperatorContains: "Innehåller",
  headerFilterOperatorEquals: "Lika med",
  headerFilterOperatorStartsWith: "Börjar med",
  headerFilterOperatorEndsWith: "Slutar med",
  headerFilterOperatorIs: "Lika med",
  headerFilterOperatorNot: "Ej lika med",
  headerFilterOperatorAfter: "Efter",
  headerFilterOperatorOnOrAfter: "Lika med eller efter",
  headerFilterOperatorBefore: "Före",
  headerFilterOperatorOnOrBefore: "Före eller lika med",
  headerFilterOperatorIsEmpty: "Är tom",
  headerFilterOperatorIsNotEmpty: "Är ej tom",
  headerFilterOperatorIsAnyOf: "Är någon av",
  "headerFilterOperator=": "Lika med",
  "headerFilterOperator!=": "Ej lika med",
  "headerFilterOperator>": "Större än",
  "headerFilterOperator>=": "Större än eller lika med",
  "headerFilterOperator<": "Mindre än",
  "headerFilterOperator<=": "Mindre än eller lika med",
};
