import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { LogInRequest, ResponseSessionData, sessionSliceName, SessionState } from "../dataTypes";
import { saveSessionId } from "../utils/saveSessionId";

async function login(dispatch: AppDispatch, user: LogInRequest) {
  const request = new Request(api.account.authentication(), Method.POST);

  return await sendRequest(request, user, await defaultRestOptions({ dispatch, includeSessionId: false }));
}

export const logIn = createAsyncThunk<ResponseSessionData, LogInRequest>(
  sessionSliceName + "/logIn",
  (dispatch, logInRequest) => login(dispatch, logInRequest),
);

export const addLogInReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(logIn.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(logIn.fulfilled, (state, action) => {
    const { payload } = action;

    state.sessionData = mapFulfilledResponseObject(payload);
    state.requestState = REQUEST_STATE.FULFILLED;

    saveSessionId(payload.id, true);
  });
  builder.addCase(logIn.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
