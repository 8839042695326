import { SnackbarOrigin } from "@mui/material";
import { useAppSelector } from "../../../app/hooks";
import { selectMessages } from "../../../features/dialog/dialogSelectors";
import Snackbar from "../Snackbar/Snackbar";

const SnackbarMessage = ({
  onReset,
  position = { vertical: "bottom", horizontal: "center" },
  duration,
}: {
  onReset: () => void;
  position?: SnackbarOrigin;
  duration?: number;
}) => {
  const messages = useAppSelector(selectMessages);
  const severityType = messages?.[0]?.type;

  return (
    <>
      {severityType != null ? (
        <Snackbar
          onClose={onReset}
          position={position}
          messages={messages}
          severityType={severityType}
          duration={duration}
        />
      ) : null}
    </>
  );
};

export default SnackbarMessage;
