import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import {
  GetPurchaseOrderLineEventsFilter,
  PurchaseOrderLineEvent,
  purchaseOrderLineEventSliceName,
  ResponsePurchaseOrderLineEvent,
} from "../dataTypes";
import { purchaseOrderLineEventAdapter } from "../purchaseOrderLineEventAdapter";

const getPurchaseOrderLineEvents = async (
  dispatch: AppDispatch,
  { filter, purchaseOrderLineId }: { filter: Readonly<GetPurchaseOrderLineEventsFilter>; purchaseOrderLineId: string },
) => {
  const url = api.purchaseOrderLine.getPurchaseOrderLineEvents(purchaseOrderLineId);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filter, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  purchaseOrderLineEvents: PaginationResponse<ResponsePurchaseOrderLineEvent>;
}

export const fetchPurchaseOrderLineEvents = createAsyncThunk<
  Response,
  {
    filter: Readonly<GetPurchaseOrderLineEventsFilter>;
    fulfilledUpdateMethod: FulfilledUpdateMethod;
    purchaseOrderLineId: string;
  }
>(purchaseOrderLineEventSliceName + "/fetchPurchaseOrderLineEvents", getPurchaseOrderLineEvents);

export const addFetchPurchaseOrderLineEventsReducers = (
  builder: EntityActionReducerBuilderInterface<PurchaseOrderLineEvent, AdditionalPaginationState>,
) => {
  builder.addCase(fetchPurchaseOrderLineEvents.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchPurchaseOrderLineEvents.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const purchaseOrderLineEvents = payload.purchaseOrderLineEvents.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        purchaseOrderLineEventAdapter.setAll(state, purchaseOrderLineEvents);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        purchaseOrderLineEventAdapter.upsertMany(state, purchaseOrderLineEvents);
        break;
    }

    state.count = payload.purchaseOrderLineEvents.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchPurchaseOrderLineEvents.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
