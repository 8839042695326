import { Box, Tab, Tabs, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ComponentPropsWithoutRef, useState } from "react";
import { useParams } from "react-router";
import { useLazyGetCustomerOrder } from "../../../features/customerOrder/hooks/useCustomerOrder";
import { translate } from "../../../preferences/localization";
import { DATE_FORMATS } from "../../../utils/DateUtil";
import DetailBanner from "../../Detail/Banner/DetailBanner";
import Label from "../../shared/Label/Label";
import Page from "../../shared/Page/Page";
import TabPanel from "../../shared/TabPanel/TabPanel";
import Attachments from "./AttachMents";
import CustomerOrderEvents from "./CustomerOrderEvents";
import CustomerOrderLinesDataGrid from "./CustomerOrderLineGrid";
import CustomerOrderPackagesDataGrid from "./CustomerOrderPackagesGrid";

const BANNER_CONTENT_FLEX = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  minHeight: "4.5rem",
  marginTop: "4.5rem",
};

const PAGE_CONTENT_FLEX: ComponentPropsWithoutRef<typeof Box>["sx"] = {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  justifyContent: "start",
  margin: "0.8rem",
  gap: "1.8rem",
};

const TAB = {
  PACKAGES: 0,
  ORDER_LINES: 1,
  ATTACHMENTS: 2,
} as const;

const CustomerOrderDetailPage = () => {
  const queryParams = useParams<{ customerOrder: string }>();

  const [tabValue, setTabValue] = useState<number>(TAB.PACKAGES);

  const { customerOrder } = useLazyGetCustomerOrder({
    loadIfNotInitialized: true,
    id: queryParams.customerOrder,
  });

  return (
    <>
      <DetailBanner>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            justifyContent: "center",
            wordWrap: "nowrap",
            margin: "3.4rem 0.8rem",
            width: "35%",
          }}
        >
          <Typography variant="h1" marginTop={"1.6rem"}>
            {translate("ORDER")} #{customerOrder?.clientsOrderNumber}
          </Typography>
        </Box>
        <Box sx={{ ...BANNER_CONTENT_FLEX }}>
          <Label
            label={translate("ORDER_DATE")}
            value={dayjs(customerOrder?.orderDate).format(DATE_FORMATS.YYYY_MM_DD_HH_mm)}
          />
        </Box>
        <Box sx={{ ...BANNER_CONTENT_FLEX }}>
          <Label
            label={translate("REQUESTED_DELIVERY_DATE")}
            value={customerOrder?.deliveryDate ? dayjs(customerOrder.deliveryDate).format(DATE_FORMATS.YYYY_MM_DD) : ""}
          />
        </Box>
        <Box sx={{ ...BANNER_CONTENT_FLEX }}>
          <Label label={translate("LAST_EVENT")} value={customerOrder?.lastEvent} />
        </Box>
      </DetailBanner>

      <Page>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10%",
            justifyContent: "space-between",
            marginTop: "2.4rem",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", flex: "2" }}>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <Box sx={{ ...PAGE_CONTENT_FLEX }}>
                <Typography variant="h4">{translate("RECIPIENT_DETAIL")}</Typography>
                <Label label={translate("RECIPIENT_NAME")} value={customerOrder?.recipientName} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="subtitle1" component="span" color="textSecondary">
                    {translate("RECIPIENT_ADRESS")}
                  </Typography>
                  <Typography variant="body1" component="span">
                    {customerOrder?.recipientAddress} <br />
                    {customerOrder?.recipientZipCode} - {customerOrder?.recipientCity} <br />
                    {customerOrder?.recipientCountry}
                  </Typography>
                </Box>
                <Label label={translate("RECIPIENT_TELEPHONE_NUMBER")} value={customerOrder?.recipientTelephone} />
                <Label label={translate("RECIPIENT_EMAIL")} value={customerOrder?.recipientEmail} />
              </Box>
              <Box sx={{ ...PAGE_CONTENT_FLEX }}>
                <Typography variant="h4">{translate("CARRIER_DETAILS")}</Typography>
                <Label label={translate("CARRIER")} value={customerOrder?.carrier} />
                <Label label={translate("CARRIER_PRODUCT")} value={customerOrder?.carrierProduct} />
              </Box>
            </Box>
            <Box sx={{ flex: "2", marginTop: "2.5rem", width: "96.5rem", height: "50rem" }}>
              <Tabs value={tabValue} onChange={(_, selectedTabValue: number) => setTabValue(selectedTabValue)}>
                <Tab label={translate("ORDER_LINES")} />
                <Tab label={translate("PACKAGES")} />
                <Tab label={translate("ATTACHMENTS")} />
              </Tabs>

              <TabPanel value={tabValue} index={0}>
                <CustomerOrderLinesDataGrid customerOrderId={queryParams.customerOrder} />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CustomerOrderPackagesDataGrid customerOrder={customerOrder} />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Attachments customerOrderId={queryParams.customerOrder} />
              </TabPanel>
            </Box>
          </Box>

          <Box display={"flex"} flexDirection={"column"} flex={"1"}>
            <Box sx={{ ...PAGE_CONTENT_FLEX }}>
              <Typography variant="h4">{translate("EVENTS")}</Typography>
              <CustomerOrderEvents customerOrderId={queryParams.customerOrder} />
            </Box>
          </Box>
        </Box>
      </Page>
    </>
  );
};

export default CustomerOrderDetailPage;
