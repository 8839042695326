import { cloneDeep } from "lodash";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import makeCancellablePromise from "../../../utils/makeCancellablePromise";
import { FulfilledUpdateMethod, REQUEST_STATE, RequestState } from "../../dataTypes";
import { selectRequestState } from "../customerOrderEventSelectors";
import { GetCustomerOrderEventsFilter } from "../dataTypes";
import { fetchCustomerOrderEvents } from "../reducers/fetchCustomerOrderEvents";

let filterRef: GetCustomerOrderEventsFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchCustomerOrderEvents = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) {
    requestStateRef = requestState;
  }

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadCustomerOrderEvents = useCallback(
    ({
      filter,
      fulfilledUpdateMethod,
      customerOrderId,
    }: {
      filter: Readonly<GetCustomerOrderEventsFilter>;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
      customerOrderId: string;
    }) => {
      if (isEqual(filter, filterRef) && requestStateRef === REQUEST_STATE.PENDING) return;

      requestStateRef = REQUEST_STATE.PENDING;
      filterRef = cloneDeep(filter);

      const thunkPromise = dispatch(fetchCustomerOrderEvents({ filter, fulfilledUpdateMethod, id: customerOrderId }));

      return makeCancellablePromise(
        thunkPromise.then(({ payload }) => {
          if (payload && "customerOrderEvents" in payload) {
            return payload.customerOrderEvents.page.map(({ id }) => id);
          }
        }),
        thunkPromise.abort,
      );
    },
    [dispatch],
  );

  return loadCustomerOrderEvents;
};

export default useFetchCustomerOrderEvents;
