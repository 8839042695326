import { AdditionalRequestState, IdObject } from "../dataTypes";
import { PaginationFilterOptions } from "../utils/pagination";

export const customerOrderPackageCarrierEventSliceName = "customerPackageCarrierEvent";

export interface ResponseCustomerOrderPackageCarrierEvent extends IdObject {
  event: string;
  date: number;
  time: number;
  place: string;
}

export interface GetCustomerOrderPackageCarrierEventsFilter extends PaginationFilterOptions {}

export interface CustomerOrderPackageCarrierEvent
  extends ResponseCustomerOrderPackageCarrierEvent,
    AdditionalRequestState {}
