import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setErrors } from "../../features/dialog/dialogSlice";
import useLogOut from "../../features/session/hooks/useLogOut";
import useTranslate from "../../language/useTranslate";
import pages from "../../utils/pages";

const Logout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { logOut, isError, isLoading: isSigningOut, isSuccess } = useLogOut();
  const translate = useTranslate();

  useEffect(() => {
    logOut();
  }, [logOut]);

  useEffect(() => {
    if (isSuccess) {
      history.push(pages.HOME);
    }
  }, [history, isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(setErrors({ errors: [{ title: translate("UNABLE_TO_LOG_OUT"), code: "UNABLE_TO_LOG_OUT" }] }));
      history.push(pages.HOME);
    }
  }, [dispatch, history, isError, translate]);

  if (isSigningOut) {
    return <div>{translate("LOG_OUT_IN_PROGRESS")}</div>;
  }

  return null;
};

export default Logout;
