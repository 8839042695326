import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReadonlyDeep } from "type-fest";
import { useAppSelector } from "../../../app/hooks";
import useCancellablePromise from "../../../hooks/useCancellablePromises";
import { FULFILLED_UPDATE_METHOD, FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import {
  makeSelectCustomerOrderEventsById,
  selectCount,
  selectErrorMessage,
  selectRequestState,
} from "../customerOrderEventSelectors";
import { GetCustomerOrderEventsFilter } from "../dataTypes";
import useFetchCustomerOrderEvents from "./useFetchCustomerOrderEvents";

interface ids {
  allIds: string[];
  uniqueIds: Set<string>;
}

const useLazyGetCustomerOrderEvents = ({
  filter,
  fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.SET_ALL,
  loadIfNotInitialized = false,
  infiniteScroll = false,
  customerOrderId,
}: {
  filter: ReadonlyDeep<GetCustomerOrderEventsFilter>;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
  infiniteScroll?: boolean;
  customerOrderId: string;
}) => {
  const fetchCustomerOrderEvents = useFetchCustomerOrderEvents();
  const hasInitialized = useRef(false);
  const [ids, setIds] = useState<ids>({ allIds: [], uniqueIds: new Set() });

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const count = useAppSelector(selectCount);
  const selectCustomerOrderEvents = useMemo(() => makeSelectCustomerOrderEventsById(ids.allIds), [ids.allIds]);
  const customerOrderEvents = useAppSelector(selectCustomerOrderEvents);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const { addCancellablePromise } = useCancellablePromise(filter);

  const loadCustomerOrderEvents = useCallback(async () => {
    const promise = fetchCustomerOrderEvents({ filter, fulfilledUpdateMethod, customerOrderId });

    if (promise == null) return;

    addCancellablePromise(promise);

    const response = await promise;

    if (!response.isSuccessful) return;

    const value = response.value;

    if (value == null) return;

    if (infiniteScroll) {
      setIds(prevIds => {
        const allIds: string[] = [...prevIds.allIds, ...value.filter(id => !uniqueIds.has(id))];
        const uniqueIds: Set<string> = new Set(...prevIds.uniqueIds, ...value);

        return { allIds, uniqueIds };
      });
    } else {
      setIds({ allIds: value, uniqueIds: new Set(value) });
    }
  }, [addCancellablePromise, fetchCustomerOrderEvents, filter, fulfilledUpdateMethod, infiniteScroll, customerOrderId]);

  useEffect(() => {
    //om något ändras som inte är from och amount i filtret nollställ ids
    hasInitialized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitialized.current) {
      hasInitialized.current = true;
      loadCustomerOrderEvents();
    }
  }, [isUninitialized, loadIfNotInitialized, loadCustomerOrderEvents]);

  return {
    customerOrderEvents,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchCustomerOrderEvents: loadCustomerOrderEvents,
  };
};

export default useLazyGetCustomerOrderEvents;
