import { AdditionalRequestState } from "../dataTypes";

export const systemSettingsSliceName = "systemSettings";

export interface ResponseSystemSettings {
  useTimeOut: boolean;
  timeOutMS: number;
  portalTitle: string;
}

export interface SystemSettings extends ResponseSystemSettings, AdditionalRequestState {}

export interface SystemSettingsState extends AdditionalRequestState {
  isInitialized: boolean;
  systemSettings?: SystemSettings;
}
