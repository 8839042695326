import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { weekLetterSliceName } from "./dataTypes";
import { weekLetterAdapter } from "./weekLetterAdapter";

const selectSliceState = (state: RootState) => state[appDataName][weekLetterSliceName];

const adapterSelectors = weekLetterAdapter.getSelectors(selectSliceState);

export const selectErrorMessages = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectWeekLettersEntities = adapterSelectors.selectEntities;
export const selectWeekLetters = adapterSelectors.selectAll;
export const makeSelectWeekLetter = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
