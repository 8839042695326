import { useAppSelector } from "../../../app/hooks";
import { useLazyGetGoodsOwner } from "../../goodsOwner/hooks/useGoodsOwner";
import { selectCurrentGoodsOwnerId } from "../../session/sessionSelectors";

const useLazyGetCurrentGoodsOwner = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const currentGoodsOwnerId = useAppSelector(selectCurrentGoodsOwnerId);

  const { fetchGoodsOwner, goodsOwner, ...rest } = useLazyGetGoodsOwner({
    id: currentGoodsOwnerId,
    loadIfNotInitialized,
  });

  return {
    ...rest,
    fetchCurrentGoodsOwner: fetchGoodsOwner,
    currentGoodsOwner: goodsOwner,
  };
};

export default useLazyGetCurrentGoodsOwner;
