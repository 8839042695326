import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderPackageCarrierEventAdapter } from "./customerOrderPackageCarrierEventAdapter";
import { CustomerOrderPackageCarrierEvent, customerOrderPackageCarrierEventSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderPackageCarrierEventSliceName];

const adapterSelectors = customerOrderPackageCarrierEventAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderPackageCarrierEventsEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderPackageCarrierEvents = adapterSelectors.selectAll;
export const makeSelectCustomerOrderPackageCarrierEvent = (id = "") => (state: RootState) =>
  adapterSelectors.selectById(state, id);
export const makeSelectCustomerOrderPackageCarrierEventsById = (ids: string[] = []) => (state: RootState) =>
  ids.reduce<CustomerOrderPackageCarrierEvent[]>((customerOrderPackageCarrierEvents, id) => {
    const customerOrderPackageCarrierEvent = makeSelectCustomerOrderPackageCarrierEvent(id)(state);

    return customerOrderPackageCarrierEvent
      ? [...customerOrderPackageCarrierEvents, customerOrderPackageCarrierEvent]
      : customerOrderPackageCarrierEvents;
  }, []);
