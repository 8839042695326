import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetWeekLettersFilter } from "../dataTypes";
import useLazyGetWeekLetters from "./useLazyGetWeekLetters";

const useGetWeekLetters = (args: {
  filter?: Readonly<GetWeekLettersFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
}) => {
  const { isLoading: isLazyLoading, fetchWeekLetters, ...rest } = useLazyGetWeekLetters(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    const initiateWeekLetters = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchWeekLetters();
      initiationStatus.current = "initiated";
    };

    initiateWeekLetters();
  }, [fetchWeekLetters, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchWeekLetters: fetchWeekLetters };
};

export default useGetWeekLetters;
