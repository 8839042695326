const QUICK_ACCESS_ITEMS = [
  {
    label: "TRACK_AND_TRACE",
    illustration: "truck",
    content: "TRACK_AND_TRACE_DESCRIPTION",
    link: "/track-and-trace",
    functionId: "ControlTower_GetCustomerOrders",
  },
  {
    label: "CUSTOMER_COMPLAINTS",
    illustration: "hand-paper-money",
    content: "MANAGE_COMPLAINTS",
    link: "/embed-customer-complaints",
    comflowTaskId: "Complaints/ComplaintsCustomer",
  },
  {
    label: "COMPLAINTS_INTERNAL",
    illustration: "hand-paper-money",
    content: "MANAGE_COMPLAINTS",
    link: "/embed-internal-complaints",
    comflowTaskId: "Complaints/ComplaintsInternal",
  },
];

export default QUICK_ACCESS_ITEMS;
