import { Method, Request, Response as ResponseUtil, sendRequest } from "@myloc/myloc-utils";
import { useCallback, useState } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { getErrorMessages } from "../../../services/error/errorService";
import { Message, Messages, ValidationResult } from "../../dataTypes";
import defaultRestOptions from "../../utils/defaultRestOptions";
import getValidationResult from "../../utils/getValidationResults";
import { SingleSignOnResponse } from "../dataTypes";
import { saveSingleSignOnResponse } from "../utils/saveSingleSignOnResponse";

async function getUserData(dispatch: AppDispatch) {
  const customErrorHandler: Parameters<typeof defaultRestOptions>["0"]["customErrorHandler"] = exception => {
    return new ResponseUtil(
      exception?.response?.statusCode,
      exception?.response?.statusText,
      getErrorMessages(exception),
    );
  };

  const url = api.singleSignOn.getUserInfo();
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ customErrorHandler, dispatch }));
}

const useFetchSingleSignOnUserData = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessages, setErrorMessages] = useState<Messages>();

  const loadUserData = useCallback(async (): Promise<ValidationResult<undefined>> => {
    setIsLoading(true);
    setIsSuccess(false);
    setIsError(false);
    setErrorMessages(undefined);

    const response = await getUserData(dispatch);

    let isOk = false;

    if (typeof response.data === "object" && "id_token" in response.data) {
      saveSingleSignOnResponse((response.data as { id_token: SingleSignOnResponse }).id_token);
      isOk = true;
    }

    setIsSuccess(isOk);
    setIsError(!isOk);

    setIsLoading(false);

    if (isOk) {
      return getValidationResult({ data: undefined });
    } else {
      const responseErrorMessages = { errors: response.data as Message[] };

      setErrorMessages(responseErrorMessages);

      return getValidationResult({ messages: responseErrorMessages });
    }
  }, [dispatch]);

  return { loadUserData, isLoading, isSuccess, isError, errorMessages };
};

export default useFetchSingleSignOnUserData;
