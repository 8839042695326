import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FULFILLED_UPDATE_METHOD,
  FulfilledUpdateMethod,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { File, GetFilesFilter, ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import mapRejectedResponse from "../../utils/mapRejectedResponse";
import { customerOrderPackageFileAdapter } from "../customerOrderPackageFileAdapter";
import { customerOrderPackageFileSliceName } from "../dataTypes";

const getCustomerOrderPackageFiles = async (
  dispatch: AppDispatch,
  customerOrderPackageId: string,
  filters: Readonly<GetFilesFilter>,
) => {
  const url = api.customerOrderPackage.files(customerOrderPackageId);

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, filters, await defaultRestOptions({ dispatch }));

  return response;
};

interface Response {
  files: PaginationResponse<ResponseFile>;
}

export const fetchCustomerOrderPackageFiles = createAsyncThunk<
  Response,
  { filter: Readonly<GetFilesFilter>; fulfilledUpdateMethod: FulfilledUpdateMethod; packageId: string }
>(customerOrderPackageFileSliceName + "/fetchCustomerOrderPackageFiles", (dispatch, { filter, packageId }) =>
  getCustomerOrderPackageFiles(dispatch, packageId, filter),
);

export const addFetchCustomerOrderPackageFilesReducers = (
  builder: EntityActionReducerBuilderInterface<File, AdditionalPaginationState>,
) => {
  builder.addCase(fetchCustomerOrderPackageFiles.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessages = undefined;
  });
  builder.addCase(fetchCustomerOrderPackageFiles.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod },
      },
    } = action;

    const customerOrderFiles = payload.files.page.map(mapFulfilledResponseObject);

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        customerOrderPackageFileAdapter.setAll(state, customerOrderFiles);
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        customerOrderPackageFileAdapter.upsertMany(state, customerOrderFiles);
        break;
    }

    state.count = payload.files.count;

    state.requestState = REQUEST_STATE.FULFILLED;
  });

  builder.addCase(fetchCustomerOrderPackageFiles.rejected, (state, action) => {
    const { payload } = action;

    if (payload == null) return;

    return { ...state, ...mapRejectedResponse(payload) };
  });
};
