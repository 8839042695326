import cloneDeep from "lodash.clonedeep";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE, RequestState, ValidationResult } from "../../dataTypes";
import getValidationResult from "../../utils/getValidationResults";
import { GetPasswordRulesFilter } from "../dataTypes";
import { selectPasswordRules } from "../passwordRulesSelectors";
import { fetchPasswordRules } from "../reducers/fetchPasswordRules";

let filterRef: GetPasswordRulesFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchPasswordRules = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectPasswordRules)?.requestState;

  if (requestStateRef === undefined) requestStateRef = requestState;

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadPasswordRules = useCallback(
    async ({
      filter,
    }: {
      filter?: Readonly<GetPasswordRulesFilter>;
    }): Promise<ValidationResult<undefined> | undefined> => {
      if (isEqual(filter, filterRef) && requestStateRef === REQUEST_STATE.PENDING) return;

      requestStateRef = REQUEST_STATE.PENDING;
      filterRef = cloneDeep(filter);

      const response = (await dispatch(fetchPasswordRules({ filter }))).payload;

      if (!response) return;

      if ("regexValidation" in response) {
        return getValidationResult({ data: undefined });
      }

      return getValidationResult({ messages: { errors: response.errors } });
    },
    [dispatch],
  );

  return loadPasswordRules;
};

export default useFetchPasswordRules;
