import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { fetchComflowSessionValues } from "../../comflowSessionValues/reducers/fetchComflowSessionValues";
import { SessionData, SessionState } from "../dataTypes";

export const addFetchComflowSessionValuesReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchComflowSessionValues.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload.sessionId) {
      if (!state.sessionData) {
        state.sessionData = {} as SessionData;
      }

      state.sessionData.id = payload.sessionId;
    }
  });
};
