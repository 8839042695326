import { Link, Breadcrumbs as MUIBreadcrumbs, Typography } from "@mui/material";
import { Breadcrumb } from "../../../navigation/dataTypes";
import useBreadcrumbs from "../../../navigation/useBreadcrumbs";
import useHistory from "../../../navigation/useHistory";
import styles from "./Breadcrumbs.module.scss";

const Breadcrumbs = () => {
  const history = useHistory();

  const { breadcrumbs } = useBreadcrumbs();

  function handleBreadcrumb(breadcrumb: Breadcrumb) {
    const index = breadcrumbs?.findIndex(currBreadcrumb => currBreadcrumb.id === breadcrumb.id) ?? -1;

    history.push({
      location: { pathname: breadcrumb.link },
      state: { breadcrumbs: index >= 0 ? breadcrumbs?.slice(0, index + 1) : undefined },
    });
  }

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs && breadcrumbs.length > 1 ? (
        <MUIBreadcrumbs aria-label="breadcrumb">
          {breadcrumbs.map((breadcrumb, index) =>
            breadcrumbs.length === index + 1 ? (
              <Typography color="text.primary">{breadcrumb.text}</Typography>
            ) : (
              <Link
                key={index}
                component="button"
                underline="hover"
                variant="body2"
                color="inherit"
                onClick={() => {
                  handleBreadcrumb(breadcrumb);
                }}
              >
                {breadcrumb.text}
              </Link>
            ),
          )}
        </MUIBreadcrumbs>
      ) : null}
    </div>
  );
};

export default Breadcrumbs;
