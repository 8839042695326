import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { customerOrderPackageLastEventAdapter } from "./customerOrderPackageLastEventAdapter";
import { CustomerOrderPackageLastEvent, customerOrderPackageLastEventSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderPackageLastEventSliceName];

const adapterSelectors = customerOrderPackageLastEventAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderPackageLastEventsEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderPackageLastEvents = adapterSelectors.selectAll;
export const makeSelectCustomerOrderPackageLastEvent = (id = "") => (state: RootState) =>
  adapterSelectors.selectById(state, id);

export const makeSelectCustomerOrderPackageLastEventsById = (ids: string[] = []) => (state: RootState) => {
  if (ids.length === 0) {
    const customerOrderPackageLastEvents = selectCustomerOrderPackageLastEvents(state);

    return customerOrderPackageLastEvents;
  }

  return ids.reduce<CustomerOrderPackageLastEvent[]>((customerOrderPackageLastEvents, id) => {
    const customerOrderPackageLastEvent = makeSelectCustomerOrderPackageLastEvent(id)(state);

    return customerOrderPackageLastEvent
      ? [...customerOrderPackageLastEvents, customerOrderPackageLastEvent]
      : customerOrderPackageLastEvents;
  }, []);
};
