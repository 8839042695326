import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReadonlyDeep } from "type-fest";
import { useAppSelector } from "../../../app/hooks";
import useCancellablePromise from "../../../hooks/useCancellablePromises";
import { FULFILLED_UPDATE_METHOD, FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import {
  makeSelectCustomerOrderPackageCarrierEventsById,
  selectCount,
  selectErrorMessage,
  selectRequestState,
} from "../customerOrderPackageCarrierEventSelectors";
import { GetCustomerOrderPackageCarrierEventsFilter } from "../dataTypes";
import useFetchCustomerOrderPackageCarrierEvents from "./useFetchCustomerOrderPackageCarrierEvents";

interface ids {
  allIds: string[];
  uniqueIds: Set<string>;
}

const useLazyGetCustomerOrderPackageCarrierEvents = ({
  filter,
  fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.SET_ALL,
  loadIfNotInitialized = false,
  infiniteScroll = false,
  packageId,
}: {
  filter: ReadonlyDeep<GetCustomerOrderPackageCarrierEventsFilter>;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
  infiniteScroll?: boolean;
  packageId: string;
}) => {
  const fetchCustomerOrderPackageCarrierEvents = useFetchCustomerOrderPackageCarrierEvents();
  const hasInitialized = useRef(false);
  const [ids, setIds] = useState<ids>({ allIds: [], uniqueIds: new Set() });

  const requestState = useAppSelector(selectRequestState);
  const errorMessage = useAppSelector(selectErrorMessage);
  const count = useAppSelector(selectCount);
  const selectCustomerOrderPackageCarrierEvents = useMemo(
    () => makeSelectCustomerOrderPackageCarrierEventsById(ids.allIds),
    [ids.allIds],
  );

  const customerOrderPackageCarrierEvents = useAppSelector(selectCustomerOrderPackageCarrierEvents);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const { addCancellablePromise } = useCancellablePromise(filter);

  const loadCustomerOrderPackageCarrierEvents = useCallback(async () => {
    const promise = fetchCustomerOrderPackageCarrierEvents({ filter, fulfilledUpdateMethod, packageId });

    if (promise == null) return;

    addCancellablePromise(promise);

    const response = await promise;

    if (!response.isSuccessful) return;

    const value = response.value;

    if (value == null) return;

    if (infiniteScroll) {
      setIds(prevIds => {
        const allIds: string[] = [...prevIds.allIds, ...value.filter(id => !uniqueIds.has(id))];
        const uniqueIds: Set<string> = new Set(...prevIds.uniqueIds, ...value);

        return { allIds, uniqueIds };
      });
    } else {
      setIds({ allIds: value, uniqueIds: new Set(value) });
    }
  }, [
    addCancellablePromise,
    fetchCustomerOrderPackageCarrierEvents,
    filter,
    fulfilledUpdateMethod,
    infiniteScroll,
    packageId,
  ]);

  useEffect(() => {
    //om något ändras som inte är from och amount i filtret nollställ ids
    hasInitialized.current = false;
  }, [filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitialized.current) {
      hasInitialized.current = true;
      loadCustomerOrderPackageCarrierEvents();
    }
  }, [isUninitialized, loadIfNotInitialized, loadCustomerOrderPackageCarrierEvents]);

  return {
    customerOrderPackageCarrierEvents,
    count,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchCustomerOrderPackageCarrierEvents: loadCustomerOrderPackageCarrierEvents,
  };
};

export default useLazyGetCustomerOrderPackageCarrierEvents;
