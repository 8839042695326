import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { File } from "../file/dataTypes";
import { customerOrderFileAdapter } from "./customerOrderFileAdapter";
import { customerOrderFileSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][customerOrderFileSliceName];

const adapterSelectors = customerOrderFileAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectCount = (state: RootState) => selectSliceState(state).count;

export const selectCustomerOrderFilesEntities = adapterSelectors.selectEntities;
export const selectCustomerOrderFiles = adapterSelectors.selectAll;
export const makeSelectCustomerOrderFile = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);

export const makeSelectCustomerOrderFilesById = (ids: string[] = []) => (state: RootState) => {
  return ids.reduce<File[]>((customerOrderFiles, id) => {
    const customerOrderFile = makeSelectCustomerOrderFile(id)(state);

    return customerOrderFile ? [...customerOrderFiles, customerOrderFile] : customerOrderFiles;
  }, []);
};
