import { GridPaginationModel } from "@mui/x-data-grid-premium";

export const DEFAULT_PAGINATION_MODEL: GridPaginationModel = {
  page: 0,
  pageSize: 10,
};

export const DEFAULT_SMALL_PAGINATION_MODEL: Readonly<GridPaginationModel> = {
  page: 0,
  pageSize: 5,
};

export const PAGINATION_MODE = {
  SERVER: "server",
  CLIENT: "client",
} as const;
