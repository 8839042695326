import { cloneDeep } from "lodash";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import makeCancellablePromise from "../../../utils/makeCancellablePromise";
import { FulfilledUpdateMethod, REQUEST_STATE, RequestState } from "../../dataTypes";
import { GetFilesFilter } from "../../file/dataTypes";
import { selectRequestState } from "../customerOrderFileSelectors";
import { fetchCustomerOrderFiles } from "../reducers/fetchCustomerOrderFiles";

let filterRef: GetFilesFilter | undefined;
let requestStateRef: RequestState | undefined;

const useFetchCustomerOrderFiles = () => {
  const dispatch = useAppDispatch();

  const requestState = useAppSelector(selectRequestState);

  if (requestStateRef === undefined) {
    requestStateRef = requestState;
  }

  useEffect(() => {
    requestStateRef = requestState;
  }, [requestState]);

  const loadCustomerOrderFiles = useCallback(
    ({
      filter,
      customerOrderId,
      fulfilledUpdateMethod,
    }: {
      filter: Readonly<GetFilesFilter>;
      customerOrderId: string;
      fulfilledUpdateMethod: FulfilledUpdateMethod;
    }) => {
      if (isEqual(filter, filterRef) && requestStateRef === REQUEST_STATE.PENDING) return;

      requestStateRef = REQUEST_STATE.PENDING;
      filterRef = cloneDeep(filter);

      const thunkPromise = dispatch(fetchCustomerOrderFiles({ filter, customerOrderId, fulfilledUpdateMethod }));

      return makeCancellablePromise(
        thunkPromise.then(({ payload }) => {
          if (payload && "files" in payload) {
            return payload.files.page.map(({ id }) => id);
          }
        }),
        thunkPromise.abort,
      );
    },
    [dispatch],
  );

  return loadCustomerOrderFiles;
};

export default useFetchCustomerOrderFiles;
