import { fetchFilesUploadedToWMS } from "../../customerOrder/reducers/fetchFilesUploadedToWMS";
import { EntityActionReducerBuilderInterface } from "../../dataTypes";
import mapFulfilledResponseObject from "../../utils/mapFulfilledResponseObject";
import { File } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

export const addGetCustomerOrderFilesUploadedToWMSReducers = (builder: EntityActionReducerBuilderInterface<File>) => {
  builder.addCase(fetchFilesUploadedToWMS.fulfilled, (state, action) => {
    const { payload } = action;

    const files = payload.files.page.map(mapFulfilledResponseObject);

    fileAdapter.upsertMany(state, files);
  });
};
