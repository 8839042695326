import { useEffect, useRef } from "react";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetCustomerOrderLinesFilter } from "../dataTypes";
import useLazyGetCustomerOrderLines from "./useLazyGetCustomerOrderLines";

const useGetCustomerOrderLines = (args: {
  filter?: Readonly<GetCustomerOrderLinesFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
}) => {
  const { isLoading: isLazyLoading, fetchCustomerOrderLines, ...rest } = useLazyGetCustomerOrderLines(args);
  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrderLines();
  }, [fetchCustomerOrderLines]);

  useEffect(() => {
    const initiateCustomerOrderLines = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrderLines();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrderLines();
  }, [fetchCustomerOrderLines, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrders: fetchCustomerOrderLines };
};

export default useGetCustomerOrderLines;
