import { AdditionalRequestState, IdObject } from "../dataTypes";
import { ClientPaginatedRequestFilter } from "../utils/pagination";

export const customerOrderPackageSliceName = "customerOrderPackage";

export interface ResponseCustomerOrderPackage extends IdObject {
  client: string;
  orderDate: number;
  sscc: string;
  shipmentNumber: string;
  carrier: string;
  carrierProduct: string;
  lastEventId: string;
  lastEvent: string;
  lastEventTimeStamp: number;
  customerNumber: string;
  recipient: string;
  recipientAddress: string;
  recipientZipCode: string;
  recipientCity: string;
  recipientCountryCode: string;
  recipientCountry: string;
  recipientName: string;
  recipientEmail: string;
  recipientTelephone: string;
  recipientMobile: string;
  eta: number;
  linkToPackage: string;
}

export const CUSTOMER_ORDER_PACKAGE_FIELDS = {
  CLIENT: "client",
  ORDER_DATE: "orderDate",
  SSCC: "sscc",
  SHIPMENT_NUMBER: "shipmentNumber",
  CARRIER: "carrier",
  CARRIER_PRODUCT: "carrierProduct",
  LAST_EVENT_ID: "lastEventId",
  LASTEVENT: "lastEvent",
  LAST_EVENT_TIME_STAMP: "lastEventTimeStamp",
  CUSTOMER_NUMBER: "customerNumber",
  RECIPIENT: "recipient",
  RECIPIENT_ADDRESS: "recipientAddress",
  RECIPIENT_ZIP_CODE: "recipientZipCode",
  RECIPIENT_CITY: "recipientCity",
  RECIPIENT_COUNTRY_CODE: "recipientCountryCode",
  RECIPIENT_COUNTRY: "recipientCountry",
  RECIPIENT_NAME: "recipientName",
  RECIPIENT_EMAIL: "recipientEmail",
  RECIPIENT_TELEPHONE: "recipientTelephone",
  RECIPIENT_MOBILE: "recipientMobile",
  ETA: "eta",
  LINK_TO_PACKAGE: "linkToPackage",
} as const;

//vissa fält kan man sortera på men inte få ut i responsen
export const CUSTOMER_ORDER_PACKAGE_FILTRATION_FIELDS = {
  ...CUSTOMER_ORDER_PACKAGE_FIELDS,
  ORDER_NUMBER: "orderNumber",
  SEARCH: "search",
  ITEM_SEARCH: "itemID",
} as const;

export interface GetCustomerOrderPackagesFilter extends ClientPaginatedRequestFilter {}

export interface CustomerOrderPackage extends ResponseCustomerOrderPackage, AdditionalRequestState {}
