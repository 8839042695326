import { AdditionalRequestState, IdObject } from "../dataTypes";
import { FILE_FIELDS } from "../file/dataTypes";
import { ClientPaginatedRequestFilter, GoodsOwnerPaginatedRequestFilter } from "../utils/pagination";

export const customerOrderSliceName = "customerOrder";

export interface GetFilesUploadedToWMSFilter extends GoodsOwnerPaginatedRequestFilter {}

export interface UploadFilesToWMSRequest {
  goodsOwner: string;
}

export const GET_FILES_UPLOADED_TO_WMS_SEARCH_FIELDS = {
  ...FILE_FIELDS,
  GOODS_OWNER: "goodsOwner",
};

export interface GetCustomerOrdersFilter extends ClientPaginatedRequestFilter {}

export interface ResponseCustomerOrder extends IdObject {
  client: string;
  clientsOrderNumber: string;
  orderNumber: string;
  orderType: string;
  deliveryDate: number;
  orderDate: number;
  orderTime: number;
  recipient: string;
  recipientAddress: string;
  recipientZipCode: string;
  recipientCity: string;
  recipientCountryCode: string;
  recipientCountry: string;
  recipientName: string;
  recipientEmail: string;
  recipientTelephone: string;
  recipientMobile: string;
  customerNumber: string;
  lastEventId: string;
  lastEvent: string;
  lastEventTimeStamp: number;
  statusColor: string;
  carrier: string;
  carrierProduct: string;
  transporter: string;
  lastEventCarrier: string;
  orderReference: string;
  orderClient: string;
  fetchOrder: boolean;
  customerOrderNumber: string;
  shipmentNumber: string;
}

export interface GetCustomerOrdersFilter extends ClientPaginatedRequestFilter {}

export interface CustomerOrder extends ResponseCustomerOrder, AdditionalRequestState {}

export const CUSTOMER_ORDER_FIELDS = {
  CLIENT: "client",
  CLIENTS_ORDER_NUMBER: "clientsOrderNumber",
  ORDER_NUMBER: "orderNumber",
  ORDER_TYPE: "orderType",
  DELIVERY_DATE: "deliveryDate",
  ORDER_DATE: "orderDate",
  RECIPIENT: "recipient",
  RECIPIENT_ADDRESS: "recipientAddress",
  RECIPIENT_ZIP_CODE: "recipientZipCode",
  RECIPIENT_CITY: "recipientCity",
  RECIPIENT_COUNTRY_CODE: "recipientCountryCode",
  RECIPIENT_COUNTRY: "recipientCountry",
  RECIPIENT_NAME: "recipientName",
  RECIPIENT_EMAIL: "recipientEmail",
  RECIPIENT_TELEPHONE: "recipientTelephone",
  RECIPIENT_MOBILE: "recipientMobile",
  CUSTOMER_NUMBER: "customerNumber",
  LAST_EVENT_ID: "lastEventId",
  LAST_EVENT: "lastEvent",
  LAST_EVENT_TIME_STAMP: "lastEventTimeStamp",
  STATUS_COLOR: "statusColor",
  CARRIER: "carrier",
  CARRIER_PRODUCT: "carrierProduct",
  TRANSPORTER: "transporter",
  LAST_EVENT_CARRIER: "lastEventCarrier",
  ORDER_REFERENCE: "orderReference",
  ORDER_CLIENT: "orderClient",
  FETCH_ORDER: "fetchOrder",
  CUSTOMER_ORDER_NUMBER: "customerOrderNumber",
  SHIPMENT_NUMBER: "shipmentNumber",
};

export const CUSTOMER_ORDER_FILTRATION_FIELDS = {
  ...CUSTOMER_ORDER_FIELDS,
  SEARCH: "search",
  ITEM_SEARCH: "itemSearch",
};
