import classNames from "classnames";
import PropType from "prop-types";
import Spinner from "../../../assets/images/spinner.gif";
import useTranslate from "../../../language/useTranslate";
import styles from "./LoadingSpinner.module.scss";

const LoadingSpinner = ({
  title,
  customCssClass,
  customSpinnerCssClass,
}: {
  title?: string;
  customCssClass?: string;
  customSpinnerCssClass?: string;
}) => {
  const translate = useTranslate();

  return (
    <figure className={classNames(styles.spinner, customCssClass)}>
      <img src={Spinner} alt={title} className={classNames(styles.spinnerImage, customSpinnerCssClass)} />
      {title && <figcaption>{translate(title)}</figcaption>}
    </figure>
  );
};

LoadingSpinner.propTypes = {
  title: PropType.string,
  customCssClass: PropType.string,
  customSpinnerCssClass: PropType.string,
};

export default LoadingSpinner;
