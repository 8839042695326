import { useEffect, useRef } from "react";
import { ReadonlyDeep } from "type-fest";
import useForceUpdate from "../../../hooks/useForceUpdate";
import { FulfilledUpdateMethod } from "../../dataTypes";
import { GetCustomerOrderPackageCarrierEventsFilter } from "../dataTypes";
import useLazyGetCustomerOrderPackageCarrierEvents from "./useLazyGetCustomerOrderPackageCarrierEvents";

const useGetCustomerOrderPackageCarrierEvents = (args: {
  filter: ReadonlyDeep<GetCustomerOrderPackageCarrierEventsFilter>;
  fulfilledUpdateMethod: FulfilledUpdateMethod;
  packageId: string;
  //lägg till infintescroll
}) => {
  const {
    isLoading: isLazyLoading,
    fetchCustomerOrderPackageCarrierEvents,
    ...rest
  } = useLazyGetCustomerOrderPackageCarrierEvents(args);

  const forceUpdate = useForceUpdate();
  const initiationStatus = useRef<"initiating" | "initiated">();

  useEffect(() => {
    if (initiationStatus.current == null) return;

    fetchCustomerOrderPackageCarrierEvents();
  }, [fetchCustomerOrderPackageCarrierEvents]);

  useEffect(() => {
    const initiateCustomerOrderPackageCarrierEvents = async () => {
      if (initiationStatus.current != null) return;

      initiationStatus.current = "initiating";
      forceUpdate();
      await fetchCustomerOrderPackageCarrierEvents();
      initiationStatus.current = "initiated";
    };

    initiateCustomerOrderPackageCarrierEvents();
  }, [fetchCustomerOrderPackageCarrierEvents, forceUpdate]);

  const isLoading = !initiationStatus.current || isLazyLoading;

  return { ...rest, isLoading, refetchCustomerOrderEvents: fetchCustomerOrderPackageCarrierEvents };
};

export default useGetCustomerOrderPackageCarrierEvents;
