import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { fileSliceName, ResponseFile } from "../../file/dataTypes";

import getFormData from "../../../utils/getFormData";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { UploadFilesToWMSRequest } from "../dataTypes";

async function doUploadFilesToWMS(
  dispatch: AppDispatch,
  { uploadFilesToWMSRequest, files }: { uploadFilesToWMSRequest: UploadFilesToWMSRequest; files: Blob[] },
) {
  const request = new Request(api.purchaseOrder.uploadFilesToWMS(), Method.POST);

  return await sendRequest(
    request,
    getFormData({ data: uploadFilesToWMSRequest, files }),
    await defaultRestOptions({ dispatch }),
  );
}

interface Response {
  files: ResponseFile[];
}

export const uploadFilesToWMS = createAsyncThunk<
  Response,
  { uploadFilesToWMSRequest: UploadFilesToWMSRequest; files: Blob[] }
>(fileSliceName + "/uploadFilesToWMS", doUploadFilesToWMS);
