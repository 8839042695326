import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { systemSettingsSliceName } from "./dataTypes";

const selectSliceState = (state: RootState) => state[appDataName][systemSettingsSliceName];

export const selectErrorMessages = (state: RootState) => selectSliceState(state).errorMessages;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectSystemSettings = (state: RootState) => selectSliceState(state).systemSettings;

export const selectUseIdleTimeOut = (state: RootState) => selectSliceState(state).systemSettings?.useTimeOut;
